import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createEditor } from 'slate';
import { ReactEditor, withReact } from 'slate-react';
import { withHtml } from 'components/GQActivityPage/Insights/SlateHTML';
import InsightTextEditor from 'components/GQActivityPage/Insights/InsightTextEditor';
import { find, map } from 'lodash/fp';
import GQLoader from 'components/GQLoader/GQLoader';
import GQFlag from 'components/GQFlag';
import moment from 'moment';
import { useInsight } from 'hooks/useInsight';
import { getRiskShortNames } from 'utils/riskUtils';

const InsightMobile = React.memo(() => {
    const { id } = useParams();
    const [editor] = useState<ReactEditor>(() =>
        withHtml(withReact(createEditor()))
    );
    const { insight, loading } = useInsight({ id });
    const getComponentById = useCallback(
        (id: string) => {
            return find(comp => comp.id === id, insight.components);
        },
        [insight]
    );
    const insightRisks = useMemo(() => getRiskShortNames(insight?.risks), [
        insight,
    ]);

    if (loading) return <GQLoader />;
    return (
        <div style={{ overflowY: 'auto', padding: '1.5rem .5rem' }}>
            <div className="mobile-insights-list-item-header">
                <div className="mobile-insights-header-title">
                    {insight.insightsData.name}
                </div>
                <div className="mobile-insights-header-tags">
                    {insight.insightsData.tags.join(', ')}
                </div>
                <div className="mobile-insights-header-date">
                    {moment(insight.date).format('MMMM DD, YYYY')}
                </div>
                <div className="mobile-insights-header-countries">
                    {map(
                        (country: number) => (
                            <GQFlag
                                key={country}
                                countryId={country}
                                getFlagFromAll={true}
                                style={{ fontSize: 14, marginRight: 4 }}
                            />
                        ),
                        insight.countries
                    )}
                </div>
                <div className="mobile-insights-header-risks">
                    {map(
                        (risk: string) => (
                            <span key={risk} style={{ marginRight: 4 }}>
                                {risk}
                            </span>
                        ),
                        insightRisks
                    )}
                </div>
            </div>
            <InsightTextEditor
                editor={editor}
                showToolbar={false}
                value={insight.insightsData.insight.nodes}
                readOnly
                getComponentById={getComponentById}
            />
        </div>
    );
});
export default InsightMobile;
