import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import * as _ from 'lodash';
import { Moment } from 'moment';
import GQFlag from '../GQFlag';
import GQButton, { IGQButtonProps } from '../GQButton/GQButton';
import Toast from '../scoringPage/lib/Toast';
import APIService, {
    IActiveContingentRisk,
    IActiveCountry,
    IActiveCountryMap,
    IActiveDimension,
    IActiveGroup,
    IAPIActiveClientFacingIndicator,
} from '../../services/APIService';
import countryStore from '../../stores/CountryStore';
import heatmapStore from '../../stores/HeatmapStore';
import GQAccordion from '../GQAccordion/GQAccordion';
import GQPopupStorage from '../GQPopup/GQPopupStorage';
import {
    VIEW,
    navbarMapGroup,
    navbarContingentGroup,
    navbarCountryGroup,
    INSIGHTS_VIEWS,
} from '../../interfaces';
import {
    DATE_FNS_DATE_DISPLAY_FORMAT,
    SettingsItems,
    MOMENTJS_DATE_DISPLAY_FORMAT,
    E_TIME_PRESET_KEYS,
    DATE_PICKER_DEFAULT_PRESETS,
    HEATMAP_PRESETS,
} from '../../constants';
import EventsService, {
    GQEmitterEvents,
    EventSubscription,
} from '../../services/EventsService';
import { IMobxRootState } from '../../RootStore';
import loading from '../../services/LoadingService';
import EventsFeedStore from '../eventsFeed/EventsFeedStore';
import DatePicker from 'react-datepicker';
import chartStore from '../GQChart/ChartStore';
import moment from 'moment';
import GQDateRangePicker from './GQDateRangePicker';
import Logo from 'assets/general/logo.svg';
import CountryPicker, {
    CountryPickerProps,
} from 'components/CountryPicker/CountryPicker';
import rootStore from '../../RootStore';
import ToggleComponentButton from 'components/shared/ToggleComponentButton';
import {
    forEach,
    get,
    includes,
    first,
    filter,
    flow,
    find,
    join,
    has,
    drop,
    map,
    split,
} from 'lodash/fp';
import GQCGSelectedList from 'components/GQSettings/SettingsPages/CountryGroups/Picker/GQCGSelectedList';
import GQDimensionChip from 'components/GQDimensionChip/GQDimensionChip';
import GQDatePicker from './GQDatePicker';
import cx from 'classnames';
import SpecificActivityStore from 'stores/SpecificActivityStore';
import { getCFILongName, getCFIShortName, getDimensionLongName, getDimensionShortName } from 'utils/riskUtils';

const DATE_RANGE_VIEWS = [
    VIEW.RISK_VIEW,
    VIEW.WORLD_GRAPH,
    VIEW.COUNTRY_GRAPH,
    VIEW.CONTINGENT_GRAPH,
    VIEW.SORT_BY_DELTA,
    VIEW.SORT_BY_CONTINGENT_DELTA,
    VIEW.CONTINGENT,
];

const CountryPickerContainer = ({
    children,
}: {
    children: ReactElement<CountryPickerProps>;
}) => {
    return <div className="navbar-country-picker-container">{children}</div>;
};

const buttonProps: Partial<IGQButtonProps> = {
    noBorder: true,
    toggleButton: true,
    tooltipPosition: 'bottom left',
};

const navbarFeedbackKey = 'navbar-feedback-tooltip';
const navbarSettingsMenuKey = 'navbar-settings-menu-tooltip';

interface State {
    hasNewInsightNotificationFlag: boolean;
}

interface Props extends RouteComponentProps<{ insightsView: string }> {
    disabledCountryModeButton?: boolean;
    activeDimList?: number[];
    minDate?: Moment;
    heatmapStartDate?: Date;
    heatmapTimeSpan?: E_TIME_PRESET_KEYS;
    maxDate?: Moment;
    activeCountries?: number[];
    virtualToday?: Moment;
    chartExpanded?: boolean;
    isClientFacingIndicators?: boolean;
    heatmapCountries?: number[];
    heatmapTitle?: string;
    pushURL?: (url: string) => void;
    impersonate?: boolean;
    username?: string;
    countries?: IActiveCountryMap;
    licensedCountriesArr?: IActiveCountry[];
    dimensions?: { [dimensionId: number]: IActiveDimension };
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
    activeContingentRisks?: IActiveContingentRisk[];
    activity?: SpecificActivityStore;
    groups?: IActiveGroup[];
    activeGroupId?: string;
    canUserScore?: boolean;
}

type ToggleButtonType = 'single' | 'multi';

@inject(
    ({
        appStore,
        routingStore,
        countryStore,
        risksStore,
        chartStore,
        heatmapStore,
        UserStore,
        insightsStore,
        settingsStore,
    }: IMobxRootState) => {
        return {
            pushURL(url) {
                routingStore.push(url);
            },
            disabledCountryModeButton:
                appStore.currentDisplayedView === VIEW.SCORE_EVENT,
            activeDimList: risksStore.currentList,
            minDate: chartStore.baseDate
                .clone()
                .startOf('day')
                .subtract(chartStore.rangeLeft, 'days'),
            maxDate: chartStore.baseDate
                .clone()
                .startOf('day')
                .add(chartStore.rangeRight, 'days'),
            heatmapStartDate: heatmapStore.startDate,
            heatmapTimeSpan: heatmapStore.timeSpan,
            activeCountries: countryStore.currentCountryList,
            virtualToday: chartStore.virtualToday,
            isClientFacingIndicators: risksStore.isClientFacingIndicators,
            currentDisplayedView: appStore.currentDisplayedView,
            heatmapCountries: heatmapStore.countriesList,
            heatmapTitle: heatmapStore.title,
            impersonate: appStore.impersonate,
            username: UserStore.username,
            countries: countryStore.countries.countries,
            licensedCountriesArr: countryStore.countries.licensedCountriesArr,
            dimensions: risksStore.dimensions.hashed,
            activeClientFacingIndicators: risksStore.clientFacingIndicators,
            activeContingentRisks: risksStore.contingentRisks,
            activity: insightsStore.activity,
            activeGroupId: settingsStore.activeGroupId,
            groups: settingsStore.activeGroups,
            canUserScore: UserStore.uscoresPermissions.can_score,
        };
    }
)
@observer
class GQNavbar extends React.Component<Props, State> {
    private showToast = false;
    private newGQInsightSubscription: EventSubscription;
    private clickSub: EventSubscription;
    public containerRef: GQCGSelectedList = null;
    constructor(props: Props) {
        super(props);
        this.state = {
            hasNewInsightNotificationFlag: false,
        };
    }

    //todo: this code is from old HomeController. this will need an additional refactoring
    private get activeView(): VIEW {
        const activeView = get(
            1,
            this.props.location.pathname.match(/\/([^/]+)/)
        );
        return activeView as VIEW;
    }

    //todo: remove this code and use router hooks to get insights view
    private get insightsView(): VIEW {
        const insightsView = get(2, split('/', this.props.location.pathname));
        return insightsView as VIEW;
    }

    public componentDidMount() {
        this.clickSub = EventsService.registerOnMouseLeftClick(() => {
            this.hideSettingsMenu();
            this.hideFeedback();
        });
        this.newGQInsightSubscription = EventsService.registerEvent(
            GQEmitterEvents.PN_NEW_GQ_INSIGHT,
            this.onNewInsightNotification
        );
        APIService.getUnseenActivitiesCount().then(({ count }) => {
            this.setState({
                hasNewInsightNotificationFlag:
                    this.activeView === VIEW.INSIGHTS ? false : count > 0,
            });
        });
    }

    public componentWillUnmount() {
        if (this.newGQInsightSubscription) {
            this.newGQInsightSubscription.remove();
        }
        if (this.clickSub) {
            this.clickSub.remove();
        }
    }

    public componentDidUpdate() {
        if (
            this.activeView === VIEW.INSIGHTS &&
            this.state.hasNewInsightNotificationFlag
        ) {
            this.setState({
                hasNewInsightNotificationFlag: false,
            });
        }
    }

    private isRiskModeView() {
        return (
            navbarMapGroup.has(this.activeView) ||
            navbarMapGroup.has(this.insightsView)
        );
    }

    private isCountryModeView() {
        return (
            navbarCountryGroup.has(this.activeView) ||
            navbarCountryGroup.has(this.insightsView)
        );
    }

    private isContingentModeView() {
         return (
            navbarContingentGroup.has(this.activeView) ||
            navbarContingentGroup.has(this.insightsView)
        );
    }

    private isHeatmapView() {
        return (
            this.activeView === VIEW.HEATMAP ||
            this.insightsView === VIEW.HEATMAP
        );
    }

    private isCountryModeCountrySelectorView() {
        return this.activeView === VIEW.COUNTRY_GRAPH_SELECT_COUNTRY;
    }

    private isContingentCountrySelectorView() {
        return this.activeView === VIEW.SELECT_COUNTRY;
    }

    private onGroupClicked = (id: string, scroll: number) => {
        const activeGroup = _.find(this.props.groups, e => e.id === id);
        for (const country of countryStore.currentCountryList) {
            if (!activeGroup.countries.includes(country)) {
                countryStore.removeCountry(country);
            }
        }
        rootStore.settingsStore.setActiveGroupId(id);
        rootStore.settingsStore.setActiveGroupScroll(scroll);
        APIService.getActiveCountries().then(countries => {
            countryStore.updateCountries(countries);
        });
    };

    private onHeatmapCountryPickerGroupClicked = (
        id: string,
        scroll: number
    ) => {
        rootStore.settingsStore.setActiveGroupId(id);
        rootStore.settingsStore.setActiveGroupScroll(scroll);
        const activeGroup = _.find(this.props.groups, e => e.id === id);
        heatmapStore.setCountriesList(activeGroup.countries);
    };

    private selectAllCountriesFromActiveCountryGroup = () => {
        const activeGroupCountries = flow(
            find(
                (group: IActiveGroup) => group.id === this.props.activeGroupId
            ),
            get('countries')
        )(this.props.groups);

        forEach(
            countryId => countryStore.addCountry(countryId),
            activeGroupCountries
        );
    };

    private selectAllHeatmapCountriesFromActiveCountryGroup = () => {
        const activeGroupCountries = flow(
            find(
                (group: IActiveGroup) => group.id === this.props.activeGroupId
            ),
            get('countries')
        )(this.props.groups);

        heatmapStore.setCountriesList(activeGroupCountries);
    };

    private unselectAllCountries = () => {
        forEach(
            country => countryStore.removeCountry(country.id),
            this.props.licensedCountriesArr
        );
    };

    private renderTogglePickerButton = (toggleButtonType: ToggleButtonType) => {
        return ({ isVisible }: { isVisible: boolean }) => {
            return toggleButtonType === 'single' ? (
                <GQButton
                    icon="gqi-dropdown"
                    noBorder={true}
                    noPadding={true}
                    style={{
                        fontSize: '20px',
                        color: 'rgba(255,255,255,0.6)',
                        verticalAlign: 'middle',
                    }}
                />
            ) : (
                <GQButton
                    icon={isVisible ? 'gqi-check-circle' : 'gqi-plus-circle'}
                    noBorder={true}
                    noPadding={true}
                    style={{
                        fontSize: '21px',
                        color: 'rgba(255,255,255,0.6)',
                        verticalAlign: 'middle',
                        backgroundColor: 'rgba(255, 255, 254, 0.2)',
                        borderRadius: '50%',
                        width: '19px',
                        height: '19px',
                        marginTop: '1px',
                    }}
                />
            );
        };
    };

    private renderSeparator() {
        return <div className="gq-navbar-vertical-separator marginSides" />;
    }

    private renderFlag(countryId) {
        if (!countryId) {
            return this.renderEmptyFlag();
        }

        return (
            <div className="top-nav-country-flag">
                <GQFlag countryId={countryId} className="flag-icon-squared" />
            </div>
        );
    }

    private renderEmptyFlag() {
        return (
            <div className="top-nav-country-flag">
                <div className="empty-flag" />
            </div>
        );
    }

    private renderCountryName(countryId) {
        const countryName = this.getCountryName(countryId);
        return (
            <div className="top-nav-country-name">
                {countryName ? countryName : 'Select country'}
            </div>
        );
    }

    private renderActiveDimension() {
        const { activeDimList } = this.props;

        const showActiveDimension =
            this.activeView !== VIEW.SCORE_EVENT &&
            !navbarContingentGroup.has(this.activeView);

        let activeDimension = this.getDimensionName(first(activeDimList), true);
        if (!activeDimension.toUpperCase().includes('RISK')) {
            activeDimension = activeDimension.concat(' Risk');
        }

        return (
            <React.Fragment>
                {showActiveDimension && (
                    <div className="top-nav-dimension">{activeDimension}</div>
                )}
            </React.Fragment>
        );
    }

    private renderPickers() {
        const {
            activeCountries,
            licensedCountriesArr,
            heatmapCountries,
            heatmapTitle,
        } = this.props;

        const firstCountry = first(activeCountries);

        const availableLicensedCountries = filter(
            country => has(country.id, this.props.countries),
            licensedCountriesArr
        );

        // this large 'if' section will eventually move into each page's Navbar rendering section.
        // GQNavBar should not know so much about each page's country picker variation(s),
        // nor does HomePageController (which will be split into a component-per-page)
        if (this.isContingentCountrySelectorView()) {
            return (
                <React.Fragment>
                    {this.renderSeparator()}
                    {this.renderFlag(null)}
                    {this.renderCountryName(null)}
                    <ToggleComponentButton
                        renderToggleButton={this.renderTogglePickerButton(
                            'single'
                        )}>
                        <CountryPickerContainer>
                            <CountryPicker
                                canSelectAllCountries={false}
                                countriesList={availableLicensedCountries}
                                selectedCountriesList={[]}
                                isMultiSelect={false}
                                onGroupClicked={this.onGroupClicked}
                                activeGroupScroll={
                                    rootStore.settingsStore.activeGroupScroll
                                }
                                onCountrySelected={(countryId: number) => {
                                    rootStore.countryStore.changeFirstCountry(
                                        countryId
                                    );
                                    this.goToURL(VIEW.CONTINGENT);
                                }}
                            />
                        </CountryPickerContainer>
                    </ToggleComponentButton>
                    {this.renderSeparator()}
                    {this.renderActiveDimension()}
                    {this.renderSeparator()}
                    {this.renderDate()}
                    {this.renderSeparator()}
                </React.Fragment>
            );
        }

        if (this.isContingentModeView()) {
            const contingentCountriesIds = map(
                country => country.id,
                availableLicensedCountries
            );

            return (
                <React.Fragment>
                    {this.renderSeparator()}
                    {firstCountry && (
                        <React.Fragment>
                            {this.renderFlag(firstCountry)}
                            {this.renderCountryName(firstCountry)}
                        </React.Fragment>
                    )}
                    <ToggleComponentButton
                        renderToggleButton={this.renderTogglePickerButton(
                            'single'
                        )}>
                        <CountryPickerContainer>
                            <CountryPicker
                                canSelectAllCountries={false}
                                countriesList={availableLicensedCountries}
                                selectedCountriesList={[firstCountry]}
                                isMultiSelect={false}
                                onCountrySelected={(countryId: number) => {
                                    rootStore.countryStore.changeFirstCountry(
                                        countryId
                                    );
                                }}
                            />
                        </CountryPickerContainer>
                    </ToggleComponentButton>
                    {this.renderSeparator()}
                    <ToggleComponentButton
                        renderToggleButton={this.renderTogglePickerButton(
                            'multi'
                        )}>
                        <CountryPickerContainer>
                            <CountryPicker
                                canSelectAllCountries={true}
                                countriesList={availableLicensedCountries}
                                selectedCountriesList={drop(
                                    1,
                                    this.props.activeCountries
                                )}
                                isMultiSelect={true}
                                onCountrySelected={(countryId: number) => {
                                    rootStore.countryStore.toggleSecondaryCountry(
                                        countryId
                                    );
                                }}
                                onAllSelected={() => {
                                    rootStore.countryStore.currentStore.overrideValues(
                                        ...[
                                            first(this.props.activeCountries),
                                            ...contingentCountriesIds,
                                        ]
                                    );
                                }}
                                onAllUnselected={() => {
                                    rootStore.countryStore.currentStore.overrideValues(
                                        first(this.props.activeCountries)
                                    );
                                }}
                            />
                        </CountryPickerContainer>
                    </ToggleComponentButton>
                    <GQCGSelectedList
                        style={{ height: 22 }}
                        ref={el => (this.containerRef = el)}
                        selectedIds={drop(1, this.props.activeCountries)}
                        useSelectable={true}
                        selected={drop(1, this.props.activeCountries)}
                        onClick={selectedCountryId => {
                            rootStore.countryStore.removeCountry(
                                selectedCountryId
                            );
                        }}
                    />
                    {this.renderSeparator()}
                    <div className="contingent-title">
                        {this.getDimensionName(
                            first(this.props.activeDimList)
                        ) || 'Contingent Risk'}
                    </div>
                    {this.renderSeparator()}
                    {this.renderDate()}
                    {this.renderSeparator()}
                </React.Fragment>
            );
        }

        if (this.isHeatmapView()) {
            const selectedCountryIds = heatmapStore.selectedCountry
                ? [heatmapStore.selectedCountry]
                : [];

            return (
                <React.Fragment>
                    {this.renderSeparator()}
                    <div className="contingent-title">{heatmapTitle}</div>
                    {this.renderSeparator()}
                    <ToggleComponentButton
                        renderToggleButton={this.renderTogglePickerButton(
                            'multi'
                        )}>
                        <CountryPickerContainer>
                            <CountryPicker
                                canSelectAllCountries={true}
                                countriesList={licensedCountriesArr}
                                selectedCountriesList={licensedCountriesArr
                                    .filter(country =>
                                        heatmapCountries.includes(country.id)
                                    )
                                    .map(country => country.id)}
                                groups={this.props.groups || []}
                                isMultiSelect={true}
                                onGroupClicked={
                                    this.onHeatmapCountryPickerGroupClicked
                                }
                                activeGroupScroll={
                                    rootStore.settingsStore.activeGroupScroll
                                }
                                activeGroupId={this.props.activeGroupId}
                                onCountrySelected={(countryId: number) =>
                                    heatmapStore.toggleCountry(countryId)
                                }
                                onAllSelected={
                                    this
                                        .selectAllHeatmapCountriesFromActiveCountryGroup
                                }
                                onAllUnselected={() =>
                                    heatmapStore.setCountriesList([])
                                }
                            />
                        </CountryPickerContainer>
                    </ToggleComponentButton>
                    <GQCGSelectedList
                        style={{ height: 22 }}
                        ref={el => (this.containerRef = el)}
                        selectedIds={selectedCountryIds}
                        useSelectable={true}
                        selected={selectedCountryIds}
                        onClick={countryId =>
                            heatmapStore.setSelectedCountry(null)
                        }
                    />
                    {this.renderSeparator()}
                    {this.renderDate()}
                    {this.renderSeparator()}
                </React.Fragment>
            );
        }

        if (
            this.isCountryModeView() ||
            this.isCountryModeCountrySelectorView()
        ) {
            return (
                <React.Fragment>
                    {this.renderSeparator()}
                    {this.renderFlag(firstCountry)}
                    {this.renderCountryName(firstCountry)}
                    <ToggleComponentButton
                        renderToggleButton={this.renderTogglePickerButton(
                            'single'
                        )}>
                        <CountryPickerContainer>
                            <CountryPicker
                                canSelectAllCountries={false}
                                countriesList={licensedCountriesArr}
                                selectedCountriesList={[firstCountry]}
                                groups={this.props.groups || []}
                                isMultiSelect={false}
                                onGroupClicked={this.onGroupClicked}
                                activeGroupScroll={
                                    rootStore.settingsStore.activeGroupScroll
                                }
                                activeGroupId={this.props.activeGroupId}
                                onCountrySelected={(countryId: number) => {
                                    countryStore.changeFirstCountry(countryId);
                                    if (
                                        this.isCountryModeCountrySelectorView()
                                    ) {
                                        this.goToURL(VIEW.COUNTRY_GRAPH);
                                    }
                                }}
                            />
                        </CountryPickerContainer>
                    </ToggleComponentButton>

                    {this.renderSeparator()}
                    <div className="top-nav-dimension-container">
                        <div
                            id="dimentionList"
                            className="top-nav-dimension-list row"
                            onWheel={this.invertScroll}>
                            {_.map(this.props.activeDimList, dim => {
                                return (
                                    <GQDimensionChip
                                        key={dim}
                                        id={dim}
                                        shortName={
                                            this.props.activeDimList.length > 6
                                        }
                                        isCFI={
                                            this.props.isClientFacingIndicators
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                    {this.renderSeparator()}
                    {this.renderDate()}
                </React.Fragment>
            );
        }

        if (this.isRiskModeView() || this.activeView === VIEW.PROMPT) {
            return (
                <React.Fragment>
                    {this.renderSeparator()}
                    {this.renderActiveDimension()}
                    {this.renderSeparator()}
                    <ToggleComponentButton
                        renderToggleButton={this.renderTogglePickerButton(
                            'multi'
                        )}>
                        <CountryPickerContainer>
                            <CountryPicker
                                canSelectAllCountries={true}
                                countriesList={licensedCountriesArr}
                                selectedCountriesList={activeCountries}
                                groups={this.props.groups || []}
                                isMultiSelect={true}
                                onGroupClicked={this.onGroupClicked}
                                activeGroupScroll={
                                    rootStore.settingsStore.activeGroupScroll
                                }
                                activeGroupId={this.props.activeGroupId}
                                onCountrySelected={(countryId: number) => {
                                    countryStore.toggleCountry(countryId);
                                }}
                                onAllSelected={
                                    this
                                        .selectAllCountriesFromActiveCountryGroup
                                }
                                onAllUnselected={this.unselectAllCountries}
                            />
                        </CountryPickerContainer>
                    </ToggleComponentButton>
                    <GQCGSelectedList
                        style={{ height: 22 }}
                        ref={el => (this.containerRef = el)}
                        selectedIds={activeCountries}
                        useSelectable={true}
                        selected={activeCountries}
                        onClick={selectedCountryId => {
                            countryStore.toggleCountry(selectedCountryId);
                        }}
                    />
                    {this.renderSeparator()}
                    {this.renderDate()}
                </React.Fragment>
            );
        }
    }

    //todo: remove and use react router links links
    private goToURL(url: string, ...args: any[]) {
        if (
            this.activeView === VIEW.CREATE_INSIGHT &&
            includes(url, INSIGHTS_VIEWS)
        ) {
            const insightId = get('id', this.props.activity)
            const insightUrl = `/${VIEW.CREATE_INSIGHT}/${url}/${insightId || ''}`;
            this.props.pushURL(insightUrl);
            return;
        }
        const fullUrl = `/${url}/${join('/', args)}`;
        if (this.props.location.pathname === fullUrl) {
            return;
        }

        this.props.pushURL(fullUrl);
    }
    private renderNavigationMenu() {
        return (
            <div className="navbar-navigation">
                <div className="filler" />
                <GQAccordion
                    icon="gqi-world"
                    tooltip="Risk View - Compare a single risk across multiple countries"
                    expanded={
                        this.isRiskModeView() ||
                        this.isHeatmapView() ||
                        this.activeView === VIEW.WORLD_GRAPH ||
                        this.activeView === VIEW.INSIGHTS ||
                        navbarMapGroup.has(this.insightsView)
                    }
                    expandedWidth={(this.getWorldButtons().length + 1) * 32}
                    onClick={this.onWorldClick}
                    active={
                        this.activeView === VIEW.RISK_VIEW ||
                        this.insightsView === VIEW.RISK_VIEW
                    }>
                    {this.getWorldButtons()}
                </GQAccordion>
                <GQButton
                    {...buttonProps}
                    icon="gqi-flag"
                    active={
                        includes(this.activeView, [
                            VIEW.COUNTRY_GRAPH,
                            VIEW.COUNTRY_GRAPH_SELECT_COUNTRY ||
                                this.insightsView === VIEW.COUNTRY_GRAPH,
                        ]) || this.insightsView === VIEW.COUNTRY_GRAPH
                    }
                    onClick={() => {
                        this.goToURL(VIEW.COUNTRY_GRAPH);
                    }}
                    tooltip="Country View - Compare multiple risks for a single country"
                />
                <GQAccordion
                    icon="gqi-contingent"
                    tooltip="Contingent Risk View"
                    expanded={this.isContingentModeView()}
                    expandedWidth={
                        (this.getContingentButtons().length + 1) * 32
                    }
                    onClick={this.onContingentClick}
                    active={
                        this.activeView === VIEW.CONTINGENT ||
                        this.insightsView === VIEW.CONTINGENT
                    }>
                    {this.getContingentButtons()}
                </GQAccordion>
                <GQButton
                    {...buttonProps}
                    icon="gqi-bookmark"
                    style={{ fontSize: '18px' }}
                    onClick={this.onCreateInsightClick}
                    tooltip="Create insight"
                    disabled={this.activeView === VIEW.CREATE_INSIGHT}
                    active={this.activeView === VIEW.CREATE_INSIGHT}
                />
                <GQButton
                    {...buttonProps}
                    style={{ fontSize: '18px' }}
                    iconClass={
                        this.state.hasNewInsightNotificationFlag
                            ? 'notification'
                            : ''
                    }
                    icon="gqi-log"
                    onClick={this.goToActivityPage}
                    tooltip="Insights and Predictions"
                    active={this.activeView === VIEW.INSIGHTS}
                />
                {this.props.canUserScore && (
                    <GQButton
                        {...buttonProps}
                        style={{ fontSize: '18px' }}
                        icon="gqi-event"
                        onClick={this.goToScoreEventPage}
                        tooltip="Event Scoring"
                        active={this.activeView === VIEW.SCORE_EVENT}
                        disabled={EventsFeedStore.defaultEvent === undefined}
                    />
                )}
                <div className="gq-navbar-vertical-separator" />
                <GQButton
                    {...buttonProps}
                    style={{ fontSize: '18px' }}
                    icon="gqi-settings"
                    onClick={this.openSettingsMenu}
                    tooltip="Settings"
                    active={this.activeView === VIEW.SETTINGS}
                />
            </div>
        );
    }
    public render() {
        const shouldRenderPickers =
            this.activeView !== VIEW.SETTINGS &&
            this.activeView !== VIEW.INSIGHTS;

        return (
            <div
                className={cx('navbar', {
                    impersonate: this.props.impersonate,
                })}>
                <Toast
                    text="Bookmark Added!"
                    toastClass="positive"
                    show={this.showToast}
                />
                <div className="navbar-logo" onClick={this.onGQIconClick}>
                    <img src={Logo} alt="" />
                </div>
                <div className="navbar-pickers">
                    {shouldRenderPickers && this.renderPickers()}
                </div>
                {this.renderNavigationMenu()}
            </div>
        );
    }

    private renderDate() {
        if (this.isHeatmapView()) {
            return (
                <GQDatePicker
                    onChangeStartDate={(date: Date) => {
                        heatmapStore.setStartDate(date);
                    }}
                    onChangeTimeSpan={(timeSpan: E_TIME_PRESET_KEYS) => {
                        heatmapStore.setTimeSpan(timeSpan);
                    }}
                    startDate={this.props.heatmapStartDate}
                    timespanPresets={HEATMAP_PRESETS}
                    timespan={this.props.heatmapTimeSpan}
                />
            );
        }
        if (this.shouldShowDateRange) {
            return (
                <GQDateRangePicker
                    initialStartDate={this.props.minDate.toDate()}
                    initialEndDate={this.props.maxDate.toDate()}
                    onChange={chartStore.setNewRangeByDates}
                    allowPresetSelectionOutOfRange={true}
                    presets={DATE_PICKER_DEFAULT_PRESETS}
                />
            );
        }
        return (
            <div className="top-nav-date-picker row">
                <div className="top-nav-date">
                    <DatePicker
                        onChange={this.changeVirtualToday}
                        selected={chartStore.virtualToday.toDate()}
                        minDate={new Date(2013, 1, 1)}
                        maxDate={
                            new Date(
                                new Date().getFullYear() + 2,
                                new Date().getMonth(),
                                new Date().getDay()
                            )
                        }
                        dateFormat={DATE_FNS_DATE_DISPLAY_FORMAT}
                        showYearDropdown
                        peekNextMonth={false}
                        customInput={
                            <button
                                style={{
                                    color: '#fff',
                                    fontSize: '14px',
                                    outline: 'none',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}>
                                {' '}
                                {chartStore.virtualToday.format(
                                    MOMENTJS_DATE_DISPLAY_FORMAT
                                )}{' '}
                                <i className="gqi-dropdown" />
                            </button>
                        }
                    />
                </div>
            </div>
        );
    }

    private getWorldButtons() {
        const buttons = [
            {
                key: 'sort-by-risk',
                icon: 'gqi-risk',
                onClick: () => {
                    this.goToURL(VIEW.SORT_BY_RISK);
                },
                active:
                    this.activeView === VIEW.SORT_BY_RISK ||
                    this.insightsView === VIEW.SORT_BY_RISK,
                tooltip: 'Sort by Rank',
            },
            {
                key: 'sort-by-delta',
                icon: 'gqi-delta',
                onClick: () => {
                    this.goToURL(VIEW.SORT_BY_DELTA);
                },
                active:
                    this.activeView === VIEW.SORT_BY_DELTA ||
                    this.insightsView === VIEW.SORT_BY_DELTA,
                tooltip: 'Sort by Delta',
            },
            {
                key: 'heatmap',
                icon: 'gqi-dimension15',
                onClick: this.goToHeatmapPage,
                active: this.activeView === VIEW.HEATMAP ||
                        this.insightsView === VIEW.HEATMAP,
                tooltip: 'Heatmap',
            },
            {
                key: 'worldgraph',
                icon: 'gqi-graph-1',
                onClick: () => {
                    this.goToURL(VIEW.WORLD_GRAPH);
                },
                active:
                    this.activeView === VIEW.WORLD_GRAPH ||
                    this.insightsView === VIEW.WORLD_GRAPH,
                tooltip: 'Line Graph',
            },
        ];
        return _.map(buttons, btn => (
            <GQButton
                key={btn.key}
                icon={btn.icon}
                tooltip={btn.tooltip}
                {...buttonProps}
                active={btn.active}
                onClick={btn.onClick}
            />
        ));
    }

    private getContingentButtons() {
        const buttons = [
            {
                key: 'sort-by-contingent-risk',
                icon: 'gqi-risk',
                onClick: () => {
                    this.goToURL(VIEW.SORT_BY_CONTINGENT_RISK);
                },
                active:
                    this.activeView === VIEW.SORT_BY_CONTINGENT_RISK ||
                    this.insightsView === VIEW.SORT_BY_CONTINGENT_RISK,
                tooltip: 'Sort by Contingent Risk',
            },
            {
                key: 'sort-by-contingent-delta',
                icon: 'gqi-delta',
                onClick: () => {
                    this.goToURL(VIEW.SORT_BY_CONTINGENT_DELTA);
                },
                active:
                    this.activeView === VIEW.SORT_BY_CONTINGENT_DELTA ||
                    this.insightsView === VIEW.SORT_BY_CONTINGENT_DELTA,
                tooltip: 'Sort by Contingent Delta',
            },
            {
                key: 'navbar-contingent-graph',
                icon: 'gqi-graph-1',
                onClick: () => {
                    this.goToURL(VIEW.CONTINGENT_GRAPH);
                },
                active:
                    this.activeView === VIEW.CONTINGENT_GRAPH ||
                    this.insightsView === VIEW.CONTINGENT_GRAPH,
                tooltip: 'Contingent Risk Graph',
                disabled: countryStore.currentCountryList.length < 1,
            },
        ];
        return _.map(buttons, btn => (
            <GQButton
                key={btn.key}
                icon={btn.icon}
                tooltip={btn.tooltip}
                {...buttonProps}
                active={btn.active}
                onClick={btn.onClick}
            />
        ));
    }

    private onNewInsightNotification = () => {
        if (this.activeView !== VIEW.INSIGHTS) {
            this.setState({
                hasNewInsightNotificationFlag: true,
            });
        }
    };

    private onLogOutClick = () => {
        loading.start().then(() => {
            APIService.logout();
            this.props.history.push('/');
            loading.stop();
        });
    };

    private onTermsClick = () => {
        window.open('https://www.fitch.group/policies/', '_blank');
    }

    private getDimensionName = (id: number, getLong?: boolean) => {
        if (this.isContingentCountrySelectorView()) {
            return 'Contingent Risk';
        }
        if (this.isContingentModeView()) {
            return flow(
                find(contingentRisk => id === get('id', contingentRisk)),
                get('name')
            )(this.props.activeContingentRisks);
        }
        if (this.props.isClientFacingIndicators) {
            const cfi = this.props.activeClientFacingIndicators[id];
            return getLong ? getCFILongName(cfi) : getCFIShortName(cfi);
        }
        if (this.props.dimensions && this.props.dimensions[id]) {
            const dimension = this.props.dimensions[id]
            return getLong
                ? getDimensionLongName(dimension)
                : getDimensionShortName(dimension);
        }
        return '';
    };

    private getCountryName(id: number) {
        return get(['countries', id, 'name'], this.props) || '';
    }

    private goToActivityPage = (e: React.MouseEvent<HTMLDivElement>) => {
        this.goToURL(VIEW.INSIGHTS);
    };

    private goToHeatmapPage = () => {
        this.goToURL(VIEW.HEATMAP);
    };

    private goToScoreEventPage = (e: React.MouseEvent<HTMLDivElement>) => {
        this.goToURL(VIEW.SCORE_EVENT, EventsFeedStore.defaultEvent);
    };

    private onWorldClick = () => {
        this.goToURL(VIEW.RISK_VIEW);
    };

    private onContingentClick = () => {
        this.goToURL(VIEW.CONTINGENT);
    };

    private onCreateInsightClick = async (
        e: React.MouseEvent<HTMLDivElement>
    ) => {
        if (includes(this.activeView, INSIGHTS_VIEWS)) {
            this.goToURL(VIEW.CREATE_INSIGHT, this.activeView);
            return;
        }

        this.goToURL(VIEW.CREATE_INSIGHT, VIEW.RISK_VIEW);
    };

    private hideFeedback = () => {
        GQPopupStorage.addData(navbarFeedbackKey, null);
    };

    private openSettingsMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.hideFeedback();
        const { currentTarget } = e;
        GQPopupStorage.addData(navbarSettingsMenuKey, {
            element: currentTarget,
            orientation: 'bottom left',
            tooltipData: this.GQSettingsMenu(),
        });
    };

    private goToSettings = (id: string) => {
        this.goToURL(VIEW.SETTINGS, id);
    };

    private invertScroll = (e: React.WheelEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.currentTarget.scrollBy({ left: e.deltaY });
    };

    private onGQIconClick = () => {
        window.location.href = '/';
    };

    private GQSettingsMenu() {
        return (
            <div className="gq-navbar-settings-menu">
                {SettingsItems.map(item => {
                    const onClick = () => {
                        this.goToSettings(item.id);
                    };
                    return (
                        <div
                            key={item.id}
                            onClick={onClick}
                            className="gq-navbar-settings-menu-item">
                            {item.label}
                        </div>
                    );
                })}
                <div className="gq-navbar-settings-menu-separator" />
                <div className="gq-navbar-settings-menu-text">
                    {this.props.username}
                </div>
                <div
                    onClick={this.onLogOutClick}
                    className="gq-navbar-settings-menu-item">
                    Logout
                </div>
                <div className="gq-navbar-settings-menu-separator" />
                <div
                    onClick={this.onTermsClick}
                    className="gq-navbar-settings-menu-item"
                    style={{
                        fontSize: 10,
                        fontFamily: 'geoquant',
                        height: 'auto',
                        alignItems: 'end',
                    }}>
                    Terms & Policies
                </div>
            </div>
        );
    }

    private hideSettingsMenu() {
        GQPopupStorage.addData(navbarSettingsMenuKey, null);
    }

    private get shouldShowDateRange() {
        const view =
            this.activeView === VIEW.CREATE_INSIGHT
                ? this.insightsView
                : this.activeView;
        return includes(view, DATE_RANGE_VIEWS);
    }

    private changeVirtualToday(date: Date) {
        chartStore.setVirtualToday(moment(date));
    }
}

export default withRouter(GQNavbar);
