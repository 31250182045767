import * as _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import { observer, inject } from 'mobx-react';
import TransitionGroup from 'react-addons-transition-group';
import classNames from 'classnames';
import { VIEW } from '../../interfaces';
import EventItem from './EventItem';
import GQTabs, { IGQTab } from '../GQTabs/GQTabs';
import { IMobxRootState } from '../../RootStore';
import GQButton from '../GQButton/GQButton';
import eventsFeedStore from './EventsFeedStore';
import chartStore from '../GQChart/ChartStore';
import GQTopIndicators from './GQTopIndicators';
import AnimationUtils from '../../utils/AnimationUtils';
import EventsService, { EventSubscription } from '../../services/EventsService';
import {
    EVENTS_FILTER,
    IEventsData,
    SCORED_EVENTS_FILTER,
} from './EventsFeedInterfaces';
import EventsListHeader from './EventsListHeader';
import CancelableEvents from '../../utils/CancelableEvents';
import GQTopPredictions from './GQTopPredictions';
import GQTopInsights from './GQTopInsights';
import AnimatedArrow from './GQAnimatedArrow';
import { GQToastContainer } from '../GQToast/GQToastContainer';
import { E_TOAST_TYPE } from '../GQToast/GQToast';
import APIService, { IActiveDimension } from '../../services/APIService';
import GQLoader from '../GQLoader/GQLoader';
import DebouncedInput from 'components/shared/DebouncedInput';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { CHART_TOOLBAR_HEIGHT } from '../../constants';
import { findIndex, isNil, isEmpty, keyBy } from 'lodash/fp';
import { getEventCountries, isEventNew } from './eventUtils';

type Props = {
    eventList: IEventsData[];
    tempEvents: IEventsData[];
    onEventClick: (eventID: number) => void;
    loadMoreEvents: () => void;
    loadInProgress: boolean;
    activeEventID?: number;
    activeFilter?: EVENTS_FILTER;
    isMinimized?: boolean;
    onEventDelete: (id: number) => void;
    searchString?: string;
    isDebug?: boolean;
    isCollapsed?: boolean;
    selectedCountries?: number[];
    onViewChange?: (view: VIEW, ...args: any[]) => void;
    onTopIndicatorClicked?: (dimensionName: string, countryId: number) => void;
    scoredEventsFilter: SCORED_EVENTS_FILTER;
    isAnalyst?: boolean;
    canFilterScoredEvents?: boolean;
    canDeleteOldEvents?: boolean;
    dimensions?: { [dimensionId: number]: IActiveDimension };
    showMaximize?: boolean;
    showSearchBar?: boolean;
    searchFocused?: boolean;
    includeMentionCountries?: boolean;
};

type RowItemData = {
    activeEventID: number;
};

const DateSeparator = (props: { caption: string }) =>
    props.caption ? (
        <div className="day-divider">
            <div className="day-left-border" />
            <div className="day-caption">
                <span>{props.caption}</span>
            </div>
            <div className="day-right-border" />
        </div>
    ) : (
        <div className="day-divider">
            <div className="day-left-border" />
        </div>
    );

const EVENT_ITEM_HEIGHT = 130;
const SEPARATOR_HEIGHT = 32;
const SEARCH_BAR_HEIGHT = 80;
const EVENT_ITEM_WITH_SEPARATOR_HEIGHT = EVENT_ITEM_HEIGHT + SEPARATOR_HEIGHT;

const TABS: IGQTab[] = [
    {
        id: EVENTS_FILTER.ALL,
        label: 'All',
    },
    {
        id: EVENTS_FILTER.SCORED,
        label: 'Scored',
    },
    {
        id: EVENTS_FILTER.UNSCORED,
        label: 'Pending',
    },
];

const SCORED_EVENTS_TABS: IGQTab[] = [
    {
        id: SCORED_EVENTS_FILTER.ALL,
        label: 'All',
    },
    {
        id: SCORED_EVENTS_FILTER.HUMAN,
        label: 'Human',
    },
    {
        id: SCORED_EVENTS_FILTER.MACHINE,
        label: 'Machine',
    },
];

@inject(
    ({
        scoreEventStore,
        eventsFeedStore,
        appStore,
        UserStore,
        risksStore,
    }: IMobxRootState) => {
        return {
            activeEventID:
                scoreEventStore.activeEvent !== null
                    ? scoreEventStore.activeEvent.id
                    : null,
            activeFilter: eventsFeedStore.currentFilter,
            isMinimized: eventsFeedStore.isMinimized,
            isCollapsed: eventsFeedStore.isCollapsed,
            searchString: eventsFeedStore.searchString,
            isDebug: appStore.debugMode,
            isAnalyst: UserStore.geoquantFlagsPermission.is_analyst,
            canFilterScoredEvents:
                UserStore.uscoresPermissions.score_events_feed_by_type,
            canDeleteOldEvents:
                UserStore.uscoresPermissions.can_edit_old,
            dimensions: keyBy(
                (dim: IActiveDimension) => dim.id,
                risksStore.allDimensions
            ),
            showMaximize: eventsFeedStore.currentFilter,
            showSearchBar: eventsFeedStore.showSearchBar,
            searchFocused: eventsFeedStore.searchFocused,
            includeMentionCountries:
                UserStore.geoquantFlagsPermission.include_mention_countries,
        };
    }
)
@observer
export default class EventsFeed extends React.Component<Props> {
    private searchBarRef: HTMLDivElement = null;
    private escSub: EventSubscription = null;
    private searchBarAnimating = false;
    private cancelableEvents = new CancelableEvents();
    private toaster: GQToastContainer;

    public componentDidMount() {
        this.escSub = EventsService.registerOnEscKeyboardClick(() => {
            if (this.props.searchFocused) {
                this.resetSearchFilter();
            }
        });
        if (this.props.showSearchBar) {
            this.searchBarRef.style.height = `${SEARCH_BAR_HEIGHT}px`;
        }
    }

    public componentWillUnmount() {
        if (this.escSub) {
            this.escSub.remove();
        }
        this.cancelableEvents.cancelAll();
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.isMinimized !== this.props.isMinimized) {
            this.cancelableEvents.setTimeout(() => {
                const elem = document.querySelector('.chart-container');
                if (!elem) {
                    return;
                }

                chartStore.setState({
                    width: elem.clientWidth,
                    height: elem.clientHeight - CHART_TOOLBAR_HEIGHT,
                });
            }, 300);
            this.cancelableEvents.setTimeout(() => {
                eventsFeedStore.setShowMaximize(this.props.isMinimized);
            }, 800);
            return;
        }

        if (
            !isNil(this.props.activeEventID) &&
            isNil(prevProps.activeEventID) &&
            !isNil(this.listRef) &&
            !isEmpty(this.props.eventList)
        ) {
            const index = findIndex(
                item => item.id === this.props.activeEventID,
                this.props.eventList
            );
            this.listRef?.current?.scrollToItem(index, 'start');
            return;
        }
    }

    public render() {
        const { isMinimized } = this.props;

        return (
            <div
                className={cx([
                    'events',
                    {
                        minimized: isMinimized,
                    },
                ])}>
                <div>
                    <div className="eventsHeader">
                        <div className="eventsHeaderTitle" id="alertFeed">
                            <span
                                className={cx('eventsTitle', {
                                    hide: this.props.isCollapsed,
                                })}>
                                Alert Feed
                            </span>
                            <div className="filler" />
                            <GQButton
                                icon="gqi-arrow-left"
                                noBorder={true}
                                onClick={this.minimizeEventsFeed}
                                style={{ paddingRight: 0 }}
                            />
                        </div>
                        <AnimatedArrow
                            arrowMode="down"
                            onClick={this.onCollapseClick}
                            show={this.props.isCollapsed}
                        />
                    </div>
                    <div
                        className={classNames(['topEventsContainer'], {
                            collapsed: this.props.isCollapsed,
                        })}>
                        <GQTopPredictions
                            onViewChange={this.props.onViewChange}
                        />
                        <GQTopInsights onViewChange={this.props.onViewChange} />
                        <GQTopIndicators
                            onTopIndicatorClicked={
                                this.props.onTopIndicatorClicked
                            }
                        />
                        <AnimatedArrow
                            arrowMode="up"
                            onClick={this.onCollapseClick}
                            show={!this.props.isCollapsed}
                        />
                    </div>
                </div>
                {this.props.isMinimized && this.getExpandToggleButton()}
                <div
                    id="eventsContainer"
                    className={isMinimized ? 'minimized' : ''}>
                    <EventsListHeader
                        selectedCountries={this.props.selectedCountries}
                    />
                    <div id="eventsMenu">
                        <div className="gq-events-feed-menu-buttons">
                            <GQTabs
                                tabs={TABS}
                                onChange={this.onTabChange}
                                selectedTab={this.props.activeFilter}
                            />
                            <div className="gq-events-feed-menu-buttons-search-placeholder">
                                <TransitionGroup>
                                    {!this.props.showSearchBar && (
                                        <GQButton
                                            icon="gqi-search"
                                            noBorder
                                            onClick={this.onSearchFilterClick}
                                            options={{
                                                leaveAnim:
                                                    AnimationUtils.fadeOutToBottom,
                                                enterAnim:
                                                    AnimationUtils.fadeInFromBottom,
                                            }}
                                        />
                                    )}
                                </TransitionGroup>
                            </div>
                        </div>
                        {this.props.canFilterScoredEvents &&
                            this.props.activeFilter ===
                                EVENTS_FILTER.SCORED && (
                                <div className="gq-events-feed-menu-buttons">
                                    <GQTabs
                                        tabs={SCORED_EVENTS_TABS}
                                        onChange={this.onScoredFeedTabChange}
                                        selectedTab={
                                            this.props.scoredEventsFilter
                                        }
                                    />
                                </div>
                            )}
                        <div
                            className="gq-events-feed-search-bar"
                            ref={el => {
                                this.searchBarRef = el;
                            }}>
                            <div className="gq-events-feed-search-bar-content">
                                <DebouncedInput
                                    id="eventsFeedSearchInput"
                                    placeholder={`Search in \`${
                                        _.find(
                                            TABS,
                                            ({ id }) =>
                                                id ===
                                                eventsFeedStore.currentFilter
                                        ).label
                                    }\``}
                                    initialValue={this.props.searchString}
                                    onChangeDebounced={
                                        this.onSearchFilterChange
                                    }
                                    onFocus={this.onFocusSearch}
                                    onBlur={this.onBlurSearch}
                                />
                                {this.props.searchString.length ? (
                                    <GQButton
                                        icon="gqi-clear"
                                        onClick={this.resetSearchFilter}
                                        noBorder
                                    />
                                ) : (
                                    <GQButton
                                        icon="gqi-close"
                                        onClick={this.resetSearchFilter}
                                        noBorder
                                        active
                                    />
                                )}
                            </div>
                            {this.props.showSearchBar && (
                                <div className="gq-events-feed-search-bar-indicator">
                                    <i
                                        className="gqi-info"
                                        style={{
                                            paddingRight: 4,
                                            lineHeight: 1,
                                        }}
                                    />
                                    Showing search results only
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="eventsWrapper">
                        {this.props.tempEvents.length > 0 && (
                            <div id="tempEvents">
                                <DateSeparator
                                    caption={`Scored - ${_.first(
                                        this.props.tempEvents
                                    ).time.format('MMM D, YYYY')}`}
                                />
                                {_.map(this.props.tempEvents, tempEvent => {
                                    return (
                                        <div key={`tempEvent_${tempEvent.id}`}>
                                            <div className="event-item-container">
                                                <EventItem
                                                    dimensions={
                                                        this.props.dimensions
                                                    }
                                                    {...tempEvent}
                                                    isActive={
                                                        tempEvent.id ===
                                                        this.props.activeEventID
                                                    }
                                                    isTemp={true}
                                                    onClick={
                                                        this.handleEventClick
                                                    }
                                                    is_analyst={false}
                                                    onDelete={
                                                        this.onEventDelete
                                                    }
                                                    isDebug={this.props.isDebug}
                                                    canDeleteEvent={isEventNew(tempEvent) || this.props.canDeleteOldEvents}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <DateSeparator caption="" />
                            </div>
                        )}
                        <div id="eventsList">{this.renderEventsList()}</div>
                    </div>
                </div>
                <GQToastContainer ref={el => (this.toaster = el)} />
            </div>
        );
    }

    private renderRow = ({ index, style, data }) => {
        if (!this.isEventLoaded(index) && this.props.loadInProgress) {
            return (
                <div style={style}>
                    <GQLoader style={{ marginBottom: '15px' }} />
                </div>
            );
        }

        const event = this.props.eventList[index];
        if (!event) {
            console.log('invalid event index', index);
            return null;
        }

        const previousEvent = this.props.eventList[index - 1];
        const shouldDrawSeparator =
            previousEvent &&
            index > 0 &&
            event.dateString !== previousEvent.dateString;

        return (
            <div style={style}>
                {shouldDrawSeparator && (
                    <DateSeparator caption={event.dateString} />
                )}
                <EventItem
                    bullshit_metric={event.bullshit_metric}
                    countries={getEventCountries(
                        event,
                        this.props.includeMentionCountries
                    )}
                    created_at={event.created_at}
                    focus_countries={event.focus_countries}
                    headline={event.headline}
                    id={event.id}
                    mentioned_countries={event.mentioned_countries}
                    source={event.source}
                    time={event.time}
                    url={event.url}
                    _score={event._score}
                    debugData={event.debugData}
                    matchFilter={event.matchFilter}
                    uscores={event.uscores}
                    isTemp={false}
                    isActive={event.id === data.activeEventID}
                    onClick={this.handleEventClick}
                    is_analyst={this.props.isAnalyst}
                    onDelete={this.onEventDelete}
                    isDebug={this.props.isDebug}
                    dimensions={this.props.dimensions}
                    canDeleteEvent={isEventNew(event) || this.props.canDeleteOldEvents}
                />
            </div>
        );
    };

    private isEventLoaded = index => index < _.size(this.props.eventList);

    private loadMoreItems = () => this.props.loadMoreEvents();

    private getItemSize = (index: number) => {
        if (index > 0 && !this.isEventLoaded(index)) {
            return EVENT_ITEM_WITH_SEPARATOR_HEIGHT;
        }
        const currentEvent = this.props.eventList[index];
        const previousEvent = this.props.eventList[index - 1];
        if (
            currentEvent &&
            previousEvent &&
            currentEvent.dateString !== previousEvent.dateString
        ) {
            return EVENT_ITEM_WITH_SEPARATOR_HEIGHT;
        }
        return EVENT_ITEM_HEIGHT;
    };

    private listRef = React.createRef<List>();

    private renderEventsList = () => {
        const { eventList } = this.props;
        if (_.isEmpty(eventList)) {
            return <GQLoader />;
        }

        const itemData: RowItemData = {
            activeEventID: this.props.activeEventID,
        };

        return (
            <AutoSizer>
                {({ height, width }) => (
                    <InfiniteLoader
                        itemCount={_.size(this.props.eventList) + 1}
                        isItemLoaded={this.isEventLoaded}
                        loadMoreItems={this.loadMoreItems}>
                        {({ onItemsRendered, ref }) => {
                            return (
                                <List
                                    height={height}
                                    width={width}
                                    itemCount={_.size(this.props.eventList) + 1}
                                    itemSize={this.getItemSize}
                                    onItemsRendered={onItemsRendered}
                                    ref={list => {
                                        // this is not a normal React ref -
                                        // https://github.com/bvaughn/react-window/issues/324#issuecomment-528887341
                                        ref(list); // Give InfiniteLoader a reference to the list
                                        this.listRef = { current: list }; // Set your own ref to it as well.
                                    }}
                                    estimatedItemSize={EVENT_ITEM_HEIGHT}
                                    itemData={itemData}>
                                    {this.renderRow}
                                </List>
                            );
                        }}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        );
    };

    private onEventDelete = async (id: number) => {
        this.toaster.createToast('The event will be deleted in 5 seconds', {
            type: E_TOAST_TYPE.SUCCESS,
            duration: 5000,
            showCloseButton: false,
            onDurationEnd: this.actuallyDeleteEvent.bind(this, id),
            buttons: [<GQButton key="activity_undo_delete" caption="Cancel" />],
        });
    };

    private async actuallyDeleteEvent(id: number) {
        try {
            this.cancelableEvents
                .promise(() => APIService.deleteEvent(id))
                .then(() => {
                    if (this.props.onEventDelete) {
                        this.props.onEventDelete(id);
                    }
                });
        } catch (err) {
            console.error(err);
            this.toaster.createToast(
                `Failed deleting event #${id}: ${JSON.stringify(err)}`,
                {
                    type: E_TOAST_TYPE.ERROR,
                    showCloseButton: true,
                }
            );
        }
    }

    private onSearchFilterChange = (value: string) => {
        eventsFeedStore.setSearchString(value);
    };

    private resetSearchFilter = () => {
        if (this.props.showSearchBar && !this.searchBarAnimating) {
            this.searchBarAnimating = true;
            AnimationUtils.animateHeight(this.searchBarRef, 55, 0, {
                onComplete: () => {
                    eventsFeedStore.setSearchString(null);
                    eventsFeedStore.setShowSearchBar(false);
                    this.searchBarAnimating = false;
                },
            });
        }
    };

    private onSearchFilterClick = () => {
        eventsFeedStore.setShowSearchBar(true);
        this.cancelableEvents.setTimeout(() => {
            AnimationUtils.animateHeight(this.searchBarRef, 0, SEARCH_BAR_HEIGHT, {
                onComplete: () => {
                    this.cancelableEvents.setTimeout(() => {
                        this.focusSearch();
                    });
                },
            });
        });
    };

    private focusSearch = () => {
        const el = document.getElementById('eventsFeedSearchInput');
        if (el) {
            el.focus();
        }
    };

    private onFocusSearch = () => {
        eventsFeedStore.setSearchFocused(true);
    };

    private onBlurSearch = () => {
        eventsFeedStore.setSearchFocused(false);
    };

    private onTabChange = (id: string) => {
        // Timeout for smooth animation
        this.cancelableEvents.setTimeout(() => {
            eventsFeedStore.setCurrentFilter(id as EVENTS_FILTER);
        }, 50);
    };

    private onScoredFeedTabChange = (id: string) => {
        this.cancelableEvents.setTimeout(() => {
            eventsFeedStore.setScoredEventsFilter(id as SCORED_EVENTS_FILTER);
        }, 50);
    };

    private getExpandToggleButton() {
        const params: {
            icon: string;
            style: React.CSSProperties;
            tooltip: string;
        } = {
            icon: 'gqi-arrow-right',
            style: {
                position: 'fixed',
                top: 69,
                left: 0,
                zIndex: 999,
                opacity: this.props.showMaximize ? 1 : 0,
                transform: `scale(${this.props.showMaximize ? 1 : 0})`,
            },
            tooltip: 'Expand events',
        };
        return (
            <GQButton
                onClick={this.onMinimizeClick}
                noBorder={true}
                icon={params.icon}
                style={params.style}
                className="events-toggle-expand"
            />
        );
    }
    private minimizeEventsFeed = (e: React.MouseEvent<HTMLDivElement>) => {
        eventsFeedStore.setMinimized(!this.props.isMinimized);
    };

    private onMinimizeClick = () => {
        eventsFeedStore.setMinimized(!this.props.isMinimized);
    };
    private onCollapseClick = () => {
        eventsFeedStore.setcollapse(!this.props.isCollapsed);
        eventsFeedStore.setCollapseClickedValue(!this.props.isCollapsed);
    };

    private handleEventClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const element = e.currentTarget as HTMLDivElement;
        if (element.id) {
            this.props.onEventClick(Number(element.id));
        } else {
            const parent = this.recurseParents(element, 0);
            this.props.onEventClick(Number(parent.id));
        }
    };

    private recurseParents = (
        element: HTMLDivElement,
        depth: number
    ): HTMLDivElement => {
        if (depth <= 2) {
            if (element.parentElement.id) {
                return element.parentElement as HTMLDivElement;
            } else {
                this.recurseParents(
                    element.parentElement as HTMLDivElement,
                    ++depth
                );
            }
        } else {
            return element;
        }
    };
}
