import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {
    HeatmapCellType,
    HeatmapCellPosition,
    HeatmapPercentile,
    HeatmapRisk,
    HeatmapScoreType,
} from './HeatmapTypes';
import Heatmap from './Heatmap';
import APIService, {
    E_RISK_TYPE,
    IActiveDimension,
    IActiveDimensionTree,
    IActiveCountry,
    IActiveClientFacingIndicator,
    IActiveGroup,
} from 'services/APIService';
import {
    map,
    get,
    isEmpty,
    filter,
    flatten,
    values,
    flow,
    sortBy,
    uniqBy,
    find,
    findIndex,
    includes,
    keyBy,
    first,
} from 'lodash/fp';
import moment from 'moment';
import { C_SCORES_DATE_FORMAT, TIME_WINDOW_PRESETS } from '../../constants';
import HeatmapTooltip from './HeatmapTooltip';
import GQLoader from 'components/GQLoader/GQLoader';
import { HEATMAP_PERCENTILES } from './HeatmapPercentiles';
import { inject } from 'mobx-react';
import { IMobxRootState } from '../../RootStore';
import heatmapStore from '../../stores/HeatmapStore';
import { isHeatmapPercentileVisible } from 'utils/generalUtils';
import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import loading from '../../services/LoadingService';
import { getRiskLongNames } from 'utils/riskUtils';

const redPercentiles = filter(
    (percentile: HeatmapPercentile) => percentile.type === 'red',
    HEATMAP_PERCENTILES
).sort((p1, p2) => p2.percentile - p1.percentile);

const greenPercentiles = filter(
    (percentile: HeatmapPercentile) => percentile.type === 'green',
    HEATMAP_PERCENTILES
).sort((p1, p2) => p2.percentile - p1.percentile);

export const getHeatmapPercentile = (
    percentileValue: number
): HeatmapPercentile => {
    const isRiskRising = percentileValue > 0;
    const valueAbs = Math.abs(percentileValue);
    const percentilesGroup = isRiskRising ? redPercentiles : greenPercentiles;
    for (let percentile of percentilesGroup) {
        if (valueAbs >= percentile.percentile) {
            return percentile;
        }
    }
    return null;
};

const getListFromTree = (
    dimensionsTree: IActiveDimensionTree
): IActiveDimension[] => {
    return [
        dimensionsTree,
        ...flatten(
            map(
                (childTree: IActiveDimensionTree) => getListFromTree(childTree),
                dimensionsTree.children
            )
        ),
    ];
};

interface IHeatmapProviderProps {
    visibleCountryIds?: number[];
    minDate?: number;
    numDays?: number;
    selectedCountryId?: number;
    riskType?: E_RISK_TYPE;
    scoreType?: HeatmapScoreType;
    selectedRisk?: number;
    activeGroupId?: string;
    disableClickOutside?: boolean;
    disableTooltip?: boolean;
    disableActiveGroup?: boolean;
    disableToggle?: boolean;
    selectedPercentile?: HeatmapPercentile;
    isAnalyst?: boolean;
}

export const HeatmapProvider = inject(
    ({ heatmapStore, settingsStore, UserStore }: IMobxRootState) => {
        return {
            visibleCountryIds: heatmapStore.countriesList,
            selectedCountryId: heatmapStore.selectedCountry,
            riskType: heatmapStore.riskType,
            scoreType: heatmapStore.scoreType,
            selectedRisk: heatmapStore.selectedRisk,
            minDate: heatmapStore.startDate,
            numDays: TIME_WINDOW_PRESETS[heatmapStore.timeSpan].val,
            selectedPercentile: heatmapStore.selectedPercentile,
            activeGroupId: settingsStore.activeGroupId,
            isAnalyst: UserStore.geoquantFlagsPermission.is_analyst,
        };
    }
)(
    ({
        visibleCountryIds,
        selectedRisk,
        riskType,
        selectedCountryId,
        minDate,
        numDays,
        selectedPercentile,
        activeGroupId,
        scoreType,
        disableClickOutside,
        disableTooltip,
        disableActiveGroup,
        disableToggle,
        isAnalyst
    }: IHeatmapProviderProps) => {
        const [isInitialized, setIsInitialized] = useState<boolean>(false);

        const [heatmapCells, setHeatmapCells] = useState<HeatmapCellType[][]>(
            []
        );
        const [isChangingRiskType, setIsChangingRiskType] = useState<boolean>(
            false
        );
        const [isChangingScoreType, setIsChangingScoreType] = useState<boolean>(
            false
        );
        const [isChangingExternalProp, setIsChangingExternalProp] = useState<
            boolean
        >(false);
        const [dimensionsList, setDimensionsList] = useState<
            IActiveDimension[]
        >(null);
        const [clientFacingIndicators, setClientFacingIndicators] = useState<
            IActiveClientFacingIndicator[]
        >(null);
        const [allCountries, setAllCountries] = useState<IActiveCountry[]>(
            null
        );
        const [hoveredCell, setHoveredCell] = useState<HeatmapCellPosition>(
            null
        );
        const [thresholdsData, setThresholdsData] = useState<
            HeatmapCellType[][]
        >([]);

        const [allCountriesById, allCountriesByName] = useMemo(() => {
            return [
                keyBy(country => country.id, allCountries),
                keyBy(country => country.name, allCountries),
            ];
        }, [allCountries]);

        const countryNames = useMemo(() => {
            return flow(
                filter((country: IActiveCountry) =>
                    includes(country.id, visibleCountryIds)
                ),
                map(get('name'))
            )(allCountries);
        }, [allCountries, visibleCountryIds]);

        const startDate = useMemo(
            () => moment(minDate || moment()).format(C_SCORES_DATE_FORMAT),
            [minDate]
        );

        useEffect(() => {
            (async () => {
                const [
                    indicators,
                    dimensionsTree,
                    activeCountries,
                ] = await Promise.all([
                    APIService.getActiveClientFacingIndicators(),
                    APIService.getActiveDimensionsTree(),
                    APIService.getActiveCountries(),
                ]);

                const countries = values(
                    activeCountries.licensed
                ).sort((c1, c2) => c1.name.localeCompare(c2.name));
                setAllCountries(countries);

                const clientFacingIndicators = flow(
                    values,
                    filter(
                        (indicator: IActiveClientFacingIndicator) =>
                            !indicator.disabled
                    ),
                    sortBy(indicator => indicator.order)
                )(indicators);
                setClientFacingIndicators(clientFacingIndicators);

                //todo: remove uniqBy and use parentId or a backend unique id
                const dimensionsList = uniqBy(
                    'id',
                    getListFromTree(dimensionsTree)
                );
                setDimensionsList(dimensionsList);

                setIsInitialized(true);
            })();
        }, []);

        useEffect(() => {
            if (disableActiveGroup){
                return;
            }

            APIService.getActiveGroups().then(activeGroups => {
                const activeGroupCountriesList = flow(
                    get('groups'),
                    find((group: IActiveGroup) => group.id === activeGroupId),
                    get('countries')
                )(activeGroups);

                if (!isEmpty(activeGroupCountriesList)) {
                    heatmapStore.setCountriesList(activeGroupCountriesList);
                }
            });
        }, [activeGroupId, disableActiveGroup]);

        const fetchHeatmap = useCallback(
            async (riskType: E_RISK_TYPE, scoreType: HeatmapScoreType) => {
                loading.start();
                const percentilesData = await APIService.getHeatmap(
                    riskType,
                    scoreType,
                    startDate,
                    numDays,
                    visibleCountryIds
                );

                const risks: HeatmapRisk[] =
                    riskType === E_RISK_TYPE.CLIENT_FACING_INDICATORS
                        ? map(
                              indicator => ({
                                  id: indicator.id,
                                  title: indicator.display_name,
                              }),
                              clientFacingIndicators
                          )
                        : map(
                              dimension => ({
                                  id: dimension.id,
                                  title: dimension.lname,
                              }),
                              dimensionsList
                          );

                const thresholdsData = map(countryName => {
                    return map(risk => {
                        const country = get(countryName, allCountriesByName);
                        const percentileValue =
                            get([country.id, risk.id], percentilesData) || 1;
                        const percentile: HeatmapPercentile = getHeatmapPercentile(
                            percentileValue
                        );

                        return {
                            value: percentileValue,
                            color: get('color', percentile),
                            isVisible: !!percentile,
                            clickable: !!percentile,
                            percentile,
                            tooltip:
                                !disableTooltip &&
                                (() => (
                                    <HeatmapTooltip
                                        country={country}
                                        risk={risk}
                                        riskType={riskType}
                                        scoreType={scoreType}
                                        minDate={minDate}
                                        numDays={numDays}
                                        isAnalyst={isAnalyst}
                                    />
                                )),
                        } as HeatmapCellType;
                    }, risks);
                }, countryNames);
                setThresholdsData(thresholdsData);
                loading.stop();
            },
            [
                startDate,
                numDays,
                visibleCountryIds,
                allCountriesByName,
                clientFacingIndicators,
                countryNames,
                dimensionsList,
                disableTooltip,
                isAnalyst,
                minDate,
            ]
        );

        useEffect(() => {
            const riskIndex =
                riskType === E_RISK_TYPE.DIMENSIONS
                    ? findIndex(
                          (dimension: IActiveDimension) =>
                              dimension.id === selectedRisk,
                          dimensionsList
                      )
                    : findIndex(
                          (indicator: IActiveClientFacingIndicator) =>
                              indicator.id === selectedRisk,
                          clientFacingIndicators
                      );

            const selectedCountryName = get(
                [selectedCountryId, 'name'],
                allCountriesById
            );
            const countryIndex = findIndex(
                (countryName: string) => countryName === selectedCountryName,
                countryNames
            );

            const cells = thresholdsData.map(
                (row: HeatmapCellType[], rowIndex: number) =>
                    row.map((cell: HeatmapCellType, columnIndex: number) => {
                        if (!cell.percentile) {
                            return {
                                ...cell,
                                isVisible: false,
                            };
                        }

                        if (
                            isHeatmapPercentileVisible(
                                cell.percentile,
                                selectedPercentile
                            ) ||
                            riskIndex === columnIndex ||
                            countryIndex === rowIndex
                        ) {
                            return {
                                ...cell,
                                isVisible: true,
                            };
                        }
                        return {
                            ...cell,
                            isVisible:
                                !selectedPercentile &&
                                riskIndex === -1 &&
                                countryIndex === -1,
                        };
                    })
            );
            setHeatmapCells(cells);
        }, [
            allCountriesById,
            clientFacingIndicators,
            countryNames,
            dimensionsList,
            riskType,
            selectedCountryId,
            selectedPercentile,
            selectedRisk,
            thresholdsData,
        ]);

        const prevStartDate = useRef<string>(null);
        const prevNumDays = useRef<number>(null);
        const prevVisibleCountryIds = useRef<number[]>(null);

        useEffect(() => {
            (async () => {
                if (!isInitialized || isEmpty(visibleCountryIds)) {
                    return;
                }

                // to following 'if' + useRef usage is necessary because the
                // 'fetchHeatmap' function is one of the dependencies of this function,
                // and fetchHeatmap will change every time the risk/score type will change,
                // thus causing the current function to re-run (and refetch) even though
                // the startDate/numDays did not change.
                // the other way to solve this issue is to move the startDate/numDays
                // onChange event handlers into the heatmap provider,
                // and then delete this useEffect
                if (
                    prevNumDays.current === numDays &&
                    prevStartDate.current === startDate &&
                    prevVisibleCountryIds.current === visibleCountryIds
                ) {
                    return;
                }

                prevNumDays.current = numDays;
                prevStartDate.current = startDate;
                prevVisibleCountryIds.current = visibleCountryIds;
                setIsChangingExternalProp(true);
                await fetchHeatmap(riskType, scoreType);
                setIsChangingExternalProp(false);
            })();
        }, [
            startDate,
            numDays,
            fetchHeatmap,
            isInitialized,
            visibleCountryIds,
            riskType,
            scoreType,
        ]);

        useEffect(() => {
            if (!selectedRisk) {
                heatmapStore.setTitle(
                    riskType === E_RISK_TYPE.CLIENT_FACING_INDICATORS
                        ? 'FOCUS RISKS'
                        : 'FUNDAMENTAL RISKS'
                );
                return;
            }
            const selectedRiskName = first(
                getRiskLongNames([{ type: riskType, id: selectedRisk }])
            );
            heatmapStore.setTitle(selectedRiskName);
        }, [riskType, selectedRisk, clientFacingIndicators, dimensionsList]);

        const onClickOutside = useCallback((event: any) => {
            const headerElement = document.querySelector('.navbar');
            const clickedInsideHeader = headerElement.contains(event.target);
            // without the following 'if' - clicking a map view link on
            // the header will cause the selected country+risk to be reset,
            // but this is bad since the map view should remember the
            // heatmap's selected country+risk
            if (clickedInsideHeader) {
                return;
            }

            heatmapStore.setSelectedPercentile(null);
            heatmapStore.resetSelection();
        }, []);

        const onCellPress = useCallback(
            (rowIndex: number, columnIndex: number) => {
                if (selectedCountryId || selectedRisk) {
                    heatmapStore.resetSelection();
                    return;
                }

                const risks =
                    riskType === E_RISK_TYPE.DIMENSIONS
                        ? dimensionsList
                        : clientFacingIndicators;
                const selectedRiskId = get([columnIndex, 'id'], risks);
                const countryName = get(rowIndex, countryNames);
                const newCountryId = get(
                    [countryName, 'id'],
                    allCountriesByName
                );

                heatmapStore.setSelectedPercentile(null);
                heatmapStore.setSelectedRisk(selectedRiskId);
                heatmapStore.setSelectedCountry(newCountryId);
            },
            [
                selectedCountryId,
                selectedRisk,
                riskType,
                dimensionsList,
                clientFacingIndicators,
                countryNames,
                allCountriesByName,
            ]
        );

        const onCellMouseEnter = useCallback(
            (rowIndex: number, colIndex: number) => {
                setHoveredCell({
                    row: rowIndex,
                    column: colIndex,
                });
            },
            []
        );

        const onCellMouseLeave = useCallback(() => {
            setHoveredCell(null);
        }, []);

        const onChangeRiskType = useCallback(
            async (newRiskType: E_RISK_TYPE) => {
                if (newRiskType === riskType) {
                    return;
                }

                heatmapStore.setSelectedPercentile(null);
                heatmapStore.resetSelection();
                setIsChangingRiskType(true);
                await fetchHeatmap(newRiskType, scoreType);
                setIsChangingRiskType(false);
                heatmapStore.setRiskType(newRiskType);
            },
            [riskType, fetchHeatmap, scoreType]
        );

        const onChangeScoreType = useCallback(
            async (newScoreType: HeatmapScoreType) => {
                if (newScoreType === scoreType) {
                    return;
                }

                setIsChangingScoreType(true);
                await fetchHeatmap(riskType, newScoreType);
                setIsChangingScoreType(false);
                heatmapStore.setScoreType(newScoreType);
            },
            [scoreType, fetchHeatmap, riskType]
        );

        const onCountryClick = useCallback(
            (countryName: string) => {
                const newCountryId = get(
                    [countryName, 'id'],
                    allCountriesByName
                );

                if (selectedCountryId === newCountryId) {
                    heatmapStore.setSelectedCountry(null);
                    return;
                }

                heatmapStore.setSelectedCountry(newCountryId);
                heatmapStore.setSelectedRisk(null);
                heatmapStore.setSelectedPercentile(null);
            },
            [allCountriesByName, selectedCountryId]
        );

        const onRiskClick = useCallback(
            (riskId: number) => {
                if (selectedRisk === riskId) {
                    heatmapStore.setSelectedRisk(null);
                    return;
                }

                heatmapStore.setSelectedRisk(riskId);
                heatmapStore.setSelectedCountry(null);
                heatmapStore.setSelectedPercentile(null);
            },
            [selectedRisk]
        );

        const onSelectPercentile = useCallback(
            (percentile: HeatmapPercentile) => {
                const didPressOnSamePercentile =
                    selectedPercentile &&
                    selectedPercentile.percentile === percentile.percentile;

                heatmapStore.setSelectedPercentile(
                    didPressOnSamePercentile ? null : percentile
                );

                heatmapStore.resetSelection();
            },
            [selectedPercentile]
        );

        const hoveredRiskId = useMemo(() => {
            const hoveredColumnIndex = get('column', hoveredCell);
            const riskId = get(
                [hoveredColumnIndex, 'id'],
                riskType === E_RISK_TYPE.DIMENSIONS
                    ? dimensionsList
                    : clientFacingIndicators
            );

            return riskId;
        }, [dimensionsList, hoveredCell, riskType, clientFacingIndicators]);

        const selectedCountryName = useMemo(
            () => get([selectedCountryId, 'name'], allCountriesById),
            [allCountriesById, selectedCountryId]
        );

        if (isEmpty(heatmapCells)) {
            return (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <GQLoader />
                </div>
            );
        }

        return (
            <OutsideClickHandler
                disabled={disableClickOutside}
                onOutsideClick={onClickOutside}
                display="contents">
                <Heatmap
                    disableToggle={disableToggle}
                    cells={heatmapCells}
                    percentiles={HEATMAP_PERCENTILES}
                    onCellPress={onCellPress}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    dimensionsList={dimensionsList}
                    selectedPercentile={selectedPercentile}
                    onSelectPercentile={onSelectPercentile}
                    hoveredCell={hoveredCell}
                    selectedRiskId={selectedRisk}
                    hoveredRiskId={hoveredRiskId}
                    riskType={riskType}
                    scoreType={scoreType}
                    onChangeRiskType={onChangeRiskType}
                    onChangeScoreType={onChangeScoreType}
                    selectedCountry={selectedCountryName}
                    onCountryClick={onCountryClick}
                    countryNames={countryNames}
                    onDimensionClick={onRiskClick}
                    clientFacingIndicators={clientFacingIndicators}
                    numDays={numDays}
                    isChangingRiskType={isChangingRiskType}
                    isChangingScoreType={isChangingScoreType}
                    isChangingExternalProp={isChangingExternalProp}
                />
            </OutsideClickHandler>
        );
    }
);

export const HeatmapPage = () => {
    return (
        <React.Fragment>
            <EventsFeedDataProvider />
            <HeatmapProvider />
        </React.Fragment>
    );
};