import React, { useCallback } from 'react';
import rootStore from '../../RootStore';
import GQCountrySelect from '../GQCountryMode/GQCountrySelect';
import { useHistory, useParams } from 'react-router-dom';

const SelectCountry = () => {
    const { push } = useHistory();
    let { nextView } = useParams();

    const onSelectCountry = useCallback((id: number) => {
        rootStore.countryStore.currentStore.overrideValues(id);
        push(`/${nextView}`);
    }, [push, nextView]);

    return <GQCountrySelect onCountrySelected={onSelectCountry} />;
};

export default SelectCountry;
