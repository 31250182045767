import { observable, toJS } from 'mobx';
import {
    E_COMPONENT_TYPE,
    INote,
    E_CHART_GROUP_IDENTIFIERS,
    E_RISK_TYPE,
    IChartNote,
    IMapNote,
} from '../../services/lib/APIServiceInterfaces';
import { generateID } from '../../utils/generalUtils';
export class ActivityBaseNoteStore implements INote {
    public id: string;
    @observable public noteType: E_COMPONENT_TYPE;
    @observable public countryID: number;
    @observable public text: string;
    constructor(note: INote) {
        this.id = note.id;
        this.countryID = note.countryID;
        this.noteType = note.noteType;
        this.text = note.text;
    }

    public toJS(): INote {
        return {
            id: this.id,
            noteType: toJS(this.noteType),
            countryID: toJS(this.countryID),
            text: toJS(this.text),
        };
    }
}
export interface IActivityChartNoteStoreProps extends IChartNote {
    yValue?: number;
}
export class ActivityChartNoteStore extends ActivityBaseNoteStore
    implements IChartNote {
    @observable public readonly noteType = E_COMPONENT_TYPE.CHART;
    @observable public readonly date: Date;
    @observable public readonly lineType: E_CHART_GROUP_IDENTIFIERS;
    @observable public readonly riskID?: number;
    @observable public readonly riskType?: E_RISK_TYPE;
    @observable public readonly lineIdentifier: string;
    @observable public readonly yValue: number;
    constructor(props: IActivityChartNoteStoreProps) {
        super({
            id: props.id ? props.id : generateID(),
            countryID: props.countryID,
            noteType: E_COMPONENT_TYPE.CHART,
            text: props.text || '',
        });
        this.yValue = props.yValue;
        this.lineIdentifier = props.lineIdentifier;
        this.date = props.date;
        this.riskID = props.riskID;
        this.riskType = props.riskType;
    }

    public toJS(): IActivityChartNoteStoreProps {
        const note = super.toJS();
        return {
            ...note,
            noteType: E_COMPONENT_TYPE.CHART,
            date: toJS(this.date),
            lineType: toJS(this.lineType),
            riskID: toJS(this.riskID),
            riskType: toJS(this.riskType),
            lineIdentifier: toJS(this.lineIdentifier),
            yValue: toJS(this.yValue),
        };
    }

    public clone() {
        return new ActivityChartNoteStore(this.toJS());
    }
}

export class ActivityMapNoteStore extends ActivityBaseNoteStore {
    @observable public noteType:
        | E_COMPONENT_TYPE.DELTA
        | E_COMPONENT_TYPE.CR_DELTA
        | E_COMPONENT_TYPE.MAP
        | E_COMPONENT_TYPE.RISK
        | E_COMPONENT_TYPE.CR_RISK
        | E_COMPONENT_TYPE.CONTINGENT_MAP;
    constructor(props: IMapNote) {
        super(props);
        this.noteType = props.noteType;
    }

    public toJS() {
        return {
            ...super.toJS(),
            noteType: this.noteType as
                | E_COMPONENT_TYPE.DELTA
                | E_COMPONENT_TYPE.CR_DELTA
                | E_COMPONENT_TYPE.MAP
                | E_COMPONENT_TYPE.RISK
                | E_COMPONENT_TYPE.CR_RISK,
        };
    }

    public clone() {
        return new ActivityMapNoteStore(this.toJS());
    }
}

export class ActivityHeatmapNoteStore extends ActivityBaseNoteStore {
    @observable public noteType: E_COMPONENT_TYPE.HEATMAP;
}

export type ActivityNoteStore =
    | ActivityMapNoteStore
    | ActivityChartNoteStore
    | ActivityHeatmapNoteStore;
