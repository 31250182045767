import { E_RISK_TYPE, VIEW } from 'interfaces';
import React, { useEffect } from 'react';
import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import ChartContainer from 'components/GQChart/ChartContainer';
import GQMapProvider from 'components/GQMap/GQMapProvider';
import { useHistory } from 'react-router-dom';
import useInitCountryDim from 'hooks/useInitCountryDim';
import { inject } from 'mobx-react';
import { isEmpty } from 'lodash/fp';
import { IMobxRootState } from 'RootStore';
import ContingentSidebarRiskPicker from 'components/sidebarRiskPicker/ContingentSidebarRiskPicker';

type SortByContingentRiskPageProps = {
    countries?: number[];
};

type SortByContingentRiskProps = {
    showChart?: boolean;
};

export const SortByContingentRiskPage = inject(({ countryStore }: IMobxRootState) => {
    return {
        countries: countryStore.currentCountryList,
    };
})(({ countries }: SortByContingentRiskPageProps) => {
    const { push } = useHistory();
    useEffect(() => {
        if (isEmpty(countries)) {
            push(`/${VIEW.SELECT_COUNTRY}/${VIEW.SORT_BY_CONTINGENT_RISK}`);
        }
    }, [countries, push]);

    if (isEmpty(countries)) {
        return null;
    }
    return (
        <React.Fragment>
            <EventsFeedDataProvider />
            <SortByContingentRisk showChart />
            <ContingentSidebarRiskPicker />
        </React.Fragment>
    );
});

export const SortByContingentRisk = React.memo((props: SortByContingentRiskProps) => {
    let { push } = useHistory();

    const ready = useInitCountryDim({
        riskType: E_RISK_TYPE.CONTINGENT,
        multiCountry: true,
        multiRisk: false,
    });

    return (
        ready && (
            <div className="map-and-chart-container">
                <GQMapProvider view={VIEW.SORT_BY_CONTINGENT_RISK} />
                {props.showChart && (
                    <ChartContainer
                        activeView={VIEW.SORT_BY_CONTINGENT_RISK}
                        onExpand={() => {
                            push(`/${VIEW.CONTINGENT_GRAPH}`);
                        }}
                        changeDataWhenDragStop={true}
                    />
                )}
            </div>
        )
    );
});

