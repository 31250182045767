import { E_TAB_FILTER } from 'components/GQActivityPage/activityPage/ActivityPageInterfaces';
import { filter, includes } from 'lodash/fp';
import { useEffect, useMemo, useState } from 'react';
import APIService, {
    IBasicActivityRecord,
    INSIGHT_FEED_TYPE,
} from 'services/APIService';

export const useInsights = () => {
    const [insights, setInsights] = useState<IBasicActivityRecord[]>([]);
    const [tab, setTab] = useState<E_TAB_FILTER>(E_TAB_FILTER.GQ);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        APIService.getActivities()
            .then(setInsights)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);
    const filteredInsights = useMemo(() => {
        switch (tab) {
            case E_TAB_FILTER.BMI:
                return filter(
                    (insight: IBasicActivityRecord) =>
                        includes(INSIGHT_FEED_TYPE.BMI, insight.feedTypes),
                    insights
                );
            case E_TAB_FILTER.MY:
                return filter(
                    (insight: IBasicActivityRecord) => insight.createdByMe,
                    insights
                );
            case E_TAB_FILTER.GQ:
            default:
                return filter(
                    (insight: IBasicActivityRecord) =>
                        includes(INSIGHT_FEED_TYPE.Geoquant, insight.feedTypes),
                    insights
                );
        }
    }, [insights, tab]);
    return { insights: filteredInsights, error, loading, tab, setTab };
};
