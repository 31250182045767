import { IEvent, E_RISK_TYPE } from './services/APIService';
import { Moment } from 'moment';

export interface IMapById<TYPE> {
    [id: string]: TYPE;
}
export interface IMenuState {
    currentActive: string;
    buttons: IButton[];
}
export * from './services/lib/APIServiceInterfaces';

export interface IDebugable {
    debugData?: any;
}

export enum VIEW {
    CREATE_INSIGHT = 'create_insight',
    RISK_VIEW = 'risk_view',
    SORT_BY_RISK = 'sort_by_risk',
    SORT_BY_CONTINGENT_RISK = 'sort_by_contingent_risk',
    SORT_BY_DELTA = 'sort_by_delta',
    SORT_BY_CONTINGENT_DELTA = 'sort_by_contingent_delta',
    WORLD_GRAPH = 'world_graph',
    COUNTRY_GRAPH_SELECT_COUNTRY = 'country_graph_select_country',
    SELECT_COUNTRY = 'select_country',
    COUNTRY_GRAPH = 'country_graph',
    CONTINGENT_GRAPH = 'contingent_graph',
    INSIGHTS = 'insights',
    INSIGHT = 'insight',
    SETTINGS = 'settings',
    SCORE_EVENT = 'score_event',
    EXPORT_COMPONENT = 'export_component', //todo: remove 'export' prefix
    INSIGHT_HTML_DAILY = 'insight_daily',
    INSIGHT_HTML_WEEKLY = 'insight_weekly',
    INSIGHT_PDF = 'insight_pdf',
    CONTINGENT = 'contingent',
    HEATMAP = 'heatmap',
    SIGNALS_DAILY = 'signals_daily',
    SIGNALS = 'signals',
    SIGNALS_WEEKLY = 'signals_weekly',
    SIGNALS_MONTHLY = 'signals_monthly',
    PROMPT = 'prompt',
}

export const INSIGHTS_VIEWS = [
    VIEW.RISK_VIEW,
    VIEW.SORT_BY_RISK,
    VIEW.SORT_BY_DELTA,
    VIEW.WORLD_GRAPH,
    VIEW.COUNTRY_GRAPH,
    VIEW.CONTINGENT,
    VIEW.HEATMAP,
    VIEW.CONTINGENT_GRAPH,
    VIEW.SORT_BY_CONTINGENT_RISK,
    VIEW.SORT_BY_CONTINGENT_DELTA
];

export const EXPANDED_CHART_VIEWS: VIEW[] = [
    VIEW.WORLD_GRAPH,
    VIEW.CONTINGENT_GRAPH,
    VIEW.COUNTRY_GRAPH,
];

export const navbarMapGroup = new Set<string>([
    VIEW.RISK_VIEW,
    VIEW.SORT_BY_RISK,
    VIEW.SORT_BY_DELTA,
    VIEW.WORLD_GRAPH,
]);

export const countrySelectors = new Set<string>([
    VIEW.COUNTRY_GRAPH_SELECT_COUNTRY,
    VIEW.SELECT_COUNTRY,
]);

export const navbarCountryGroup = new Set<string>([VIEW.COUNTRY_GRAPH]);

export const navbarContingentGroup = new Set<string>([
    VIEW.CONTINGENT,
    VIEW.CONTINGENT_GRAPH,
    VIEW.SORT_BY_CONTINGENT_DELTA,
    VIEW.SORT_BY_CONTINGENT_RISK,
    VIEW.SORT_BY_CONTINGENT_DELTA,
    VIEW.SORT_BY_CONTINGENT_RISK,
]);

export interface IRootStoreExporter {
    // custom export to override export keys
    customExport?: () => any;
    // default keys to export
    exportKeys?: () => string[];
    // restore method, default is extendObservable (run in action)
    customRestore?: (snap: any) => void;
    // custom converter
    exportConverter?: (key: string, value: any) => any;
    // custom restore (e.g parse date to moment)
    restoreConverter?: (key: string, value: any) => any;
}

export interface IConfiguration {
    connections: {
        webapp_url: string;
    };
    menu: IButton[];
    mapView: {
        minRadi: number;
        maxRadi: number;
    };
}
export interface IButton {
    id: string;
    icon: string;
    label: string;
}

export interface IGeoData {
    name: string;
    abbreviation: string;
    abbreviation_short: string;
    latitude: number;
    longitude: number;
    id: number;
    vx?: number;
    vy?: number;
    x?: number;
    y?: number;
}

export interface ICircleData {
    score_today: number;
    delta: number;
    geo: {
        name: string;
        abbreviation_short: string;
        abbreviation: string;
        latitude: number;
        longitude: number;
        id: number;
    };
    score_size: number;
    delta_color: {
        type: 'up' | 'down' | 'same';
        percent: number;
    };
}

export interface INodeData {
    id: number;
    name: string;
    original_name?: string;
    posX: number;
    posY: number;
    radius: number;
    class?: string;
    opacity?: number;
    score?: number;
    customCircleSvgContent?: SVGGElement;
    riskValue?: number;
    abbreviation?: string;
    abbreviation_short?: string;
    scoreRadius?: number;
}

export interface IContingentNodeData {
    id: number;
    name: string;
    abbreviation?: string;
    posX?: number;
    posY?: number;
    radius: number;
    riskValue?: number;
    x?: number;
    y?: number;
    vx?: number;
    vy?: number;
    longitude?: number;
    latitude?: number;
}
export interface IScoreEventState {
    event: IEvent;
    dimension_id: number;
}

export interface IRootState {
    config: IConfiguration;
    menu: IMenuState;
    map: IMapState;
    user: IUserState;
    app: IAppState;
}

export interface IScoresData {
    scores: IMapNode[];
    identifier: number;
    type: E_RISK_TYPE;
}

export interface IMapState {
    data: IScoresData;
    nodes: IMapNode[];
    showDatePicker: boolean;
    view: string;
}

export interface IUserState {
    userData: {
        username: string;
        user_id: string;
    };
    inputUsername: string;
    inputPassword: string;
    error: string;
}

export interface IExternalDataItem {
    name: string;
    id: ExternalDataItemID;
    onClick: (id: any) => any;
}

export enum ExternalDataItemID {
    CUSTOM_WEIGHT = 1,
    CURRENCY = 2,
    GDP = 3,
    OIL = 4,
}

export interface IUserPermissions {
    clientFacingIndicatorsResource: { [key: number]: boolean };
    countryResource: { [key: number]: boolean };
    dimensionResoure: { [key: number]: boolean };
    geoquantResource: { [key: string]: boolean };
    uscoreResource: { [key: string]: boolean };
    customWeightsResource: { limit: number };
}

export interface IAppState {
    loggedIn: boolean;
    isOnTutorial: boolean;
}

export interface IDate {
    momentObj: Moment;
    readable: string;
}

export interface IScoreEventForm {
    country_id: number;
    impact: number;
    duration: number;
    dimension_id: number;
    event_id: number;
    score_id: string;
    delete: boolean;
}

export interface IMapNode extends d3.SimulationNodeDatum, INodeData {
    delta?: number;
    delta_type?: string;
}

export interface ITransitionGroupComponent {
    componentWillAppear?(callback: () => void): void;
    componentDidAppear?(callback: () => void): void;
    componentWillEnter?(callback: () => void): void;
    componentDidEnter?(callback: () => void): void;
    componentWillLeave?(callback: () => void): void;
    componentDidLeave?(callback: () => void): void;
}

export type AnimatedComponent<P, S> = React.Component<P, S> &
    ITransitionGroupComponent;

export enum E_CW_STATUS {
    PENDING = 1,
    READY = 2,
}

export interface ICWChangeNotification {
    activePresetId: string;
    activePreset: string;
    status?: E_CW_STATUS;
    progress?: number;
}

export interface IDeltaByCountry {
    [countryId: number]: number;
}


export interface IUserOrganization {
    id: string;
    name: string;
    scores_delta_offset_start: number;
    scores_delta_window_size: number;
}

export interface IUserSettings {
    username: string;
    status: string;
    organization: IUserOrganization;
    activeCustomWeightsPreset: ICWChangeNotification;
    permissions: IUserPermissions;
}
