import React from 'react';
import { inject, observer } from 'mobx-react';
import { feature } from 'topojson';
import * as d3 from 'd3';
import * as d3Geo from 'd3-geo';
import { Moment } from 'moment';
import * as _ from 'lodash';
import rootStore, { IMobxRootState } from '../../RootStore';
import GQMap from './GQMap';
import {
    IMapNode,
    IGetScoreDeltaQueryParams,
    IScoresDelta,
    VIEW,
    INote,
} from '../../interfaces';
import APIService, {
    IActiveGroup,
    IActiveCountry,
    IActiveDimension,
} from '../../services/APIService';
import EventService, { EventSubscription } from '../../services/EventsService';
import GQMapNoteAnchor, {
    IGQMapNoteAnchorData,
} from '../GQActivityPage/Insights/MapNoteAnchor';
import loading from '../../services/LoadingService';
import CustomWeightStore from '../../stores/CustomWeightStore';
import {
    first,
    flow,
    get,
    filter,
    includes,
    isNil,
} from 'lodash/fp';
import { RouteComponentProps } from 'react-router';
import { C_FIRST_DAY_HUMAN_SCORES_DATE } from '../../constants';

const wMap = require('../../data/worldMap.json');
const projection = d3Geo
    .geoNaturalEarth1()
    .center([15, 25])
    .scale(260);
const features = feature(wMap, wMap.objects.land);
const geoPathFn = d3Geo.geoPath().projection(projection);

export interface ICustomMapComponentProps<T> {
    x: number;
    y: number;
    data?: T;
}

export interface ICustomMapComponent {
    countryID: number;
    Component: React.ComponentClass<ICustomMapComponentProps<any>>;
    data?: any;
}

interface IGQMapProviderState {
    nodes: IMapNode[];
    geoPath: string;
    showScores: boolean;
    showHalos: boolean;
}

export interface IGQMapProviderProps extends Partial< RouteComponentProps<{ insightsView: VIEW; view: VIEW; }>> 
 {
    identifier?: number;
    dateRangeStart?: Moment;
    dateRangeEnd?: Moment;
    isClientFacingIndicator?: boolean;
    riskId?: number;
    selectedCountries?: number[];
    virtualToday?: Moment;
    activeGroupId?: string;
    view: VIEW;
    annoMode?: boolean;
    annotations?: INote[];
    willMount?: () => void;
    overrideCountriesList?: Set<number>;
    dimensions?: { [dimensionId: number]: IActiveDimension };
    clientWidth?: number;
    contentWidth?: number;
    isEventsFeedMinimized?: boolean;
    isRiskPickerMinimized?: boolean;
}

const stopHold = (timeout?: number) => {
    setTimeout(() => {
        if (rootStore.eventsFeedStore.onHold) {
            rootStore.eventsFeedStore.setState({
                onHold: false,
            });
        }
    }, timeout || 0);
};

const CONTINGENT_SCORES_VIEWS = [
    VIEW.CONTINGENT,
    VIEW.SORT_BY_CONTINGENT_RISK,
    VIEW.SORT_BY_CONTINGENT_DELTA,
];

const RISK_SCORES_VIEWS = [
    VIEW.RISK_VIEW,
    VIEW.SORT_BY_RISK,
    VIEW.SORT_BY_DELTA,
];

@inject(
    ({
        chartStore,
        risksStore,
        countryStore,
        settingsStore,
        insightsStore,
        eventsFeedStore,
    }: IMobxRootState): Partial<IGQMapProviderProps> => {
        // todo:eventually GQMapProvider should receive the countries list as a prop depending on each page's country list data structure
        // (eg - Contingent page will pass the first country + secondary countries list as a single array, while the map page will pass the selected country list array etc.)
        // GQMapProvider should not decide or know how to obtain its country list since this is a concern of each page in the app.
        // the current plan is to:
        // 1. make the GQMapProvider decide which country store to use
        // 2. convert HomeController mega-component into multiple components - each component per app page (eg: ContingentPage, WorldGrpahPage, CountryPage etc.)
        // 3. Each page will have (or not have) its own map component which will receive the countries list as a prop from the page component
        return {
            identifier: risksStore.currentRisksType,
            isClientFacingIndicator: risksStore.isClientFacingIndicators,
            dateRangeStart: chartStore.rangeLeftDate.clone(),
            dateRangeEnd: chartStore.rangeRightDate.clone(),
            riskId: _.first(risksStore.currentList),
            selectedCountries: countryStore.currentCountryList,
            virtualToday: chartStore.virtualToday.clone(),
            activeGroupId: settingsStore.activeGroupId,
            annoMode: insightsStore.annotationMode,
            clientWidth: chartStore.clientWidth,
            contentWidth: chartStore.width,
            isEventsFeedMinimized: eventsFeedStore.isMinimized,
            isRiskPickerMinimized: risksStore.isMinimized,
        };
    }
)
@observer
export default class GQMapProvider extends React.Component<
    IGQMapProviderProps,
    IGQMapProviderState
> {
    private mounted = false;
    private force: d3.Simulation<IMapNode, undefined> = null;
    private restartCollide: boolean = false;
    private onReloadCountryNotification: EventSubscription = null;
    private onStopForceSub: EventSubscription = null;
    private fetchScoresDebounced: () => Promise<void>;

    constructor(props: IGQMapProviderProps) {
        super(props);
        this.state = {
            nodes: [],
            geoPath: geoPathFn(features as any),
            showScores: false,
            showHalos: false,
        };
        this.fetchScoresDebounced = _.debounce(this.fetchScores, 500);
    }

    public componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.fetchScoresDebounced);
        if (this.onReloadCountryNotification) {
            this.onReloadCountryNotification.remove();
        }
        this.onStopForceSub.remove();
    }
    public componentDidMount() {
        if (this.props.willMount) {
            this.props.willMount();
        }
        this.onStopForceSub = EventService.addListener('MAP_STOP_FORCE', () => {
            if (this.force) {
                this.force.stop();
            }
        });

        this.mounted = true;
        this.fetchScores();
        this.onReloadCountryNotification = EventService.registerOnReloadCountry(
            () => this.fetchScores()
        );
        window.addEventListener('resize', this.fetchScoresDebounced);
    }

    public shouldComponentUpdate(nextProps: IGQMapProviderProps) {
        if (
            nextProps.selectedCountries[0] !== this.props.selectedCountries[0]
        ) {
            return true;
        }
        return !rootStore.appStore.freezeRender;
    }

    public componentDidUpdate(
        prevProps: IGQMapProviderProps,
        prevState: IGQMapProviderState
    ) {
        if (
            this.shouldFetchRiskScores(prevProps, prevState) ||
            this.shouldFetchContingent(prevProps, prevState)
        ) {
            this.fetchScoresDebounced();
        }
    }

    private shouldFetchContingent(
        prevProps: IGQMapProviderProps,
        prevState: IGQMapProviderState
    ) {
        if (!includes(this.props.view, CONTINGENT_SCORES_VIEWS)) {
            return false;
        }

        return (
            !includes(prevProps.view, CONTINGENT_SCORES_VIEWS)  ||
            this.props.dateRangeStart.format('yyyymmdd') !==
                prevProps.dateRangeStart.format('yyyymmdd') ||
            this.props.dateRangeEnd.format('yyyymmdd') !==
                prevProps.dateRangeEnd.format('yyyymmdd') ||
            this.props.virtualToday.format('yyyymmdd') !==
                prevProps.virtualToday.format('yyyymmdd') ||
            this.props.selectedCountries[0] !==
                prevProps.selectedCountries[0] ||
            this.props.riskId !== prevProps.riskId
        );
    }

    public render() {
        return (
            <GQMap
                geoPath={this.state.geoPath}
                nodes={this.state.nodes}
                showScores={this.state.showScores}
                showHalos={this.state.showHalos}
                view={this.props.view}
                selectedCountries={this.props.selectedCountries}
                onCircleClick={this.handleCircleClick}
                onCircleHover={this.toggleHover}
                colorToSelectedIdentifier={this.getColorForCountry}
                className={this.props.annoMode ? 'anno-mode' : ''}
                customComponents={this.resolveCustomComponents()}
                isSmallScreen={this.isSmallScreen()}
                contentWidth={this.props.contentWidth}
            />
        );
    }

    private resolveCustomComponents(): ICustomMapComponent[] {
        if (this.props.annotations) {
            const notes = _.map(this.props.annotations, (note, i) => {
                const data: IGQMapNoteAnchorData = {
                    index: i + 1,
                };
                const circle: ICustomMapComponent = {
                    Component: GQMapNoteAnchor,
                    countryID: note.countryID,
                    data,
                };
                return circle;
            });
            return notes;
        }
    }

    private getColorForCountry = (id: number) => {
        const color = rootStore.countryStore.colorStack.color(id);
        return color;
    };

    private handleCircleClick = (
        id: number,
        e: React.MouseEvent<SVGElement>
    ) => {
        const { annoMode, view } = this.props;

        if (annoMode) {
            rootStore.annoStore.selectedMapCountry = id;
        } else if (view === VIEW.CONTINGENT) {
            rootStore.countryStore.toggleSecondaryCountry(id);
        } else {
            this.toggleCountry(id);
        }
    };
    private toggleCountry = (id: number) => {
        rootStore.countryStore.toggleCountry(id);
    };

    private toggleHover = async (
        id: number,
        e: React.MouseEvent<SVGGElement>
    ) => {};

    private fetchScores = async () => {
        loading.start();

        const params: IGetScoreDeltaQueryParams = {
            identifier: this.props.riskId,
            isClientFacingIndicator: this.props.isClientFacingIndicator,
            virtualToday: this.props.virtualToday,
            activeGroupId: this.props.activeGroupId,
            dateStart: this.props.dateRangeStart,
            dateEnd: this.props.dateRangeEnd,
        };
        const groups = await APIService.getActiveGroups();
        let scoreDeltas: IScoresDelta;
        if (includes(this.props.view, CONTINGENT_SCORES_VIEWS)) {
            scoreDeltas = await APIService.getContingentScoreDelta(
                params,
                first(this.props.selectedCountries)
            );
        } else {
            scoreDeltas = await APIService.getScoreDelta(
                params,
                !CustomWeightStore.hasActiveCustomWeight
            );
        }
        const activeGroup = flow(
            get('groups'),
            filter(
                (group: IActiveGroup) =>
                    (isNil(group.id) && isNil(this.props.activeGroupId)) ||
                    group.id === this.props.activeGroupId
            ),
            first
        )(groups);

        if (this.props.overrideCountriesList) {
            scoreDeltas.scores = scoreDeltas.scores.filter(c =>
                this.props.overrideCountriesList.has(c.geo.id)
            );
        }
        if (this.mounted) {
            const nodes = this.convertNodes(scoreDeltas, activeGroup);
            const geoData = await APIService.getActiveCountries();
            const focal: IActiveCountry =
                geoData.countries[first(this.props.selectedCountries)];
            if (this.props.view === VIEW.CONTINGENT) {
                nodes.push({
                    riskValue: 1,
                    radius: 19,
                    posX: projection
                        ? +projection([focal.longitude, focal.latitude])[0]
                        : focal.longitude,
                    posY: projection
                        ? +projection([focal.longitude, focal.latitude])[1]
                        : focal.latitude,
                    x: projection
                        ? +projection([focal.longitude, focal.latitude])[0]
                        : focal.longitude,
                    y: projection
                        ? +projection([focal.longitude, focal.latitude])[1]
                        : focal.latitude,
                    name: focal.name,
                    original_name: focal.name,
                    id: focal.id,
                    abbreviation: focal.abbreviation,
                    abbreviation_short: focal.abbreviation_short,
                });
            }
            this.applyForce(nodes);
        }
        loading.stop();
    };

    private calcContingentRiskRadius(
        score: number,
        avg: number,
        min: number,
        max: number
    ): number {
        const maxDist = score > avg ? max - avg : avg - min;
        const dist = score - avg;
        const minRadius = 25;
        return Math.max(minRadius, 30 + 20 * (dist / maxDist));
    }

    private convertNodes(scoreDeltas: IScoresDelta, activeGroup: IActiveGroup) {
        const nodes: IMapNode[] = [];
        let scoreSizeMax = -Infinity;
        let scoreSizeMin = Infinity;

        let positiveDeltaMax = -Infinity;
        let positiveDeltaMin = Infinity;
        let negativeDeltaMax = -Infinity;
        let negativeDeltaMin = Infinity;
        for (const d of scoreDeltas.scores) {
            if (d.score_size > scoreSizeMax) {
                scoreSizeMax = d.score_size;
            }
            if (d.score_size < scoreSizeMin) {
                scoreSizeMin = d.score_size;
            }
            if (d.delta_color.type === 'up' && d.delta > positiveDeltaMax) {
                positiveDeltaMax = d.delta;
            }
            if (d.delta_color.type === 'up' && d.delta < positiveDeltaMin) {
                positiveDeltaMin = d.delta;
            }
            if (d.delta_color.type === 'down' && d.delta > negativeDeltaMax) {
                negativeDeltaMax = d.delta;
            }
            if (d.delta_color.type === 'down' && d.delta < negativeDeltaMin) {
                negativeDeltaMin = d.delta;
            }
        }

        const dayValues: number[] = [];
        const daysPassed = this.props.virtualToday.diff(C_FIRST_DAY_HUMAN_SCORES_DATE, 'days');
        _.forEach(
            scoreDeltas.scoresOriginal,
            (riskValues, countryId) => {
                const curValue = Math.abs(riskValues[daysPassed - 1]);
                dayValues.push(curValue);
            }
        );
        const avg = dayValues.reduce((p, c) => p + c, 0) / dayValues.length;
        const min = Math.min(...dayValues);
        const max = Math.max(...dayValues);
        for (const d of scoreDeltas.scores) {
            const activeGroupCountriesHasDelta = flow(
                get('countries'),
                includes(d.geo.id)
            )(activeGroup);

            const deltaMax =
                d.delta_color.type === 'up'
                    ? positiveDeltaMax
                    : negativeDeltaMax;
            const deltaMin =
                d.delta_color.type === 'up'
                    ? positiveDeltaMin
                    : negativeDeltaMin;
            let radius;
            if (this.props.view === VIEW.CONTINGENT) {
                // the risk value for the certain day.
                // Using the initial day of 01.07.2016 to get the appropriate
                // value from the array of all days.
                const riskValue: number =
                    scoreDeltas.scoresOriginal[d.geo.id][daysPassed - 1];
                radius = this.calcContingentRiskRadius(riskValue, avg, min, max);
                radius = .5 * radius;
            } else {
                radius = Math.max(d.delta_color.percent, 0.2);
            }

            if (!activeGroup || activeGroupCountriesHasDelta) {
                nodes.push({
                    delta: d.delta,
                    delta_type: d.delta_color.type,
                    class:
                        this.props.view === VIEW.RISK_VIEW
                            ? 'hide'
                            : d.delta_color.type === 'up'
                            ? 'negative-circle'
                            : d.delta_color.type === 'down'
                            ? 'positive-circle'
                            : 'hide',
                    id: d.geo.id,
                    name: d.geo.name,
                    opacity: this.calcOpacity(d.delta, deltaMax, deltaMin),
                    scoreRadius: Math.max(d.delta_color.percent, 0.2),
                    posX: projection
                        ? +projection([d.geo.longitude, d.geo.latitude])[0]
                        : d.geo.longitude,
                    posY: projection
                        ? +projection([d.geo.longitude, d.geo.latitude])[1]
                        : d.geo.latitude,
                    radius:
                        this.props.view === VIEW.CONTINGENT
                            ? radius
                            : this.calcRadius(
                                  d.score_size,
                                  scoreSizeMax,
                                  scoreSizeMin
                              ),
                    x: projection
                        ? +projection([d.geo.longitude, d.geo.latitude])[0]
                        : d.geo.longitude,
                    y: projection
                        ? +projection([d.geo.longitude, d.geo.latitude])[1]
                        : d.geo.latitude,
                    score: d.score_today,
                    abbreviation_short: d.geo.abbreviation_short,
                    abbreviation: d.geo.abbreviation,
                });

                nodes[nodes.length - 1].name =
                    nodes[nodes.length - 1].name.length * 3 >
                    nodes[nodes.length - 1].radius - 5
                        ? d.geo.abbreviation_short
                        : nodes[nodes.length - 1].name;
                if (nodes[nodes.length - 1].name === 'GB') {
                    nodes[nodes.length - 1].name = 'UK';
                }
            }
        }
        switch (this.props.view) {
            case VIEW.SORT_BY_RISK:
            case VIEW.SORT_BY_CONTINGENT_RISK:
                const linearNodes = _.sortBy(nodes, d => -d.score);
                const maxRadi = _.maxBy(linearNodes, d => d.radius);
                let xBuffer = 5;
                let yBuffer = 0;
                for (const node of linearNodes) {
                    if (node.radius * 2 + xBuffer > 1000) {
                        yBuffer += maxRadi.radius * 2;
                        xBuffer = 5 + (maxRadi.radius - node.radius);
                    }
                    node.posX = xBuffer + node.radius;
                    node.posY = yBuffer + 50;
                    xBuffer += (node.radius + 2) * 2;
                }
                return linearNodes;
            case VIEW.SORT_BY_DELTA:
            case VIEW.SORT_BY_CONTINGENT_DELTA:
                const width = 65;
                const height = 90;
                const negativeNodes = _.sortBy(
                    _.filter(nodes, node => node.class === 'negative-circle'),
                    node => -node.delta
                );
                const positiveNodes = _.sortBy(
                    _.filter(nodes, node => node.class === 'positive-circle'),
                    node => -node.delta
                );
                const deltaNodes = [...negativeNodes, ...positiveNodes];
                const paddingRight = 16;
                let deltaX = 0;
                let deltaY = 5;
                for (const node of deltaNodes) {
                    if (
                        deltaX + width >
                        this.props.contentWidth - paddingRight
                    ) {
                        deltaY += height;
                        deltaX = 0;
                    }
                    node.posX = deltaX;
                    node.posY = deltaY;
                    deltaX += width;
                }
                return deltaNodes;
        }
        return nodes;
    }

    private isSmallScreen() {
        return this.props.clientWidth <= 1500;
    }
    private calcRadius(score: number, max: number, min: number): number {
        switch (this.props.view) {
            case VIEW.CONTINGENT:
            case VIEW.RISK_VIEW: {
                return this.isSmallScreen() ? 22 : 18;
            }
            case VIEW.SORT_BY_RISK: 
            case VIEW.SORT_BY_CONTINGENT_RISK: {
                const multiplier = 20;
                return ((score - min) / (max - min)) * multiplier + 19;
            }
            default: {
                const multiplier = 35;
                return ((score - min) / (max - min)) * multiplier + 19;
            }
        }
    }

    private calcOpacity(
        delta: number,
        deltaMax: number,
        deltaMin: number
    ): number {
        let opacity = (delta - deltaMin) / (deltaMax - deltaMin);
        if (delta < 0) {
            opacity = 1 - opacity;
        }
        return opacity;
    }

    private applyForce(nodes: IMapNode[]) {
        const forceParams = {
            collide: this.isGeo ? (this.restartCollide ? 0 : 0.5) : 0,
            XY: this.isGeo ? 0.1 : 0.15,
            alpha: this.restartCollide ? 0.5 : 1,
            alphaMin: 0.3,
        };
        if (this.force) {
            _.forEach(nodes, item => {
                const forceNode = _.first(
                    _.filter(
                        this.force.nodes(),
                        forceItem => forceItem.name === item.name
                    )
                );
                if (forceNode && forceNode.x && forceNode.y) {
                    delete item.x;
                    delete item.y;
                    Object.assign(item, {
                        x: forceNode.x,
                        y: forceNode.y,
                    });
                }
            });
            this.force.nodes(nodes);
            this.force.alpha(forceParams.alpha);
        } else {
            const width = this.isDelta ? this.props.contentWidth : 960;
            const height = 600;
            this.force = d3.forceSimulation(nodes);
            this.force
                .alphaMin(forceParams.alphaMin)
                .on('tick', () => {
                    if (this.isGeo) {
                        _.forEach(this.force.nodes(), node => {
                            node.x = Math.max(
                                node.radius,
                                Math.min(width - node.radius, node.x)
                            );
                            node.y = Math.max(
                                node.radius,
                                Math.min(height - node.radius, node.y)
                            );
                        });
                    }
                    if (!rootStore.eventsFeedStore.onHold) {
                        rootStore.eventsFeedStore.setState({
                            onHold: true,
                        });
                    }
                    if (
                        this.mounted &&
                        this.props.view !== VIEW.EXPORT_COMPONENT
                    ) {
                        this.force.stop();
                        this.setState(
                            {
                                showScores: this.isDelta,
                                showHalos: false,
                                nodes: this.force.nodes().slice(),
                            },
                            () => {
                                this.force.restart();
                            }
                        );
                    }
                })
                .on('end', () => {
                    if (this.restartCollide) {
                        this.restartCollide = false;
                        this.force.alpha(1);
                        this.force.alphaMin(0.2);
                        this.force.force(
                            'collide',
                            d3
                                .forceCollide()
                                //@ts-ignore
                                .radius(this.collisionRadiFn)
                                .strength(0.2)
                                .iterations(6)
                        );
                        this.force.restart();
                        return;
                    }
                    if (this.mounted) {
                        this.setState(
                            {
                                showScores: true,
                                showHalos: true,
                                nodes: this.force.nodes().slice(),
                            },
                            () => {
                                stopHold(1000);
                            }
                        );
                    } else {
                        stopHold();
                    }
                });
        }
        this.force
            .force(
                'x',
                d3.forceX((d: IMapNode) => d.posX).strength(forceParams.XY)
            )
            .force(
                'y',
                d3.forceY((d: IMapNode) => d.posY).strength(forceParams.XY)
            )
            .force(
                'collide',
                d3
                    .forceCollide()
                    //@ts-ignore
                    .radius(this.collisionRadiFn)
                    .strength(forceParams.collide)
                    .iterations(6)
            );
        this.force.restart();
    }

    private collisionRadiFn = (d: IMapNode) => {
        const alpha = this.force.alpha();
        const alphaMin = this.force.alphaMin();

        // workaround, since initially the opacity parameter was required to prevent nodes from colliding
        const opacity = this.props.view === VIEW.CONTINGENT ? 0.5 : d.opacity;
        const scoreCollision = Math.max(opacity * 10, 2);

        if (alpha > alphaMin) {
            const newRadi = Math.floor(
                d.radius * (this.force.alphaMin() / this.force.alpha())
            );
            return (Math.max(d.radius, newRadi) + scoreCollision) * 1.1;
        }

        return (d.radius + scoreCollision) * 1.1;
    };

    private shouldFetchRiskScores(
        prevProps: IGQMapProviderProps,
        prevState: IGQMapProviderState
    ) {
        if (!includes(this.props.view, RISK_SCORES_VIEWS)) {
            return false;
        }
        if (this.props.identifier !== prevProps.identifier) {
            return true;
        }
        if (
            this.props.isClientFacingIndicator !==
            prevProps.isClientFacingIndicator
        ) {
            return true;
        }
        if (
            this.props.virtualToday.format('yyyymmdd') !==
            prevProps.virtualToday.format('yyyymmdd')
        ) {
            return true;
        }
        if (
            this.props.dateRangeStart.format('yyyymmdd') !==
                prevProps.dateRangeStart.format('yyyymmdd') ||
            this.props.dateRangeEnd.format('yyyymmdd') !==
                prevProps.dateRangeEnd.format('yyyymmdd')
        ) {
            return true;
        }
        if (this.props.view !== prevProps.view) {
            this.restartCollide = this.props.view === VIEW.RISK_VIEW;
            return true;
        }
        if (this.props.riskId !== prevProps.riskId) {
            return true;
        }
        if (this.props.activeGroupId !== prevProps.activeGroupId) {
            return true;
        }
        if (
            this.props.overrideCountriesList !== prevProps.overrideCountriesList
        ) {
            return true;
        }
        if (
            this.props.isEventsFeedMinimized !==
                prevProps.isEventsFeedMinimized ||
            this.props.isRiskPickerMinimized !== prevProps.isRiskPickerMinimized
        ) {
            return true;
        }

        return false;
    }

    private get isGeo() {
        return (
            this.props.view === VIEW.RISK_VIEW ||
            this.props.view === VIEW.CONTINGENT
        );
    }

    private get isDelta() {
        return (
            this.props.view === VIEW.SORT_BY_DELTA ||
            this.props.view === VIEW.SORT_BY_CONTINGENT_DELTA
        );
    }
}
