import { useState, useEffect } from 'react';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const isMobile = /mobile/i.test(navigator.userAgent);
        setIsMobile(isMobile);
    }, []);

    return isMobile;
};

export default useIsMobile;
