import rootStore from '../../RootStore';
import { useHistory } from 'react-router-dom';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import GQTabs from 'components/GQTabs/GQTabs';
import {
    keyBy,
    filter,
    flow,
    get,
    isEmpty,
    map,
    toLower,
    toNumber,
    first,
    isNil,
    orderBy,
} from 'lodash/fp';
import { mapWithKeys } from 'utils/lodashUtils';
import {
    Cell,
    CELL_TEXT_SHADOW,
    getCompositeRiskBgColor,
    getCompositeRiskColor,
    getCompositeRiskFontWeight,
    getCompositeRiskText,
    getCountryFlagImage,
    getHighLowCellColor,
    getHighLowCellText,
    getHighLowFontWeight,
    getPulseBgColor,
    getPulseColor,
    getPulseFontWeight,
    getPulseText,
    getRiskBanCellBg,
    getRiskBandCellFontWeight,
    getRiskBankCellText,
    Row,
} from 'components/GQTemplate/SignalsTemplate/SignalsTemplateComponents';
import { VIEW } from 'interfaces';
import APIService, { E_RISK_TYPE } from 'services/APIService';
import moment from 'moment';
import GQLoader from 'components/GQLoader/GQLoader';
import {
    E_TIME_PRESET_KEYS,
    HEATMAP_PRESETS,
    TIME_WINDOW_PRESETS,
} from '../../constants';
import GQDatePicker from 'components/GQNavbar/GQDatePicker';

export const COLORS = {
    RED: 'rgb(253, 61, 60)',
    GREEN: 'rgb(23, 255, 150)',
    RED_BG: 'rgb(152, 44, 52)',
    GREEN_BG: 'rgb(14, 161, 106)',
    DEFAULT_BG: 'rgb(0, 19, 40)',
    DEFAULT_COLOR: 'white',
    BORDER: '#999999',
};

const ARROW_UP = '↑';
const ARROW_DOWN = '↓';
enum SIGNALS_FREQUENCY {
    DAILY,
    WEEKLY,
    MONTHLY,
};


const SIGNALS_PERIOD_TABS = [
    {
        label: 'Daily',
        id: SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.DAILY],
    },
    {
        label: 'Weekly',
        id: SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.WEEKLY],
    },
    {
        label: 'Monthly',
        id: SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.MONTHLY],
    },
];

const DAILY_COLS = [
    { text: 'Country', flex: 1.2 },
    { text: 'Risk', flex: .7 },
    { text: 'Pulse', flex: 1 },
    { text: 'Comp.', flex: 1 },
    { text: 'H/L', flex: 1.5 },
    { text: 'Risk Band', flex: 1.5 },
];

// const DATE_PICKER_START_DATE = new Date(2013, 1, 1);
const DATE_PICKER_MAX_DATE = new Date();

const getDateCell = (row: any): Cell => {
    return {
        text: row.date,
    };
};
const getCountryCell = (row: any): Cell => {
    return {
        text: row.country_code || row.country_abbreviation,
        // todo: use const shared with  SignalsTemplateComponents.tsx
        image: getCountryFlagImage(row.country_code),
        countryId: row.country_id,
    };
};
const getRiskCell = (
    row: any,
): Cell => {
    return {
        text: row.risk_abbreviation,
        riskId: row.risk_id,
    };
};

const getRiskBandCell = (row: any): Cell => {
    return {
        text: getRiskBankCellText(row),
        color: COLORS.DEFAULT_COLOR,
        bg: getRiskBanCellBg(row),
        fontWeight: getRiskBandCellFontWeight(row),
    };
};

const getHighLowCell = (row: any): Cell => {
    return {
        text: getHighLowCellText(row),
        color: getHighLowCellColor(row),
        fontWeight: getHighLowFontWeight(row),
        bg: COLORS.DEFAULT_BG,
        align: 'center',
    };
};
const getCompositeRiskCell = (row: any): Cell => {
    return {
        text: getCompositeRiskText(row),
        color: getCompositeRiskColor(row),
        bg: getCompositeRiskBgColor(row),
        fontWeight: getCompositeRiskFontWeight(row),
        textShadow: `1px 1px ${COLORS.DEFAULT_BG}`,
        align: 'center',
    };
};

const getPulseCell = (row: any): Cell => {
    return {
        text: getPulseText(row),
        color: getPulseColor(row),
        bg: getPulseBgColor(row),
        fontWeight: getPulseFontWeight(row),
        textShadow: CELL_TEXT_SHADOW,
        align: 'center',
    };
};

const SignalsDaily = ({ data }) => {
    const rows = useMemo(
        () =>
            map(
                (row: any) => [
                    getCountryCell(row),
                    getRiskCell(row),
                    getPulseCell(row),
                    getCompositeRiskCell(row),
                    getHighLowCell(row),
                    getRiskBandCell(row),
                ],
                data
            ),
        [data]
    );

    return <Table title="Daily Signals" columns={DAILY_COLS} rows={rows} />;
};

const MONTHLY_COLS = [
    { text: 'Country', flex: 1.2 },
    { text: 'Risk', flex: 1 },
    { text: 'Date', flex: 1 },
    { text: 'Pulse', flex: 1 },
    { text: 'Comp.', flex: 1 },
    { text: 'H/L', flex: 1.5 },
    { text: 'Risk Band', flex: 1.5 },
];
const SignalsMonthly = ({ data }) => {
    const rows = useMemo(
        () =>
            map(
                (row: any) => [
                    getCountryCell(row),
                    getRiskCell(row),
                    getDateCell(row),
                    getPulseCell(row),
                    getCompositeRiskCell(row),
                    getHighLowCell(row),
                    getRiskBandCell(row),
                ],
                data
            ),
        [data]
    );

    return <Table title="Monthly Signals" columns={MONTHLY_COLS} rows={rows} />;
};

const WEEKLY_COLS = [
    { text: 'Country', flex: 16 },
    { text: 'Risk', flex: 18 },
    { text: 'Date', flex: 11 },
    { text: 'Pulse', flex: 12 },
    { text: 'Comp.', flex: 15 },
    { text: 'H/L', flex: 13 },
    { text: 'Risk Band', flex: 15 },
];
const SignalsWeekly = ({ data }) => {
    const rows = useMemo(
        () =>
            map(
                (row: any) => [
                    getCountryCell(row),
                    getRiskCell(row),
                    getDateCell(row),
                    getPulseCell(row),
                    getCompositeRiskCell(row),
                    getHighLowCell(row),
                    getRiskBandCell(row),
                ],
                data
            ),
        [data]
    );

    return <Table title="Weekly Signals" columns={WEEKLY_COLS} rows={rows} />;
};

type Column = {
    text: string;
    flex?: number;
};

type RowProps = {
    row: Row;
    push: (url: string) => void;
    flexColumns: any; //todo: fix any
};

type TableProps = {
    title: ReactNode;
    columns: Column[];
    rows: Row[];
};
const SignalRow = React.memo(({ row, flexColumns, push }: RowProps) => {
    const onClick = useCallback(async () => {
        let countryId = get([0, 'countryId'], row);
        if (!countryId) {
            console.error('cannot find country');
            return;
        }
        let riskId = get([1, 'riskId'], row);
        if (!riskId) {
            console.error('cannot find risk');
            return;
        }
        rootStore.countryStore.changeFirstCountry(countryId);
        rootStore.risksStore.setCurrentRiskType(E_RISK_TYPE.DIMENSIONS);
        rootStore.risksStore.currentStore.overrideValues(riskId);
        const windowCell = first(row.slice(-2, -1));
        const windowText = get('text', windowCell) || '';
        const match = windowText.match(/(\d{1,3})d/i);
        if (match) {
            const windowNumber = toNumber(match[1]);
            const startDate = moment()
                .subtract(windowNumber, 'days')
                .toDate();
            rootStore.chartStore.setNewRangeByDates(startDate, new Date());
        }

        push(`/${VIEW.WORLD_GRAPH}`);
    }, [row, push]);

    return (
        <div className="signal-row" onClick={onClick}>
            {mapWithKeys((cell: Cell, cellIndex: number) => {
                const flexData = flexColumns[cellIndex];
                const flex = flexData ? flexData.flex : 1;
                return (
                    <div
                        key={cellIndex}
                        className="signal-row-cell"
                        style={{
                            flex,
                            color: cell.color || COLORS.DEFAULT_COLOR,
                            fontSize: cell.fontSize || '12px',
                            backgroundColor: cell.bg || 'inherit',
                            textAlign: cell.align || 'inherit',
                            //justifyContent: cell.align || 'flex-start', //todo: merge with css styles
                            textShadow: cell.textShadow || 'inherit',
                            fontWeight: cell.fontWeight || 'inherit',
                        }}>
                        {cell.image && (
                            <img src={cell.image} style={{ marginRight: 4 }} />
                        )}
                        {cell.text || ''}
                    </div>
                );
            }, row)}
        </div>
    );
});

enum SORT_ORDER {
    ASC = 'asc',
    DESC = 'desc',
}
//todo: use
enum SORT_TYPE {
    STRING = 'string',
    // NUMBER = 'number', todo: check if needed
    DATE = 'date',
}

const Table = React.memo(({ title, columns, rows }: TableProps) => {
    const [sortOrder, setSortOrder] = useState<SORT_ORDER>(SORT_ORDER.ASC);
    //@ts-ignore
    const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.STRING);
    const [sortColumnIndex, setSortColumn] = useState<number>(null);

    const sortedRows = useMemo(() => {
        if (sortOrder === SORT_ORDER.ASC && isNil(sortColumnIndex)) {
            return rows;
        }

        const sortedOrder = sortOrder === SORT_ORDER.ASC ? 'asc' : 'desc';
        let sortedRows;
        switch (sortType) {
            //todo: fix
            /*case SORT_TYPE.DATE:
                sortedRows = orderBy(
                    item => new Date(item[sortColumnIndex].date),
                    sortedOrder,
                    rows
                );
                break;
                */
            case SORT_TYPE.STRING:
            default: {
                sortedRows = orderBy(
                    item => item[sortColumnIndex].text,
                    sortedOrder,
                    rows
                );
            }
        }
        return sortedRows;
    }, [sortOrder, sortColumnIndex, sortType, rows]);

    const { push } = useHistory();
    const flexColumns: any = useMemo(
        () =>
            flow(
                mapWithKeys((column: Column, columnIndex) =>
                    column.flex
                        ? { flex: column.flex, index: columnIndex }
                        : null
                ),
                filter(Boolean),
                keyBy('index')
            )(columns),
        [columns]
    );

    if (isEmpty(rows)) {
        return null;
    }

    return (
        <div>
            <div className="signals-title">{title}</div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '.5rem',
                }}>
                {mapWithKeys(
                    (column: Column, columnIndex: number) => {
                        return <div
                            key={columnIndex}
                            className="signal-column"
                            style={{
                                flex: column.flex || 1,
                                whiteSpace: 'nowrap',
                            }}
                            onClick={() => {
                                if (sortColumnIndex === columnIndex) {
                                    setSortOrder(curr =>
                                        curr === SORT_ORDER.ASC
                                            ? SORT_ORDER.DESC
                                            : SORT_ORDER.ASC
                                    );
                                    return;
                                }
                                setSortType(
                                    toLower(column.text) === 'date'
                                        ? SORT_TYPE.DATE
                                        : SORT_TYPE.STRING
                                );
                                setSortColumn(columnIndex);
                            }}>
                            {columnIndex === sortColumnIndex &&
                                sortOrder === SORT_ORDER.ASC &&
                                ARROW_UP}
                            {columnIndex === sortColumnIndex &&
                                sortOrder === SORT_ORDER.DESC &&
                                ARROW_DOWN}
                            {columnIndex === sortColumnIndex && (
                                <div style={{ width: 4 }} />
                            )}
                            {column.text}
                        </div>
                    },
                    columns
                )}
            </div>
            <div>
                {mapWithKeys(
                    (row: Row, rowIndex: number) => (
                        <SignalRow
                            key={rowIndex}
                            row={row}
                            push={push}
                            flexColumns={flexColumns}
                        />
                    ),
                    sortedRows
                )}
            </div>
        </div>
    );
});

const Signals = React.memo(() => {
    const [frequency, setFrequency] = useState<string>(
        SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.DAILY]
    );
    const [signalsData, setSignalsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [signalsDate, setSignalsDate] = useState<Date>(
        moment()
            .subtract(1, 'day')
            .toDate()
    );
    const [timeWindow, setTimeWindow] = useState<E_TIME_PRESET_KEYS>(
        E_TIME_PRESET_KEYS.THREE_MONTHS
    );
    const [error, setError] = useState(null);
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                setSignalsData(null);
                const data = await APIService.getSignals(
                    moment(signalsDate),
                    toLower(frequency),
                    TIME_WINDOW_PRESETS[timeWindow].val
                );
                setSignalsData(data?.signals);
                setIsLoading(false);
                setError(null);
            } catch (e) {
                setIsLoading(false);
                setError(error);
            }
        })();
    }, [frequency, signalsDate, timeWindow]);
    const onChangeDate = useCallback((date: Date)=>{
        setSignalsDate(date)
    },[])
    const filterDate = useCallback(
        (date: Date) => {
            if (frequency === SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.DAILY]) {
                return true;
            }

            const momentDate = moment(date);
            if (frequency === SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.WEEKLY]) {
                return momentDate.day() === 1;
            }

            return momentDate.date() === 1;
        },
        [frequency]
    );
    const onChangeTimeSpan = useCallback((timeSpan: E_TIME_PRESET_KEYS) => {
        setTimeWindow(timeSpan);
    }, []);
    const onSignalTabChange = useCallback(newTab => {
        switch (newTab) {
            case SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.DAILY]: {
                break;
            }
            case SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.WEEKLY]: {
                setSignalsDate(
                    moment()
                        .startOf('isoWeek')
                        .toDate()
                );
                break;
            }
            case SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.MONTHLY]: {
                let newMonthDate =
                    moment().date() === 1
                        ? moment()
                              .subtract(1, 'month')
                              .date(1)
                              .toDate()
                        : moment()
                              .date(1)
                              .toDate();
                setSignalsDate(newMonthDate);
                break;
            }
        }
        setFrequency(newTab);
    }, []);

    return (
        <div style={{ width: '100%', overflow: 'auto' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                <GQTabs
                    tabs={SIGNALS_PERIOD_TABS}
                    onChange={onSignalTabChange}
                    selectedTab={frequency}
                />
                <div style={{ flex: 1, position: 'relative' }}>
                    <GQDatePicker
                        onChangeStartDate={onChangeDate}
                        onChangeTimeSpan={onChangeTimeSpan}
                        startDate={signalsDate}
                        maxDate={DATE_PICKER_MAX_DATE}
                        timespanPresets={HEATMAP_PRESETS}
                        timespan={timeWindow}
                        filterDate={filterDate}
                        popperPlacement="top-end"
                        dropdownDateFormat="MMM D"
                    />
                </div>
            </div>
            <div>
                {error && (
                    <div className="signals-message error">
                        Error fetching insights
                    </div>
                )}
                {!error && isLoading && (
                    <div
                        style={{
                            width: '100%',
                            height: '10vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <GQLoader />
                    </div>
                )}
                {!error && !isLoading && isEmpty(signalsData) && (
                    <div className="signals-message">
                        No insights found for the given date, frequency and time
                        window
                    </div>
                )}
                {!error && !isLoading && !isEmpty(signalsData) && (
                    <div>
                        {frequency ===
                            SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.DAILY] && (
                            <SignalsDaily data={signalsData} />
                        )}
                        {frequency ===
                            SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.WEEKLY] && (
                            <SignalsWeekly data={signalsData} />
                        )}
                        {frequency ===
                            SIGNALS_FREQUENCY[SIGNALS_FREQUENCY.MONTHLY] && (
                            <SignalsMonthly data={signalsData} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
});
export default Signals;
