import html2canvas from 'html2canvas';

import { getHeatmapPercentile } from 'components/Heatmap/HeatmapProvider';
import mailchimpConfig from 'configs/mailchimp';
import { flow, find, get, isEmpty, isNil, includes, toLower } from 'lodash/fp';
import moment from 'moment';
import React, {
    CSSProperties,
    ReactNode,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { mapWithKeys } from 'utils/lodashUtils';
import { getUserFacingUrl } from 'services/configService';

export type Cell = {
    image?: string;
    text: string;
    color?: string;
    bg?: string;
    singleLine?: boolean;
    fontWeight?: CSSProperties['fontWeight'];
    fontSize?: CSSProperties['fontSize'];
    align?: CSSProperties['textAlign'];
    padding?: CSSProperties['padding'];
    textShadow?: CSSProperties['textShadow'];
    countryId?: number;
    riskId?: number;
};

export type Column = {
    text: ReactNode;
    width?: CSSProperties['width'];
    padding?: CSSProperties['padding'];
    fontSize?: CSSProperties['fontSize'];
};

export type Row = Cell[];

type Props = {
    title?: ReactNode;
    columns: Column[];
    rows: Row[];
};

const DAYS_TO_TIME_WINDOW = {
    30: '1 month',
    90: '3 month',
    180: '6 month',
    365: '1 year',
    730: '2 year',
    1095: '3 year',
};

export const S3_BASE_URL =
    'https://gq-static-content-production.s3.amazonaws.com';

const TOP_PERCENTILES = [99, 95, 90];
const BOTTOM_PERCENTILES = [-99, -95, -90];
export const HIGHLIGHT_PERCENTILES = [...TOP_PERCENTILES, ...BOTTOM_PERCENTILES];
const ARROW_UP = '↑';
const ARROW_DOWN = '↓';

export enum IFRAME_DATA_TYPES {
    SIGNALS = "SIGNALS",
    SCREENSHOT = "SCREENSHOT",
  }

export const COLORS = {
    RED: 'rgb(253, 61, 60)',
    GREEN: 'rgb(23, 255, 150)',
    RED_BG: 'rgb(152, 44, 52)',
    GREEN_BG: 'rgb(14, 161, 106)',
    DEFAULT_BG: 'rgb(0, 19, 40)',
    DEFAULT_COLOR: 'white',
    BORDER: '#999999',
};

const parseRGBA = rgba => {
    let [r, g, b, a] = rgba
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',');

    [r, g, b, a] = [
        parseInt(r, 10),
        parseInt(g, 10),
        parseInt(b, 10),
        parseFloat(a),
    ];

    return [r, g, b, a];
};
const toRgb = rgba => {
    let [r, g, b, a] = parseRGBA(rgba);
    const [redBG, greenBG, blueBG] = parseRGBA(COLORS.DEFAULT_BG);
    const oppositeAlpha = 1 - a;

    r = r * a + redBG * oppositeAlpha;
    g = g * a + greenBG * oppositeAlpha;
    b = b * a + blueBG * oppositeAlpha;

    return `rgb(${r}, ${g}, ${b})`;
};

export const getBgColorFromPercentile = (percentile: number) => {
    const heatmapPercentile = getHeatmapPercentile(percentile);
    return toRgb(heatmapPercentile.color);
};

export const getCountryFlagImage = (countryCode: string) => {
    return `${S3_BASE_URL}/flags/tiny/${toLower(countryCode)}.png`;
};

export const getCountryCell = (row: any): Cell => {
    return {
        text: row.country_abbreviation || row.country_code,
        image: getCountryFlagImage(row.country_code),
        align: 'left',
        padding: '3px 2px',
    };
};

export const getDateCell = (row: any): Cell => {
    return {
        text: row.date,
        singleLine: true,
        padding: '0 3px',
        fontSize: 8,
    };
};

const isPulseHighlighted = (row: any)=>{
    return includes(row.pulse_value, HIGHLIGHT_PERCENTILES);
}

export const getPulseColor = (row: any) => {
    return isPulseHighlighted(row)
        ? COLORS.DEFAULT_COLOR
        : row.pulse_value > 0
        ? COLORS.RED
        : COLORS.GREEN;
};
export const getPulseBgColor = (row: any) => {
    return isPulseHighlighted(row)
        ? getBgColorFromPercentile(row.pulse_value)
        : COLORS.DEFAULT_BG;
};

export const getPulseFontWeight = (row: any) => {
    return isPulseHighlighted(row) ? 'bold' : 'normal';
};

export const getPulseText = (row: any) => {
    const absValue = Math.abs(row.pulse_value);
    return absValue < 75 ? '<75%' : `${absValue}%`;
};

export const CELL_TEXT_SHADOW = `1px 1px ${COLORS.DEFAULT_BG}`;
export const getRiskCell = (
    row: any,
    cellAttributes: Partial<Cell> = {}
): Cell => {
    return {
        ...cellAttributes,
        text: row.risk.replace('/', ' / '),
        align: 'left',
        padding: '0 3px',
    };
};

const isRiskHighlighted = (row: any) => {
    return includes(row.risk_change, HIGHLIGHT_PERCENTILES);
};

export const getCompositeRiskColor = (row: any) => {
    return isRiskHighlighted(row)
        ? COLORS.DEFAULT_COLOR
        : row.risk_change > 0
        ? COLORS.RED
        : COLORS.GREEN;
};

export const getCompositeRiskBgColor = (row: any) => {
    return isRiskHighlighted(row)
        ? getBgColorFromPercentile(row.risk_change)
        : COLORS.DEFAULT_BG;
};

export const getCompositeRiskFontWeight = (row: any) => {
    return isRiskHighlighted(row) ? 'bold' : 'normal';
}

export const getCompositeRiskText = (row: any) => {
    const absValue = Math.abs(row.risk_change);
    return absValue < 75 ? '<75%' : `${absValue}%`;
}

export const getCompositeRiskCell = (row: any): Cell => {
    return {
        text: getCompositeRiskText(row),
        color: getCompositeRiskColor(row),
        bg: getCompositeRiskBgColor(row),
        fontWeight: getCompositeRiskFontWeight(row),
        fontSize: mailchimpConfig.fontSize * 0.8,
        textShadow: CELL_TEXT_SHADOW,
    };
};

export const getPulseCell = (row: any): Cell => {
    return {
        text: getPulseText(row),
        color: getPulseColor(row),
        bg: getPulseBgColor(row),
        fontWeight: getPulseFontWeight(row),
        fontSize: mailchimpConfig.fontSize * 0.8,
        textShadow: CELL_TEXT_SHADOW,
    };
};

export const getHighLowCellText = (row: any) => {
    return !row.level ? '-' : row.level === 'max' ? 'New High' : 'New Low';
};

export const getHighLowCellColor = (row: any) => {
    return !row.level
        ? COLORS.DEFAULT_BG
        : row.level === 'max'
        ? COLORS.RED_BG
        : COLORS.GREEN_BG;
};

export const getHighLowFontWeight = (row: any) => {
    return row.level ? 'bold' : 'normal';
};

export const getHighLowCell = (row: any): Cell => {
    return {
        text: getHighLowCellText(row),
        color: getHighLowCellColor(row),
        fontWeight: getHighLowFontWeight(row),
        bg: COLORS.DEFAULT_BG,
        padding: '0 2px',
    };
};

const getRiskBankCellCurrPrev = (row: any) =>{
    const {
        band: { current_band: curr, previous_band: prev },
    } = row;
    return {curr, prev}
}

export const getRiskBankCellText = (row: any) => {
    const { curr, prev } = getRiskBankCellCurrPrev(row);
    const riskBandUp = prev < curr;
    const riskBandArrow = riskBandUp ? ARROW_UP : ARROW_DOWN;
    return isNil(prev) || prev === curr
        ? RISK_ID_TO_BAND[curr]
        : `${riskBandArrow} ${RISK_ID_TO_BAND[prev]} to ${RISK_ID_TO_BAND[curr]}`;
};

const hasRiskBandSignal = (row: any) => {
    const { curr, prev } = getRiskBankCellCurrPrev(row);
    return !isNil(prev) && !isNil(curr) && curr !== prev;
};
export const getRiskBanCellBg = (row: any) => {
    const { curr, prev } = getRiskBankCellCurrPrev(row);
    const riskBandUp = prev < curr;
    return !hasRiskBandSignal(row)
        ? COLORS.DEFAULT_BG
        : riskBandUp
        ? COLORS.RED_BG
        : COLORS.GREEN_BG;
};

export const getRiskBandCellFontWeight = (row: any) =>{
    return hasRiskBandSignal(row) ? 'bold' : 'normal';
}

export const getRiskBandCell = (row: any): Cell => {
    return {
        text: getRiskBankCellText(row),
        color: COLORS.DEFAULT_COLOR,
        bg: getRiskBanCellBg(row),
        fontWeight: getRiskBandCellFontWeight(row),
    };
};

export const SignalsTemplateTable = ({ title, columns, rows }: Props) => {
    if (isEmpty(rows)) {
        return null;
    }

    return (
        <Table style={{ marginBottom: '2rem' }}>
            <TBody>
                {title && (
                    <TR>
                        <TD
                            style={{
                                paddingBottom: '1rem',
                            }}>
                            {title}
                        </TD>
                    </TR>
                )}
                <TR>
                    <TD>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <THead>
                                <TR>
                                    {mapWithKeys(
                                        (
                                            column: Column,
                                            columnIndex: number
                                        ) => (
                                            <TH
                                                key={columnIndex}
                                                style={{
                                                    fontSize:
                                                        column.fontSize ||
                                                        mailchimpConfig.fontSize *
                                                            0.6,
                                                    fontWeight: 600,
                                                    padding:
                                                        column.padding || 0,
                                                    width:
                                                        column.width || 'auto',
                                                }}>
                                                {column.text}
                                            </TH>
                                        ),
                                        columns
                                    )}
                                </TR>
                            </THead>
                            <TBody>
                                {mapWithKeys(
                                    (row: Row, rowIndex: number) => (
                                        <TR
                                            key={rowIndex}
                                            style={{ lineHeight: 1.1 }}>
                                            {mapWithKeys(
                                                (
                                                    cell: Cell,
                                                    cellIndex: number
                                                ) => (
                                                    <React.Fragment
                                                        key={cellIndex}>
                                                        {cell.image ? (
                                                            <ImageTableCell
                                                                cell={cell}
                                                            />
                                                        ) : (
                                                            <TableCell
                                                                cell={cell}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ),
                                                row
                                            )}
                                        </TR>
                                    ),
                                    rows
                                )}
                            </TBody>
                        </Table>
                    </TD>
                </TR>
            </TBody>
        </Table>
    );
};

const TableCell = ({ cell }: { cell: Cell }) => {
    return (
        <CellTdContainer cell={cell}>
            <span
                style={{
                    wordBreak: cell.singleLine ? 'keep-all' : 'break-word',
                    whiteSpace: cell.singleLine ? 'nowrap' : 'normal',
                    color: cell.color || COLORS.DEFAULT_COLOR,
                    fontSize: cell.fontSize || mailchimpConfig.fontSize * 0.6,
                    padding: 0,
                    margin: 0,
                    textShadow: cell.textShadow || 'none',
                }}>
                {cell.text || cell}
            </span>
        </CellTdContainer>
    );
};

const ImageTableCell = ({ cell }) => {
    return (
        <CellTdContainer cell={cell}>
            <Table>
                <TBody>
                    <TR>
                        <TD style={{ width: 19 }}>
                            <img
                                alt=""
                                src={cell.image}
                                width="16"
                                height="12"
                                style={{
                                    margin: '0 3px 0 0',
                                }}
                            />
                        </TD>
                        <TD>
                            <span
                                style={{
                                    wordBreak: cell.singleLine
                                        ? 'keep-all'
                                        : 'break-word',
                                    whiteSpace: cell.singleLine
                                        ? 'nowrap'
                                        : 'normal',
                                    color: cell.color || COLORS.DEFAULT_COLOR,
                                    fontSize:
                                        cell.fontSize ||
                                        mailchimpConfig.fontSize * 0.6,
                                    padding: 0,
                                    margin: 0,
                                    textShadow: cell.textShadow || 'none',
                                }}>
                                {cell.text || cell}
                            </span>
                        </TD>
                    </TR>
                </TBody>
            </Table>
        </CellTdContainer>
    );
};

const CellTdContainer = ({ cell, children }) => {
    return (
        <td
            style={{
                backgroundColor: cell.bg || COLORS.DEFAULT_BG,
                border: `1px solid ${COLORS.BORDER}`,
                padding: cell.padding || 0,
                paddingBottom: 3,
                margin: 0,
                fontWeight: cell.fontWeight || 'inherit',
                textAlign: cell.align || 'center',
            }}>
            {children}
        </td>
    );
};

export const TH = ({ children, style, ...rest }) => {
    return (
        <th
            {...rest}
            style={{
                backgroundColor: COLORS.DEFAULT_BG,
                border: `1px solid ${COLORS.BORDER}`,
                padding: 0,
                color: COLORS.DEFAULT_COLOR,
                textAlign: 'center',
                ...style,
            }}>
            {children}
        </th>
    );
};

export const TR = ({
    children,
    style,
    ...rest
}: {
    children: ReactNode;
    style?: React.CSSProperties;
    rest?: any;
}) => {
    return (
        <tr
            {...rest}
            style={{
                backgroundColor: COLORS.DEFAULT_BG,
                padding: 0,
                border: 'none',
                ...style,
            }}>
            {children}
        </tr>
    );
};

export const VSpace = ({ size = 1 }) => {
    return (
        <tr style={{ backgroundColor: COLORS.DEFAULT_BG, padding: 0 }}>
            <td style={{ height: size * 16 }} />
        </tr>
    );
};

export const Text = ({
    children,
    bold,
    underline,
}: {
    children: ReactNode;
    bold?: boolean;
    underline?: boolean;
}) => {
    return (
        <span
            style={{
                color: COLORS.DEFAULT_COLOR,
                fontWeight: bold ? 'bold' : 'normal',
                textDecoration: underline ? 'underline' : 'none',
            }}>
            {children}
        </span>
    );
};

export const TD = ({
    children,
    align,
    style,
    ...rest
}: {
    children: ReactNode;
    style?: CSSProperties;
    align?: CSSProperties['textAlign'];
    rest?: object;
}) => {
    return (
        <td
            {...rest}
            style={{
                backgroundColor: COLORS.DEFAULT_BG,
                padding: 0,
                ...style,
                textAlign: align,
            }}>
            {children}
        </td>
    );
};

export const TBody = ({ children }) => {
    return (
        <tbody
            style={{
                border: 'none',
                backgroundColor: COLORS.DEFAULT_BG,
                padding: 0,
            }}>
            {children}
        </tbody>
    );
};

export const THead = ({ children }) => {
    return (
        <thead
            style={{
                border: 'none',
                backgroundColor: COLORS.DEFAULT_BG,
                padding: 0,
            }}>
            {children}
        </thead>
    );
};

export const Table = ({
    children,
    style,
}: {
    children: ReactNode;
    style?: CSSProperties;
}) => {
    return (
        <table
            cellSpacing={0}
            cellPadding={0}
            style={{
                border: 'none',
                backgroundColor: COLORS.DEFAULT_BG,
                padding: 0,
                margin: 0,
                width: '100%',
                maxWidth: '600px',
                ...style,
            }}>
            {children}
        </table>
    );
};

export const Header = ({ children }) => {
    return (
        <Table style={{ width: '100%', maxWidth: 600 }}>
            <TBody>
                <TR>
                    <TD align="center">
                        <span
                            style={{
                                color: COLORS.DEFAULT_COLOR,
                                fontWeight: 'bold',
                                fontSize: 20,
                                letterSpacing: 20,
                            }}>
                            {children}
                        </span>
                    </TD>
                </TR>
                <VSpace size={1} />
            </TBody>
        </Table>
    );
};

type Data = {
    signals: object;
    report_date?: string;
    time_span?: string;
    user_id?: string;
};

enum TemplateType {
    MAILCHIMP,
    DASHBOARD,
}

export const useSignalsData = (mockData?: any) => {
    const [data, setData] = useState<Data>({
        signals: {},
    });

    useEffect(() => {
        if (!isEmpty(mockData)) {
            setData(mockData);
        }
    }, [mockData]);

    const onScreenshotClick = () => {
        const element = document.querySelector('.signals-template-page');
        document.querySelectorAll('img').forEach(img => {
            const flagImageMatch = img.src.match(/\/flags\/tiny\/(.+)\.png$/i);
            if (!flagImageMatch) {
                return;
            }
            const countryCode = flagImageMatch[1];
            const newFlagSrc = `/assets/flags/1x1/${countryCode}.svg`;
            img.src = newFlagSrc;
            img.style.width = '19px';
            img.style.paddingRight = '4px';
        });

        // without this setTimeout - the flags change hack will not finish working
        setTimeout(() => {
            //@ts-ignore
            html2canvas(element, { logging: false }).then(function(canvas) {
                const base64Image = canvas.toDataURL('image/png');
                const data = {
                    type: IFRAME_DATA_TYPES.SCREENSHOT,
                    base64Image,
                };
                window.top.postMessage(data, '*');
            });
        }, 100);
    };

    const [type, setType] = useState<TemplateType>(null);

    useEffect(() => {
        window.onmessage = function(msg) {
            const type = get(['data', 'type'], msg);
            if (type === IFRAME_DATA_TYPES.SCREENSHOT){
                onScreenshotClick();
                return;
            }

            if (
                type === IFRAME_DATA_TYPES.SIGNALS &&
                !isEmpty(get(['data', 'signals'], msg))
            ) {
                setData(get('data', msg));
                setType(TemplateType.DASHBOARD);
                return;
            }
        };
    }, []);

    const reportDate = useMemo(() => {
        const dateString = get('report_date', data);
        if (isEmpty(dateString)) {
            return new Date();
        }
        return moment(dateString).toDate();
    }, [data]);

    const timeWindow = useMemo(() => {
        const timeWindowStr = get('time_span', data);
        if (isNil(timeWindowStr)) {
            return '';
        }

        return DAYS_TO_TIME_WINDOW[timeWindowStr];
    }, [data]);

    useEffect(() => {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                const jsonDataScript = flow(
                    get('addedNodes'),
                    find(addedNode => {
                        return (
                            'SCRIPT' === get('nodeName', addedNode) &&
                            'application/json' === get('type', addedNode)
                        );
                    })
                )(mutation);

                if (!jsonDataScript) {
                    return;
                }

                setType(TemplateType.MAILCHIMP);

                const json = JSON.parse(jsonDataScript.innerHTML);
                setData(json);
            });
        });

        observer.observe(document.head, {
            childList: true,
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = 'auto';
        document.body.style.margin = '0';
        document.body.style.padding = '0';
        document.documentElement.style.fontSize = `${mailchimpConfig.fontSize}px`;
    }, []);

    useEffect(() => {
        if (isEmpty(get('signals', data)) || isNil(type)) {
            return;
        }

        if (type === TemplateType.MAILCHIMP) {
            document.body.innerHTML =
                mailchimpConfig.bodyPrefix + document.body.innerHTML;
        }
    }, [data, type]);

    return {
        signals: data?.signals,
        reportDate,
        timeWindow,
        uid: data?.user_id,
    };
};

export const SignalsPage = ({ children }: { children: ReactNode }) => {
    return (
        <table
            className="signals-template-page"
            cellSpacing={0}
            cellPadding={0}
            style={{
                border: 'none',
                backgroundColor: COLORS.DEFAULT_BG,
                padding: 0,
                margin: 0,
                width: '100%',
            }}>
            <TBody>
                <TR>
                    <td
                        align="center"
                        style={{
                            padding: '40px 0',
                            margin: 0,
                            border: 'none',
                            width: '100%',
                        }}>
                        <table
                            style={{
                                width: '100%',
                                maxWidth: '600px',
                                padding: 0,
                                border: 'none',
                            }}>
                            <TBody>
                                <TR>
                                    <TD align="center">
                                        <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            style={{
                                                width: '100%',
                                                maxWidth: '600px',
                                                fontFamily:
                                                    '"Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
                                            }}>
                                            <TBody>{children}</TBody>
                                        </table>
                                    </TD>
                                </TR>
                            </TBody>
                        </table>
                    </td>
                </TR>
            </TBody>
        </table>
    );
};


const HYPHEN = '‑';

export const RISK_ID_TO_BAND = {
    0: 'Low',
    1: `Med${HYPHEN}Low`,
    2: 'Medium',
    3: `Med${HYPHEN}High`,
    4: 'High',
};

export const FOOTER_PADDING = '0 12px';


export const Footer = React.memo(({ uid }: {uid: string}) => {
    return (
        <>
        <TR>
            <TD align="left" style={{ padding: FOOTER_PADDING }}>
                <Table>
                    <TBody>
                        <VSpace />
                        <TR>
                            <TD align="left">
                                <Text bold underline>
                                    Highlighted cells indicate a new signal
                                </Text>
                            </TD>
                        </TR>
                        <VSpace />
                        <TR>
                            <TD align="left">
                                <Text bold>Pulse Risk: </Text>
                                <Text>
                                    The daily Pulse Risk score is in the
                                    top/bottom 90% of all scores in the
                                    corresponding time horizon.
                                </Text>
                            </TD>
                        </TR>
                        <VSpace />
                        <TR>
                            <TD align="left">
                                <Text bold>Composite Risk: </Text>
                                <Text>
                                    A large (≥ 90th percentile) day over day
                                    change in Composite Risk has occurred
                                    relative to other day over day changes in
                                    the corresponding time horizon.
                                </Text>
                            </TD>
                        </TR>
                        <VSpace />
                        <TR>
                            <TD align="left">
                                <Text bold>New High/Low: </Text>
                                <Text>
                                    Risk has hit a new high/low over the
                                    specified time horizon.
                                </Text>
                            </TD>
                        </TR>
                        <VSpace />
                        <TR>
                            <TD align="left">
                                <Text bold>Risk Band: </Text>
                                <Text>
                                    A country has crossed from one categorical
                                    risk band into another. Higher bands
                                    indicate higher risk.
                                </Text>
                            </TD>
                        </TR>
                        {uid && <>
                            <VSpace  size={8}/>
                            <TR>
                                <TD align="center">
                                    <Text bold underline>
                                        <a
                                            href={`${getUserFacingUrl()}/#/unsub/signals/${uid}`}
                                            style={{
                                                color: '#ccc',
                                                fontWeight: 'normal',
                                                cursor: 'pointer',
                                                fontSize: '75%'
                                            }}>
                                            Unsubscribe
                                        </a>
                                    </Text>
                                </TD>
                            </TR></>}
                        <VSpace />
                    </TBody>
                </Table>
            </TD>
        </TR>
        </>
    );
});

export const LogoImage = () => {
    return (
        <img
            src={`$${S3_BASE_URL}/logo-icon-small.png`}
            style={{ maxWidth: 'none', maxHeight: 40 }}
            alt="logo"
        />
    );
};