import rootStore, { initRootStore } from '../RootStore';
import useSettings from 'hooks/useSettings';
import CustomWeightStore from 'stores/CustomWeightStore';
import { get, isEmpty } from 'lodash/fp';
import { useEffect, useRef, useState } from 'react';
import usePreload from './usePreload';
import { initFullStory } from 'utils/FullStoryUtils';
import { E_EVENTS_WORKER_IPC_CODES } from 'components/eventsFeed/EventsWorkerIPC';
import { getApiURL } from 'services/configService';
import APIService from 'services/APIService';
import { useLocation } from 'react-router-dom';

const useInit = () => {
    const {
        settings,
        loading: loadingSettings,
    } = useSettings();
    const { didPreload, error: preloadError, preloadData } = usePreload();
    const [didInitStores, setDidInitStores] = useState<boolean>(false);

    useEffect(() => {
        if (isEmpty(settings) || !didPreload || isEmpty(preloadData)) {
            return;
        }

        initFullStory(settings.username);

        initRootStore();

        CustomWeightStore.updateActivePreset(
            settings.activeCustomWeightsPreset
        );

        rootStore.risksStore.setDisabledRisks(
            get('permissions.dimensionResoure', settings),
            get('permissions.clientFacingIndicatorsResource', settings)
        );
        rootStore.UserStore.setUscorePermission(
            get('permissions.uscoreResource', settings)
        );
        rootStore.UserStore.setUsername(get('username', settings));
        rootStore.UserStore.setOrganization(get('organization', settings));
        rootStore.UserStore.setGeoquantFlagsPermission(
            get('permissions.geoquantResource', settings)
        );
        rootStore.UserStore.setAllPermissions(
            get('permissions', settings)
        );
        rootStore.chartStore.updateStateByScoresDelta(
            get('organization.scores_delta_offset_start', settings),
            get('organization.scores_delta_window_size', settings)
        );

        rootStore.countryStore.updateCountries(preloadData.countries);
        rootStore.risksStore.updateDimensions(preloadData.dimensions);
        rootStore.risksStore.updateAllDimensions(preloadData.dimensions.allDimensions);
        rootStore.risksStore.updateClientFacingIndicators(
            preloadData.clientFacingIndicators
        );
        rootStore.risksStore.updateContingentRisks(preloadData.contingentRisks);
        rootStore.risksStore.initRisksByType();
        rootStore.settingsStore.setActiveGroupId(
            preloadData.activeGroups.active_group
        );
        rootStore.settingsStore.setActiveGroups(
            preloadData.activeGroups.groups
        );

        rootStore.eventsFeedStore.eventsHandlerWorker.postMessage({
            code: E_EVENTS_WORKER_IPC_CODES.INIT_DATA,
            baseURL: getApiURL(),
            token: APIService.__getToken(),
            dimensionsTree: get('dimensions.fullTree', preloadData),
        });

        setDidInitStores(true);
    }, [settings, didPreload, preloadData]);

    const location = useLocation();
    const prevLocation = useRef(location);
    useEffect(() => {
        if (get('pathname', prevLocation) === get('pathname', location)) {
            return;
        }
        rootStore.routingStore.trackViewChange(prevLocation.current, location);
        prevLocation.current = location;
    }, [location]);

    return {
        didPreload,
        didInitStores,
        preloadError,
        loadingSettings,
        ready:
            didPreload &&
            !loadingSettings &&
            didInitStores &&
            !preloadError
    };
};


export default useInit;