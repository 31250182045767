import { useEffect, useState } from 'react';
import APIService, { IActivityRecord } from 'services/APIService';

export const useInsight = ({ id }: { id: string }) => {
    const [insight, setInsight] = useState<IActivityRecord>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        APIService.getFullActivity(id)
            .then(setInsight)
            .catch(setError)
            .finally(() => setLoading(false));
        return () => {
            setLoading(false);
        };
    }, [id]);

    return { insight, error, loading };
};
