import { observable, action, computed, extendObservable } from 'mobx';
import moment from 'moment';
import {
    ActivityChartComponentStore,
    ActivityMapComponentStore,
    ActivityComponentStore,
    ActivityCustomImageComponentStore,
    ActivityHeatmapComponentStore,
} from './insights/InsightsComponentStore';
import {
    IInsightData,
    E_COMPONENT_TYPE,
    IActivityComponent,
    IActivityHeatmapComponent,
    AutoInsightMetadata,
} from '../services/lib/APIServiceInterfaces';
import { includes } from 'lodash/fp';

export type IPossibleComponentStore =
    | ActivityChartComponentStore
    | ActivityMapComponentStore
    | ActivityCustomImageComponentStore
    | ActivityHeatmapComponentStore;

export const isChartComponent = (
    component: IPossibleComponentStore
): component is ActivityChartComponentStore => {
    return (
        component !== null &&
        includes(component.type, [
            E_COMPONENT_TYPE.CHART,
            E_COMPONENT_TYPE.CONTINGENT_CHART,
        ])
    );
};

export const isMapComponent = (
    component: IPossibleComponentStore
): component is ActivityMapComponentStore => {
    return (
        component !== null &&
        new Set([
            E_COMPONENT_TYPE.MAP,
            E_COMPONENT_TYPE.DELTA,
            E_COMPONENT_TYPE.RISK,
            E_COMPONENT_TYPE.CR_DELTA,
            E_COMPONENT_TYPE.CR_RISK,
            E_COMPONENT_TYPE.CONTINGENT_MAP,
        ]).has(component.type)
    );
};

export const isCustomComponent = (
    component: IPossibleComponentStore
): component is ActivityCustomImageComponentStore => {
    return component !== null && component.type === E_COMPONENT_TYPE.CUSTOM;
};

export const castComponentToChart = (
    component: ActivityComponentStore
): ActivityChartComponentStore | null => {
    if (isChartComponent(component)) {
        return component;
    }
    return null;
};

export const castComponentToMap = (
    component: ActivityComponentStore
): ActivityMapComponentStore | null => {
    if (isMapComponent(component)) {
        return component;
    }
    return null;
};

export const castComponentToHeatmap = (
    component: ActivityComponentStore
): ActivityHeatmapComponentStore | null => {
    return component.type === E_COMPONENT_TYPE.HEATMAP ? component : null;
};

export const castComponentToImage = (
    component: ActivityComponentStore
): ActivityCustomImageComponentStore | null => {
    if (isCustomComponent(component)) {
        return component;
    }
    return null;
};

export const componentFactory = (
    component: IActivityComponent
): ActivityComponentStore | null => {
    switch (component.type) {
        case E_COMPONENT_TYPE.CONTINGENT_CHART:
        case E_COMPONENT_TYPE.CHART:
            const st = new ActivityChartComponentStore(component);
            return st;
        case E_COMPONENT_TYPE.CR_DELTA:
        case E_COMPONENT_TYPE.CR_RISK:
        case E_COMPONENT_TYPE.DELTA:
        case E_COMPONENT_TYPE.RISK:
        case E_COMPONENT_TYPE.MAP:
        case E_COMPONENT_TYPE.CONTINGENT_MAP:
            return new ActivityMapComponentStore(component);
        case E_COMPONENT_TYPE.CUSTOM:
            return new ActivityCustomImageComponentStore(component);
        case E_COMPONENT_TYPE.HEATMAP:
            return new ActivityHeatmapComponentStore(
                component as IActivityHeatmapComponent
            );
    }
    return null;
};

interface IActivityProps {
    _id?: string;
    date: Date;
    insightsData: IInsightData;
    zipKey: string;
    shareable: boolean;
    createdByMe: boolean;
    components: IActivityComponent[];
    auto_generated: AutoInsightMetadata;
}
export default class SpecificActivityStore {
    @observable public readonly id: string;
    @observable public readonly date: Date;
    @observable public readonly zipKey?: string;
    @observable public readonly shareable?: boolean;
    @observable public readonly shared?: boolean;
    @observable public readonly createdByMe?: boolean;
    @observable public readonly auto_generated?: AutoInsightMetadata;
    @observable public insightsData: IInsightData;
    @observable private componentsArr: ActivityComponentStore[];
    constructor(activityProps: Partial<IActivityProps> = {}) {
        activityProps = activityProps || {};

        if (activityProps._id) {
            this.id = activityProps._id;
        }
        if (activityProps.createdByMe) {
            this.createdByMe = activityProps.createdByMe;
        }
        if (activityProps.date) {
            this.date = moment(activityProps.date).toDate();
        }
        if (activityProps.insightsData) {
            this.insightsData = activityProps.insightsData;
        } else {
            this.insightsData = {
                insight: {
                    nodes: null,
                },
                name: '',
                tags: [],
            };
        }
        this.componentsArr = activityProps.components
            ? activityProps.components.map(c => componentFactory(c))
            : [];
        this.auto_generated = activityProps.auto_generated;
    }

    @action
    public addComponent(component: IActivityComponent) {
        const newComponent = componentFactory(component);
        if (newComponent) {
            this.componentsArr.push(newComponent);
        }
    }

    @action
    public removeComponent(id: string) {
        this.componentsArr = this.componentsArr.filter(c => c.id !== id);
    }

    @computed
    public get components() {
        return this.componentsArr;
    }

    @action
    public setComponents(components: ActivityComponentStore[]) {
        this.componentsArr = components;
    }
    @action public setInsightData(data: Partial<IInsightData>) {
        extendObservable(this.insightsData, data);
    }
}
