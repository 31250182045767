import React from 'react';
import rootStore, { IMobxRootState } from '../../RootStore';
import {
    IActiveDimension,
    IAPIActiveClientFacingIndicator,
} from 'services/lib/APIServiceInterfaces';
import { inject } from 'mobx-react';
import { getCFILongName, getCFIShortName, getDimensionLongName, getDimensionShortName } from 'utils/riskUtils';

interface Props {
    id: number;
    shortName?: boolean;
    isCFI: boolean;
    style?: React.CSSProperties;
    dimensions?: { [dimensionId: number]: IActiveDimension };
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
}

@inject(
    ({ risksStore }: IMobxRootState): Partial<Props> => {
        return {
            dimensions: risksStore.dimensions.hashed,
            activeClientFacingIndicators: risksStore.clientFacingIndicators,
        };
    }
)
export default class GQDimensionChip extends React.Component<Props> {
    public render() {
        const style: React.CSSProperties = {
            ...this.props.style,
            borderColor:
                (this.props.style && this.props.style.borderColor) ||
                rootStore.risksStore.colorStack.color(this.props.id),
        };
        return this.props.dimensions ? (
            <div className="gq-chip" style={style}>
                {this.getDimensionName()}
            </div>
        ) : null;
    }

    private getDimensionName() {
        const {
            dimensions,
            shortName,
            isCFI,
            id,
            activeClientFacingIndicators,
        } = this.props;
        let name: string;
        if (isCFI) {
            const cfi = activeClientFacingIndicators[id];
            name = shortName ? getCFIShortName(cfi) : getCFILongName(cfi);
        } else {
            const dimension = dimensions[id];
            name = shortName
                ? getDimensionShortName(dimension)
                : getDimensionLongName(dimension);
        }
        return <span>{name}</span>;
    }
}
