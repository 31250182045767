import React, { useCallback, useState } from 'react';
import Logo from 'assets/general/logo.svg';
import closeIcon from 'assets/general/close.svg';
import hamburgerIcon from 'assets/general/hamburger.png';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

const GQNavbarMobile = React.memo(() => {
    const onLogoClick = useCallback(() => {
        window.location.href = '/';
    }, []);
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenuOpenState = useCallback(
        () => setMenuOpen(isOpen => !isOpen),
        []
    );
    const { push } = useHistory();
    const navigate = useCallback((to: string) => {
        push(to);
        setMenuOpen(false);
    }, [push]);

    return (
        <div className="navbar-mobile">
            <div className="navbar-mobile-logo" onClick={onLogoClick}>
                <img src={Logo} alt="Logo" className="navbar-mobile-logo-img" />
            </div>
            <div className="navbar-mobile-toggle" onClick={toggleMenuOpenState}>
                <img
                    src={menuOpen ? closeIcon : hamburgerIcon}
                    alt="Toggle menu"
                    className="navbar-mobile-toggle-img"
                />
            </div>
            <div
                className={cx('navbar-mobile-hamburger', {
                    visible: menuOpen,
                })}>
                <div
                    className="mobile-hamburger-link"
                    onClick={() => navigate('/insights')}>
                    Insights
                </div>
                <div
                    className="mobile-hamburger-link"
                    onClick={() => navigate('/signals')}>
                    Signals
                </div>
                <a
                    className="mobile-hamburger-link"
                    href="https://geoquant.com">
                    About
                </a>
            </div>
        </div>
    );
});

export default GQNavbarMobile;
