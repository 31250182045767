import ScoresCalc from './lib/ScoresCalc';
import * as Ifs from './lib/APIServiceInterfaces';
import axios, { AxiosPromise, AxiosInstance, AxiosResponse } from 'axios';
import { LoggerService } from './LoggerService';
import { APICacheInstance } from './lib/APICacheInstance';
import * as configService from './configService';
import moment, { Moment } from 'moment';
import StorageService from './StorageService';
import * as _ from 'lodash';
import {
    C_FIRST_DAY_HUMAN_SCORES_DATE,
    C_SCORES_DATE_FORMAT,
    C_TIMELINE_MAX_DATE,
    C_TIMELINE_MIN_DATE,
} from '../constants';
import EventEmitter from './EventsService';
import settingsStore from '../stores/SettingsStore';
import WebSocketService from './lib/WebSocketService';
import { IUserPermissions, IDeltaByCountry } from '../interfaces';
import {
    IUscoreActive,
    EVENTS_FILTER,
    EventScoreType,
} from '../components/eventsFeed/EventsFeedInterfaces';
import appStore from '../AppStore';
import { get, join, keyBy, orderBy } from 'lodash/fp';
import { HeatmapScoreType } from 'components/Heatmap/HeatmapTypes';

let webSocket: WebSocketService = null;

export function initiate(isMainThread: boolean) {
    if (isMainThread) {
        APIService.reloadFirst();
        EventEmitter.registerOnReloadCountry(cids => {
            APIService.clearCountriesData();
        });
    }
}

export * from './lib/APIServiceInterfaces';
export default class APIService {
    public static overridenToken: string = null;
    public static overrideGQActiveHeader: string = null;
    private static axiosInstance: AxiosInstance = null;
    private static getAxiosInstance(): AxiosInstance {
        if (!APIService.axiosInstance) {
            APIService.axiosInstance = axios.create({
                baseURL: configService.getApiURL(),
                headers: { 'content-type': 'application/json' },
                withCredentials: true,
            });
        }

        return APIService.axiosInstance;
    }

    public static async getActiveDimensionsList(): Promise<
        Ifs.IActiveDimension[]
    > {
        const result = await this.getActiveDimensions();
        return result.flatten;
    }

    public static async getActiveDimensionsTree(): Promise<
        Ifs.IActiveDimensionTree
    > {
        const result = await this.getActiveDimensions();
        return result.tree;
    }

    public static async getActiveDimensions(): Promise<
        Ifs.IActiveDimensionAPI
    > {
        const axiosInstance = APIService.getAxiosInstance();
        let result = await this._requestsMiddleware<Ifs.IActiveDimensionAPI>(
            axiosInstance.get.bind(axiosInstance),
            'ACTIVE_DIMENSIONS',
            'in/active_dimensions',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        result = {
            ...result,
            hashed: keyBy(
                (dim: Ifs.IActiveDimension) => dim.id,
                result.flatten
            ),
        };
        return result;
    }

    public static async getActiveGroups(): Promise<
        Ifs.IActiveGroupsAPIResponse
    > {
        const axiosInstance = APIService.getAxiosInstance();
        return await this._requestsMiddleware<Ifs.IActiveGroupsAPIResponse>(
            axiosInstance.get.bind(axiosInstance),
            'active_groups',
            'in/settings/active_groups',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }

    public static async deleteActiveGroup(
        group: Ifs.IActiveGroup
    ): Promise<undefined> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<undefined>(
            axiosInstance.delete.bind(axiosInstance),
            null,
            `in/settings/active_groups/${group.id}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return result;
    }
    public static async saveActiveGroup(
        group: Ifs.IActiveGroup
    ): Promise<Ifs.IActiveGroup> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<Ifs.IActiveGroup>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/settings/active_groups',
            group,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return result;
    }

    public static async getActivities() {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<{
            activities: Ifs.IBasicActivityRecord[];
        }>(axiosInstance.get.bind(axiosInstance), null, 'in/activity/latest', {
            headers: this.getCommonLoggedInHeaders(),
        });
        return result.activities
            .map(activity => {
                activity.date = moment(activity.date).toDate();
                if (activity.shareDate) {
                    activity.shareDate = moment(activity.shareDate).toDate();
                }
                return activity;
            })
            .sort((a, b) => {
                const aDate = a.shareDate
                    ? a.shareDate.getTime()
                    : a.date.getTime();
                const bDate = b.shareDate
                    ? b.shareDate.getTime()
                    : b.date.getTime();
                return bDate - aDate;
            })
    }

    public static async getFullActivity(
        id: string
    ): Promise<Ifs.IActivityRecord> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<Ifs.IActivityRecord>(
            axiosInstance.get.bind(axiosInstance),
            null,
            `in/activity/for_editing/${id}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return this.convertActivity(result);
    }

    public static async unsub(
        uid: string,
    ): Promise<{}> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<Ifs.AutoInsightResult>(
            axiosInstance.get.bind(axiosInstance),
            null,
            `unsubscribe/signals/${uid}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return result;
    }

    public static async shareActivitiy(
        activity: Ifs.IBasicActivityRecord,
        sendEmail: boolean
    ): Promise<Ifs.IBasicActivityRecord> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<{ shared: boolean }>(
            axiosInstance.get.bind(axiosInstance),
            null,
            `in/activity/share/${activity._id}`,
            {
                headers: this.getCommonLoggedInHeaders(),
                params: { sendEmail },
            }
        );
        const clone = _.cloneDeep(activity);
        clone.shared = result.shared;
        return clone;
    }

    public static convertActivity(activity: Ifs.IActivityRecord) {
        activity.date = moment(activity.date).toDate();
        activity.components.forEach(c => {
            if (!c.snapshot) {
                return;
            }

            c.snapshot.virtualToday = moment(c.snapshot.virtualToday).toDate();
            c.snapshot.baseDate = moment(c.snapshot.baseDate).toDate();
        });
        return activity;
    }

    public static async getUnseenActivitiesCount(): Promise<{ count: number }> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<{ count: number }>(
            axiosInstance.get.bind(axiosInstance),
            null,
            '/in/activity/unseen_insights_counter',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return result;
    }

    public static async autoGenerateInsight(
        countries: number[],
        startDate: moment.Moment,
        endDate: moment.Moment,
        riskType: Ifs.E_RISK_TYPE,
        risk: number,
        question?: string,
    ) {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<Ifs.AutoInsightResult>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/activity/auto_generate',
            {
                countries_id: countries,
                risks: [
                    {
                        risk_type: riskType,
                        id: risk,
                    },
                ],
                start_date: startDate.format(C_SCORES_DATE_FORMAT),
                end_date: endDate.format(C_SCORES_DATE_FORMAT),
                question,
            },
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return result;
    }
    public static async deleteActivity(activityId: string) {
        const axiosInstance = APIService.getAxiosInstance();
        await this._requestsMiddleware<{ shared: boolean }>(
            axiosInstance.delete.bind(axiosInstance),
            null,
            `in/activity/${activityId}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return true;
    }

    public static async saveActivity(
        activity: any,
        componentNames: any[]
    ): Promise<Ifs.IActivityRecord> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<Ifs.IActivityRecord>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/activity/create_activity',
            { activity, componentNames },
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return result;
    }

    public static async addTempComponent(component: Ifs.IActivityComponent) {
        for (let retries = 2; retries > 0; retries--) {
            try {
                const axiosInstance = APIService.getAxiosInstance();
                const result = await this._requestsMiddleware<
                    Ifs.IActivityComponent
                >(
                    axiosInstance.post.bind(axiosInstance),
                    null,
                    'in/activity/add_component',
                    component,
                    {
                        headers: this.getCommonLoggedInHeaders(),
                    }
                );
                return result;
            } catch {}
        }
    }

    public static async getTempComponent(
        id: string
    ): Promise<Ifs.IActivityComponent> {
        const axiosInstance = APIService.getAxiosInstance();
        const result = await this._requestsMiddleware<Ifs.IActivityComponent>(
            axiosInstance.get.bind(axiosInstance),
            null,
            `in/activity/temp_component/${id}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        if (result.snapshot.virtualToday) {
            result.snapshot.virtualToday = new Date(
                result.snapshot.virtualToday
            );
        }
        if (result.snapshot.baseDate) {
            result.snapshot.baseDate = new Date(result.snapshot.baseDate);
        }
        return result;
    }
    public static async getEventsFeedSearch(
        query: Ifs.ISearchEventsFeedQuery
    ): Promise<{ [keyString: string]: Ifs.IBaseEvent[] }> {
        const cacheKey = `SearchEvents::${query.maxDate.format(
            'YYYY-MM-DD'
        )}::${
            query.countries
                ? join(
                      ':',
                      orderBy(item => item, ['asc'], query.countries)
                  )
                : 'allCountries'
        }::${query.type}::${query.searchString}`;
        if (APICacheInstance.hasValue(cacheKey)) {
            return APICacheInstance.getValue(cacheKey);
        } else {
            const promise = new Promise<{
                [keyString: string]: Ifs.IBaseEvent[];
            }>(async (resolve, reject) => {
                const axiosInstance = APIService.getAxiosInstance();
                const response = await this._requestsMiddleware<
                    Ifs.IServerGetEventsSearchResponse
                >(
                    axiosInstance.post.bind(axiosInstance),
                    null,
                    'in/events/search',
                    {
                        ...query,
                        maxDate: query.maxDate.toISOString(),
                    },
                    {
                        headers: this.getCommonLoggedInHeaders(),
                    }
                );

                const events = APIService.patchEventsAndUScoresFromFeedRequest(
                    response.events,
                    response.uscores
                );
                resolve(events);
            });
            try {
                APICacheInstance.setValue(cacheKey, promise);
                const res = await promise;
                return res;
            } catch (err) {
                APICacheInstance.deleteValue(cacheKey);
                throw err;
            }
        }
    }

    public static async getEventsFeed(
        queryParams: Ifs.IGetEventsQuery
    ): Promise<Ifs.IEventsByDate> {
        const type = (queryParams.feedType || EVENTS_FILTER.ALL).toString();
        const limit = queryParams.numDaysToFetch || 1;
        const maxDate = moment(queryParams.maxDate);
        const cacheKey = `EventsForDates::${type}::${queryParams.maxDate}::${limit}`;

        let promise: Promise<{ [keyString: string]: Ifs.IBaseEvent[] }> = null;
        if (APICacheInstance.hasValue(cacheKey)) {
            promise = APICacheInstance.getValue(cacheKey);
        } else {
            const params = {
                limit,
                type,
            };
            promise = new Promise<{ [keyString: string]: Ifs.IBaseEvent[] }>(
                async (resolve, reject) => {
                    const axiosInstance = APIService.getAxiosInstance();
                    const response = await this._requestsMiddleware<
                        Ifs.IServerGetEventsResponse
                    >(
                        axiosInstance.get.bind(axiosInstance),
                        null,
                        `in/events/${maxDate.format('YYYYMMDD')}`,
                        {
                            headers: this.getCommonLoggedInHeaders(),
                            params,
                        }
                    );
                    const scoredEvents = APIService.patchEventsAndUScoresFromFeedRequest(
                        _.get(response, 'scored.events', {}),
                        _.get(response, 'scored.uscores', {})
                    );
                    const unscoredEvents = _.get(
                        response,
                        'unscored.events',
                        {}
                    );
                    switch (type) {
                        case EVENTS_FILTER.SCORED:
                            resolve(scoredEvents);
                            break;
                        case EVENTS_FILTER.UNSCORED:
                            resolve(unscoredEvents);
                            break;
                        default:
                            const allEvents: Ifs.IEventsByDate = {};
                            const allKeys = new Set<string>(
                                Object.keys(scoredEvents).concat(
                                    Object.keys(unscoredEvents)
                                )
                            );
                            allKeys.forEach(dateStr => {
                                allEvents[dateStr] = scoredEvents[
                                    dateStr
                                ].concat(unscoredEvents[dateStr]);
                            });
                            resolve(allEvents);
                            break;
                    }
                }
            );
            APICacheInstance.setValue(cacheKey, promise);
        }
        return promise;
    }

    private static patchEventsAndUScoresFromFeedRequest(
        events: Ifs.IEventsByDate,
        uscores: Ifs.IUScoresByEventId
    ): Ifs.IEventsByDate {
        _.forEach(events, (dayEvents, dateString) => {
            _.forEach(dayEvents, e => {
                e.uscores = uscores[e.id];
            });
        });
        return events;
    }

    public static async getUScoreEventsFeed(uscore: IUscoreActive) {
        const cacheKey = `EventsForUscore::${uscore.asId}`;
        let promise: Promise<Ifs.IBaseEvent[]> = null;

        if (APICacheInstance.hasValue(cacheKey)) {
            promise = APICacheInstance.getValue(cacheKey);
        } else {
            promise = new Promise<Ifs.IBaseEvent[]>(async (resolve, reject) => {
                const axiosInstance = APIService.getAxiosInstance();
                const response = await this._requestsMiddleware<
                    Ifs.IBaseEvent[]
                >(
                    axiosInstance.post.bind(axiosInstance),
                    null,
                    'in/events/for_uscore',
                    {
                        ...uscore,
                    },
                    {
                        headers: this.getCommonLoggedInHeaders(),
                    }
                );
                response.reverse();
                resolve(response);
            });
            APICacheInstance.setValue(cacheKey, promise);
        }
        return promise;
    }

    public static async getActiveCountries() {
        const cacheKey = `active_countries::${settingsStore.activeGroupId}`;
        const axiosInstance = APIService.getAxiosInstance();
        return this._requestsMiddleware<Ifs.IActiveCountryAPI>(
            axiosInstance.get.bind(axiosInstance),
            cacheKey,
            'in/active_countries',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }

    public static async me(): Promise<any> {
        const axiosInstance = APIService.getAxiosInstance();
        return this._requestsMiddleware<{
            username: string;
            status: string;
            organization: string;
        }>(axiosInstance.get.bind(axiosInstance), null, 'in/user/me', {
            headers: this.getCommonLoggedInHeaders(),
        });
    }

    public static async login(queryParams: Ifs.ILoginParams): Promise<any> {
        const axiosInstance = APIService.getAxiosInstance();
        const resp = await this._requestsMiddleware<any>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'user/login',
            queryParams,
            {}
        );
        if (resp.redirect && resp.location) {
            window.location.href = `${resp.location}?token=${resp.token}`;
        } else {
            StorageService.set(Ifs.API_CACHE_KEYS.TOKEN, resp.token);
        }
    }
    public static async resetPassword(email: string): Promise<any> {
        const axiosInstance = APIService.getAxiosInstance();
        const token = this.__getToken();
        return this._requestsMiddleware<IUserPermissions>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'user/reset_password',
            { email },
            {
                headers: {
                    Authorization: token,
                },
            }
        );
    }

    public static async changePassword(
        id: string,
        newPass: string
    ): Promise<any> {
        const axiosInstance = APIService.getAxiosInstance();
        const token = this.__getToken();
        return this._requestsMiddleware<IUserPermissions>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'user/change_password',
            { id, newPass },
            {
                headers: {
                    Authorization: token,
                },
            }
        );
    }

    public static async feedback(
        title: string,
        content: string,
        type: string
    ): Promise<undefined> {
        const axiosInstance = APIService.getAxiosInstance();
        const token = this.__getToken();
        return this._requestsMiddleware<undefined>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/activity/feedback',
            { title, content },
            {
                headers: {
                    Authorization: token,
                },
            }
        );
    }

    public static async deleteEvent(eventID: number): Promise<{}> {
        const URL = `in/event/${eventID}`;
        const axiosInstance = APIService.getAxiosInstance();
        return this._requestsMiddleware<{}>(
            axiosInstance.delete.bind(axiosInstance),
            null,
            URL,
            {
                params: {
                    comp: true,
                },
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }
    public static async getScoreEvent(eventID: number): Promise<Ifs.IEvent> {
        const axiosInstance = APIService.getAxiosInstance();
        const URL = `in/event/${eventID}`;
        const event = await this._requestsMiddleware<Ifs.IEvent>(
            axiosInstance.get.bind(axiosInstance),
            null,
            URL,
            {
                params: {
                    comp: true,
                },
                headers: this.getCommonLoggedInHeaders(),
            }
        );

        event.time = moment(event.time);
        return event;
    }

    public static async getActiveClientFacingIndicators(): Promise<
        Ifs.IAPIActiveClientFacingIndicator
    > {
        const axiosInstance = APIService.getAxiosInstance();
        const cacheKey = 'GetActiveClientFacingIndicators';
        return this._requestsMiddleware<any>(
            axiosInstance.get.bind(axiosInstance),
            cacheKey,
            'in/active_client_facing_indicators',
            {
                params: {
                    comp: true,
                },
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }

    public static async getActiveContingentRisks(): Promise<
        Ifs.IActiveContingentRisk[]
    > {
        const axiosInstance = APIService.getAxiosInstance();
        return this._requestsMiddleware<any>(
            axiosInstance.get.bind(axiosInstance),
            'GetActiveContingentRisks',
            'in/active_contingent_risks',
            {
                params: {
                    comp: true,
                },
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }

    public static async getRiskScores(
        riskType: Ifs.E_RISK_TYPE,
        riskId: number,
        countryIds: number[],
        gq: boolean
    ): Promise<Ifs.IAPIScoresByCountry> {
        // NOTICE: riskType should only be DIMENSION or CLIENT_FACING_INDICATORS, but because of legacy code, its too much work to define that type properly
        const res: Ifs.IAPIScoresByCountry = {};
        const countryIdsNoCache: number[] = [];
        const cacheKeyFn = (cid: number) =>
            `GetScores::${riskType}::${riskId}::${cid}::${gq}`;
        countryIds.forEach(countryId => {
            const cacheKey = cacheKeyFn(countryId);
            if (APICacheInstance.hasValue(cacheKey)) {
                res[countryId] = APICacheInstance.getValue(cacheKey);
            } else {
                countryIdsNoCache.push(countryId);
            }
        });
        if (countryIdsNoCache.length) {
            const params = {
                country_ids: orderBy(item => item, ['asc'], countryIdsNoCache),
                gq,
            };
            try {
                const axiosInstance = APIService.getAxiosInstance();
                const data = await this._requestsMiddleware<
                    Ifs.IAPIScoresByCountry
                >(
                    axiosInstance.get.bind(axiosInstance),
                    null,
                    `in/risk_scores/${riskType}/${riskId}`,
                    {
                        headers: this.getCommonLoggedInHeaders(),
                        params,
                    }
                );

                _.forEach(data, (scores, countryId) => {
                    const cid = Number(countryId);
                    res[cid] = scores;
                    APICacheInstance.setValue(cacheKeyFn(cid), scores);
                });
            } catch (err) {
                console.error(
                    'Failed to fetch Risk Scores data for: ',
                    riskType,
                    riskId,
                    params,
                    err
                );
            }
        }
        return res;
    }

    public static async getGlobalIndicator(
        indicator: Ifs.GLOBAL_INDEXES
    ): Promise<Ifs.IAPIScores> {
        const cacheKey = `GlobalIndex::${indicator}`;
        let promise;
        if (APICacheInstance.hasValue(cacheKey)) {
            promise = APICacheInstance.getValue(cacheKey);
        } else {
            const axiosInstance = APIService.getAxiosInstance();
            promise = this._requestsMiddleware<Ifs.IAPIScores>(
                axiosInstance.get.bind(axiosInstance),
                null,
                `in/global_index/${indicator}`,
                {
                    headers: this.getCommonLoggedInHeaders(),
                }
            );

            APICacheInstance.setValue(cacheKey, promise);
        }
        try {
            const result = await promise;
            return result;
        } catch (err) {
            APICacheInstance.deleteValue(cacheKey);
            throw err;
        }
    }

    public static async getRiskPulse(
        riskType: Ifs.E_RISK_TYPE,
        riskId: number,
        countryId: number,
        isGQ: boolean
    ): Promise<number[]> {
        const cacheKey = `getRiskPulse::${riskType}::${riskId}::${countryId}::${isGQ}`;

        let promise;
        if (APICacheInstance.hasValue(cacheKey)) {
            promise = APICacheInstance.getValue(cacheKey);
        } else {
            const axiosInstance = APIService.getAxiosInstance();
            promise = this._requestsMiddleware<Ifs.IAPIScoresByCountry>(
                axiosInstance.get.bind(axiosInstance),
                null,
                `in/risk_pulse/${riskType}/${riskId}/${countryId}`,
                {
                    headers: this.getCommonLoggedInHeaders(),
                    params: {
                        gq: isGQ,
                    },
                }
            );
            APICacheInstance.setValue(cacheKey, promise);
        }
        try {
            const result = await promise;
            return result[countryId];
        } catch (err) {
            APICacheInstance.deleteValue(cacheKey);
            throw err;
        }
    }

    public static async getRiskProjections(
        riskType: Ifs.E_RISK_TYPE,
        riskId: number,
        countryId: number,
        date: moment.Moment
    ): Promise<number[]> {
        const dateStr = date.format(C_SCORES_DATE_FORMAT);
        const cacheKey = `GetRiskProjection::${riskType}::${riskId}::${countryId}::${dateStr}`;

        let promise;
        if (APICacheInstance.hasValue(cacheKey)) {
            promise = APICacheInstance.getValue(cacheKey);
        } else {
            const axiosInstance = APIService.getAxiosInstance();
            promise = this._requestsMiddleware<Ifs.IAPIScoresByCountry>(
                axiosInstance.get.bind(axiosInstance),
                null,
                `in/risk_projection/${riskType}/${riskId}/${countryId}/${dateStr}`,
                {
                    headers: this.getCommonLoggedInHeaders(),
                }
            );
            APICacheInstance.setValue(cacheKey, promise);
        }
        try {
            const result = await promise;
            return result[countryId];
        } catch (err) {
            APICacheInstance.deleteValue(cacheKey);
            throw err;
        }
    }

    public static async getMaxImpactMaps(
        riskType: number,
        riskId: number,
        countryIds: number[]
    ): Promise<Ifs.IAPIMaxImpactMap> {
        const sortedCountryIds = orderBy(item => item, ['asc'], countryIds);
        const cacheKey = `GetMaxImpactMaps::${riskType}::${riskId}::${[
            ...sortedCountryIds,
        ].join(':')}`;
        let promise;
        if (APICacheInstance.hasValue(cacheKey)) {
            promise = APICacheInstance.getValue(cacheKey);
        } else {
            const axiosInstance = APIService.getAxiosInstance();
            promise = this._requestsMiddleware<Ifs.IAPIMaxImpactMap>(
                axiosInstance.get.bind(axiosInstance),
                null,
                `in/max_impact_maps/${riskType}/${riskId}`,
                {
                    headers: this.getCommonLoggedInHeaders(),
                    params: {
                        country_ids: sortedCountryIds,
                    },
                }
            );
            APICacheInstance.setValue(cacheKey, promise);
        }
        try {
            const result = await promise;
            return result;
        } catch (err) {
            APICacheInstance.deleteValue(cacheKey);
            throw err;
        }
    }

    public static async agreeToTerms() {
        const axiosInstance = APIService.getAxiosInstance();
        await this._requestsMiddleware<boolean>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/user/agree_terms',
            {},
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        appStore.setAgreedTerms(true);
        APICacheInstance.setValue('already_confirmed_terms', true);
    }

    public static async getContingentRisk(
        riskId: number,
        countryId: number
    ): Promise<Ifs.IContingentRisk> {
        const axiosInstance = APIService.getAxiosInstance();
        const data = await this._requestsMiddleware<Ifs.IContingentRisk>(
            axiosInstance.get.bind(axiosInstance),
            `ContingentRisk::${riskId}::${countryId}`,
            `in/contingent_risk/${riskId}/${countryId}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        const res: Ifs.IContingentRisk = {};
        _.forEach(data, (values, countryId) => {
            res[Number(countryId)] = values.map(Number);
        });
        return res;
    }

    public static async getHeatmap(
        riskType: Ifs.E_RISK_TYPE,
        scoreType: HeatmapScoreType,
        date: string,
        numDays: number,
        countryIds: number[]
    ): Promise<Ifs.IHeatmapValues> {
        const axiosInstance = APIService.getAxiosInstance();
        //--- Following block is experimental for PoliSci testing, and should be removed ---
        const params: any = { riskType, date, numDays };
        const queryString =
            window &&
            window.location.hash &&
            window.location.hash.match(/\?/) &&
            window.location.hash.split('?')[1];
        let cacheKey = `Heatmap::${riskType}::${params.date}::${
            params.numDays
        }::${join(',', countryIds)}::${scoreType}`;
        const overrideParams = new URLSearchParams(queryString);
        if (overrideParams.has('sync')) {
            params.date = overrideParams.get('date');
            params.numDays = Number(overrideParams.get('window'));
            params.movingAvg = Number(overrideParams.get('movingAvg'));
            params.sync = true;
            cacheKey = null;
        }
        //-----------------------
        const baseUrl = 'in/heatmap';
        const url = `${baseUrl}/${
            scoreType === HeatmapScoreType.RISK ? 'risk' : 'pulse'
        }`;
        const data = await this._requestsMiddleware<Ifs.IHeatmapValues>(
            axiosInstance.get.bind(axiosInstance),
            cacheKey,
            url,
            {
                headers: this.getCommonLoggedInHeaders(),
                params,
            }
        );
        return data;
    }

    public static async postUscore(
        data: Ifs.IUscore & { delete: boolean }
    ): Promise<string> {
        const axiosInstance = APIService.getAxiosInstance();
        const eventId = await this._requestsMiddleware<string>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/score',
            data,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );

        const cacheKey = `GetEvent::${eventId}`;
        //todo: make sure caching of the input daa is valid
        APICacheInstance.setValue(cacheKey, data);
        return eventId;
    }

    public static async deleteEventScores(eventId: number, scoreType: EventScoreType): Promise<Ifs.DeleteEventScoresResponse> {
        const axiosInstance = APIService.getAxiosInstance();
        const resp = await this._requestsMiddleware<Ifs.DeleteEventScoresResponse>(
            axiosInstance.delete.bind(axiosInstance),
            null,
            `in/event_scores/${eventId}/${scoreType}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return resp;
    }

    public static async searchActivities(
        tab: 'all' | 'predictions',
        query: string
    ) {
        const axiosInstance = APIService.getAxiosInstance();
        const searchResult = await this._requestsMiddleware<
            Ifs.ISearchActivityResult[]
        >(
            axiosInstance.get.bind(axiosInstance),
            null,
            `/in/activity/search/${tab}`,
            {
                headers: this.getCommonLoggedInHeaders(),
                params: {
                    query,
                },
            }
        );
        return searchResult;
    }
    public static async getPredictionsFeed(): Promise<
        Ifs.IGetTopPredictionsResponse
    > {
        const cacheKey = `GetPredictionsFeed`;
        if (APICacheInstance.hasValue(cacheKey)) {
            return APICacheInstance.getValue(cacheKey);
        }
        const axiosInstance = APIService.getAxiosInstance();
        const promise = this._requestsMiddleware<
            Ifs.IGetTopPredictionsResponse
        >(axiosInstance.get.bind(axiosInstance), null, `in/predictions_feed`, {
            headers: this.getCommonLoggedInHeaders(),
        });
        APICacheInstance.setValue(cacheKey, promise);
        return promise;
    }

    public static async getInsightsFeed(): Promise<
        Ifs.IGetTopInsightsResponse[]
    > {
        const cacheKey = `getInsightsFeed`;
        if (APICacheInstance.hasValue(cacheKey)) {
            return APICacheInstance.getValue(cacheKey);
        }

        const axiosInstance = APIService.getAxiosInstance();
        const promise = this._requestsMiddleware<Ifs.IGetTopInsightsResponse[]>(
            axiosInstance.get.bind(axiosInstance),
            null,
            `in/insights_feed`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        APICacheInstance.setValue(cacheKey, promise);
        return promise;
    }

    public static async getGlobalTopRisks(
        dateStart: moment.Moment,
        dateEnd: moment.Moment,
        take: number = 5,
        riskType: Ifs.E_RISK_TYPE,
        isGQ: boolean
    ): Promise<Ifs.IGlobalTopRisksDelta[]> {
        // NOTICE - Date Formats must match the expected format in the backend!
        const dateStartStr = dateStart.format(C_SCORES_DATE_FORMAT);
        const dateEndStr = dateEnd.format(C_SCORES_DATE_FORMAT);
        const cacheKey = `GetTopRisks::${dateStartStr}-${dateEndStr}::riskType${riskType}::${take}::${isGQ}`;
        if (APICacheInstance.hasValue(cacheKey)) {
            return APICacheInstance.getValue(cacheKey);
        }
        const promise = new Promise<Ifs.IGlobalTopRisksDelta[]>(
            async (resolve, reject) => {
                try {
                    const axiosInstance = APIService.getAxiosInstance();
                    const data = await this._requestsMiddleware<
                        Ifs.IGetTopRisksResponse[]
                    >(
                        axiosInstance.get.bind(axiosInstance),
                        null,
                        `in/top_risks/${riskType}/${dateStartStr}/${dateEndStr}`,
                        {
                            headers: this.getCommonLoggedInHeaders(),
                            params: {
                                take,
                                gq: isGQ,
                            },
                        }
                    );
                    const { countries } = await this.getActiveCountries();
                    const result = data.map(
                        (d): Ifs.IGlobalTopRisksDelta => {
                            return {
                                riskId: d.riskId,
                                riskType,
                                country: countries[d.country],
                                delta: d.delta,
                            };
                        }
                    );
                    resolve(result);
                } catch (err) {
                    reject(err);
                }
            }
        );
        APICacheInstance.setValue(cacheKey, promise);
        return promise;
    }

    public static async askGeoquant(question: string) {
        const axiosInstance = APIService.getAxiosInstance();
        return this._requestsMiddleware<Ifs.IPreset>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/activity/ask_us',
            { question },
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }
    public static async getContingentScoreDelta(
        params: Ifs.IGetScoreDeltaQueryParams,
        primaryCountry: number,
    ): Promise<Ifs.IScoresDelta> {
        if (
            params.dateEnd.isBefore(C_FIRST_DAY_HUMAN_SCORES_DATE) ||
            params.dateEnd.isAfter(C_TIMELINE_MAX_DATE)
        ) {
            throw new Error('contingent score delta dateEnd out of bounds');
        }

        let dateStart: Moment = params.dateStart.clone();
        if (dateStart.isBefore(C_FIRST_DAY_HUMAN_SCORES_DATE)) {
            dateStart = C_FIRST_DAY_HUMAN_SCORES_DATE.clone();
        }
        const CACHE_KEY = `GetScoreDelta::Contingent::
        ${params.identifier}::${params.virtualToday.format(
            C_SCORES_DATE_FORMAT
        )}::${dateStart.format(
            C_SCORES_DATE_FORMAT
        )}_${params.dateEnd.format(C_SCORES_DATE_FORMAT)}::${primaryCountry}`;
        if (APICacheInstance.hasValue(CACHE_KEY)) {
            return APICacheInstance.getValue<Promise<Ifs.IScoresDelta>>(
                CACHE_KEY
            );
        }
        const riskType =  Ifs.E_RISK_TYPE.CONTINGENT;
        const { identifier } = params;
        const { countries } = await this.getActiveCountries();
        const scores = await this.getContingentRisk(identifier, primaryCountry)
        let scoresDelta: {
            [countryId: number]: Array<
                Partial<Ifs.IGlobalTopRisksDelta>
            >;
        };
        const deltas: IDeltaByCountry = ScoresCalc.extrapulateDeltaFromScoresRangeContingent(
            scores,
            dateStart,
            params.dateEnd
        );
        scoresDelta = {};
        _.forOwn(deltas, (delta, countryId) => {
            scoresDelta[Number(countryId)] = [
                { riskId: identifier, riskType, delta },
            ];
        });
        const ret = {
            identifier,
            type: riskType,
            scores: ScoresCalc.calculateScoreDelta(
                scores,
                scoresDelta,
                countries,
                identifier,
                riskType,
                params.virtualToday
            ).filter(e => !isNaN(e.score_size)),
            scoresOriginal: scores,
        };

        APICacheInstance.setValue(CACHE_KEY, ret);
        return ret;
    }
    public static async getScoreDelta(
        params: Ifs.IGetScoreDeltaQueryParams,
        forceGQ: boolean
    ): Promise<Ifs.IScoresDelta> {
        params.identifier = params.identifier || 1;
        if (
            params.dateStart.isBefore(C_TIMELINE_MIN_DATE) ||
            params.dateEnd.isAfter(C_TIMELINE_MAX_DATE)
        ) {
            throw new Error('score delta out of bounds');
        }
        const CACHE_KEY = `${
            params.isClientFacingIndicator
                ? 'GetScoreDelta::ClientFacingIndicator'
                : 'GetScoreDelta'
        }::${params.identifier}::${params.virtualToday.format(
            C_SCORES_DATE_FORMAT
        )}::${params.dateStart.format(
            C_SCORES_DATE_FORMAT
        )}_${params.dateEnd.format(C_SCORES_DATE_FORMAT)}::${forceGQ}::${
            settingsStore.activeGroupId
        }`;
        if (APICacheInstance.hasValue(CACHE_KEY)) {
            return APICacheInstance.getValue<Promise<Ifs.IScoresDelta>>(
                CACHE_KEY
            );
        }
        const getDataPromise = new Promise<Ifs.IScoresDelta>(
            async (resolve, reject) => {
                try {
                    const scores: Ifs.IAPIScoresByCountry = {};
                    const riskType = params.isClientFacingIndicator
                        ? Ifs.E_RISK_TYPE.CLIENT_FACING_INDICATORS
                        : Ifs.E_RISK_TYPE.DIMENSIONS;
                    const { identifier } = params;
                    const { countries } = await this.getActiveCountries();
                    await Promise.all(
                        _.chunk(_.keys(countries), 5).map(countryIdsBatch => {
                            const countryIds = countryIdsBatch.map(cid =>
                                Number(cid)
                            );
                            return APIService.getRiskScores(
                                riskType,
                                identifier,
                                countryIds,
                                forceGQ
                            ).then(scoresByCountry => {
                                Object.assign(scores, scoresByCountry);
                            });
                        })
                    );
                    let scoresDelta: {
                        [countryId: number]: Array<
                            Partial<Ifs.IGlobalTopRisksDelta>
                        >;
                    };
                    const deltas: IDeltaByCountry = ScoresCalc.extrapulateDeltaFromScoresRange(
                        scores,
                        params.dateStart,
                        params.dateEnd
                    );
                    scoresDelta = {};
                    _.forOwn(deltas, (delta, countryId) => {
                        scoresDelta[Number(countryId)] = [
                            { riskId: identifier, riskType, delta },
                        ];
                    });
                    resolve({
                        identifier,
                        type: riskType,
                        scoresOriginal: [],
                        scores: ScoresCalc.calculateScoreDelta(
                            scores,
                            scoresDelta,
                            countries,
                            identifier,
                            riskType,
                            params.virtualToday
                        ).filter(e => !isNaN(e.score_size)),
                    });
                } catch (err) {
                    reject(err);
                }
            }
        );

        APICacheInstance.setValue(CACHE_KEY, getDataPromise);
        const ret = await getDataPromise;
        return ret;
    }

    public static async getDefaultCustomWeightPreset(): Promise<
        Ifs.ICustomWeightTree
    > {
        const axiosInstance = APIService.getAxiosInstance();
        return this._requestsMiddleware<Ifs.ICustomWeightTree>(
            axiosInstance.get.bind(axiosInstance),
            null,
            'in/settings/default_custom_weight',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }

    public static async logout() {
        StorageService.clear();
        APICacheInstance.clear();
        if (webSocket !== null) {
            webSocket.close();
            webSocket = null;
        }
        EventEmitter.emit('API_FORCE_LOGOUT');
    }

    public static async reloadFirst() {
        try {
            this._token();
        } catch (err) {
            setTimeout(this.reloadFirst.bind(this), 100);
            return;
        }
        const beginReload = Date.now();
        await Promise.all([
            this.getActiveDimensionsList(),
            this.getActiveCountries(),
            this.getActiveClientFacingIndicators(),
            this.getGlobalIndicator('oil_price_normalized'),
            this.getGlobalIndicator('oil_price_risk'),
            this.getGlobalIndicator('oil_production_risk'),
        ]);
        const ttrl = Date.now() - beginReload;
        console.info('=====DONE FIRST RELOAD====', ttrl, 'ms');
    }

    public static async getCurrencyExchange(): Promise<Ifs.ICurrencyExchange> {
        const axiosInstance = APIService.getAxiosInstance();
        const data = this._requestsMiddleware<Ifs.ICurrencyExchange>(
            axiosInstance.get.bind(axiosInstance),
            'GetCurrencyExchange',
            'in/currency_exchange',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return data;
    }

    public static async savePreset(preset: Ifs.IPreset): Promise<Ifs.IPreset> {
        const axiosInstance = APIService.getAxiosInstance();
        const data = await this._requestsMiddleware<Ifs.IPreset>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/settings/preset',
            preset,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return {
            ...preset,
            id: data.id,
        };
    }

    public static async deletePreset(id: string): Promise<{}> {
        const axiosInstance = APIService.getAxiosInstance();
        const data = this._requestsMiddleware<{}>(
            axiosInstance.delete.bind(axiosInstance),
            null,
            `in/settings/preset/${id}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return data;
    }
    public static async setActivePreset(id: string): Promise<{}> {
        const axiosInstance = APIService.getAxiosInstance();
        const data = this._requestsMiddleware<{}>(
            axiosInstance.get.bind(axiosInstance),
            null,
            `in/settings/preset/set_active/${id}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return data;
    }

    public static async uploadCustomImageComponent(
        file: File
    ): Promise<Ifs.IActivityCustomImageComponent> {
        const axiosInstance = APIService.getAxiosInstance();
        const form = new FormData();
        form.append('image', file);
        const data = await this._requestsMiddleware<any>(
            axiosInstance.post.bind(axiosInstance),
            null,
            'in/activity/add_custom_image_component',
            form,
            {
                headers: {
                    ...this.getCommonLoggedInHeaders(),
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return data;
    }

    public static async reportSeenInsight(id: string) {
        const axiosInstance = APIService.getAxiosInstance();
        await this._requestsMiddleware<{}>(
            axiosInstance.get.bind(axiosInstance),
            null,
            `in/activity/add_seen_insight/${id}`,
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
    }
    public static async getPredictions(): Promise<Ifs.IPrediction[]> {
        const axiosInstance = APIService.getAxiosInstance();
        const data = this._requestsMiddleware<Ifs.IPrediction[]>(
            axiosInstance.get.bind(axiosInstance),
            'GetActivityPredictions',
            '/in/activity/predictions',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return data;
    }

    public static async getMyPresets(): Promise<Ifs.IPreset[]> {
        const axiosInstance = APIService.getAxiosInstance();
        const data = this._requestsMiddleware<Ifs.IPreset[]>(
            axiosInstance.get.bind(axiosInstance),
            null,
            'in/settings/get_my_presets',
            {
                headers: this.getCommonLoggedInHeaders(),
            }
        );
        return data;
    }

    public static getCommonLoggedInHeaders() {
        const token = this.__getToken();
        return {
            Authorization: token,
            GQActive:
                this.overrideGQActiveHeader || settingsStore.activeGroupId,
        };
    }

    private static _handlePromiseAnswer(
        response: AxiosResponse,
        originalToken: false | string
    ) {
        if (
            response.data.data &&
            response.data.data.redirect &&
            response.data.data.location
        ) {
            StorageService.remove(Ifs.API_CACHE_KEYS.TOKEN);
            window.location.href = response.data.data.location;
        }
        if (response.data.result !== 'success') {
            if (response.status === 401) {
                if (
                    originalToken !== false &&
                    APIService.__getToken() === originalToken
                ) {
                    APIService.logout();
                }
            }
            throw response.data;
        }

        if (response.data.token) {
            StorageService.set(Ifs.API_CACHE_KEYS.TOKEN, response.data.token);
        }

        if (response.data.extra && response.data.extra.terms) {
            if (!APICacheInstance.hasValue('already_confirmed_terms')) {
                appStore.setAgreedTerms(false);
                document.location.href = '/#/terms';
            }
        }
        return response.data.data;
    }

    private static async _requestMiddlewareCacheOnly(
        request: (...args: any[]) => AxiosPromise,
        cacheKeys: string[],
        ...args: any[]
    ) {
        try {
            const requestPromise = request(...args);
            cacheKeys.forEach(cacheKey => {
                APICacheInstance.setValue<AxiosPromise>(
                    cacheKey as string,
                    requestPromise.then(res =>
                        this._handlePromiseAnswer(res, APIService.__getToken())
                    )
                );
            });

            await requestPromise;
        } catch (err) {
            cacheKeys.forEach(cacheKey => {
                APICacheInstance.deleteValue(cacheKey);
            });
        }
    }
    private static async _requestsMiddleware<T>(
        request: (...args: any[]) => AxiosPromise,
        cacheKey?: string | string[],
        ...args: any[]
    ): Promise<T> {
        try {
            if (Array.isArray(cacheKey)) {
                await this._requestMiddlewareCacheOnly(
                    request,
                    cacheKey,
                    ...args
                );
                return null;
            }
            const singleCacheKey = cacheKey as string;
            let requestPromise: Promise<T> = null;
            const token = APIService.__getToken();
            if (singleCacheKey && APICacheInstance.hasValue(singleCacheKey)) {
                requestPromise = APICacheInstance.getValue(singleCacheKey);
            } else {
                requestPromise = request(...args).then(res =>
                    this._handlePromiseAnswer(res, token)
                );
                if (singleCacheKey) {
                    APICacheInstance.setValue(singleCacheKey, requestPromise);
                }
            }

            const response: T = await requestPromise;
            return response;
        } catch (err) {
            LoggerService.debugLog(err, 'APIService._requestsMiddleware');
            APICacheInstance.deleteValue(cacheKey as string);

            if (401 === get(['response', 'status'], err)) {
                APIService.logout();
            }

            return Promise.reject(err);
        }
    }

    public static getEventContent(url: string): Promise<string> {
        return axios.get(url).then(({ data }) => {
            return data;
        });
    }

    public static loadAsset(assetName: string): Promise<string> {
        return axios.get('/assets/' + assetName).then(({ data }) => {
            return data;
        });
    }

    public static async getSignals(date: moment.Moment, frequency: string, timeWindow): Promise<any>{
        const axiosInstance = APIService.getAxiosInstance();
        const dateStr = date.format('YYYY-MM-DD');
        return await this._requestsMiddleware<undefined>(
            axiosInstance.get.bind(axiosInstance),
            `signals_report_data::${dateStr}::${frequency}::${timeWindow}`,
            `in/signals_report_data`,
            {
                headers: this.getCommonLoggedInHeaders(),
                params: {
                    frequency,
                    timeWindow,
                    date: dateStr,
                },
            }
        );
    }

    public static clearActiveGroupsData() {
        APICacheInstance.deleteValue('active_groups');
    }

    // todo: this fn is used when changing the active country group
    // so it's better to just delete the entire cache
    public static clearCountriesData() {
        APICacheInstance.deleteValue('active_groups');
        APICacheInstance.deleteValue('ACTIVE_COUNTRIES');
        APICacheInstance.deleteValueReg(/^GetEvent::/);
        APICacheInstance.deleteValueReg(/^GetMaxImpactMaps::/);
        APICacheInstance.deleteValueReg(/^GetScores::/);
        APICacheInstance.deleteValueReg(/^GetTopRisks::/);
        APICacheInstance.deleteValueReg(/^GetScoreDelta::/);
        APICacheInstance.deleteValueReg(/^GetRiskProjection::/);
    }
    public static __getToken(): string {
        if (this.overridenToken) {
            return this.overridenToken;
        }
        return StorageService.get(Ifs.API_CACHE_KEYS.TOKEN) || null;
    }

    public static __getWebSocketTransport(): WebSocketService {
        const headers = this.getCommonLoggedInHeaders();
        if (!headers.Authorization) {
            throw new Error();
        }

        if (webSocket === null) {
            webSocket = new WebSocketService(
                headers.Authorization,
                headers.GQActive || ''
            );
        }
        return webSocket;
    }

    public static reconnectWebSocketTransport(disconnectOnly = false) {
        if (webSocket !== null) {
            webSocket.close();
            webSocket = null;
        }

        if (!disconnectOnly) {
            this.__getWebSocketTransport();
        }
    }

    private static _token(): string {
        const token = this.__getToken();
        if (!token) throw new Error('Not logged in');
        return token;
    }
}
