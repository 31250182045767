import React, { useState, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
    DATE_FNS_DATE_DISPLAY_FORMAT,
    C_TIMELINE_MIN_DATE,
    C_TIMELINE_MAX_DATE,
    MOMENTJS_DATE_DISPLAY_FORMAT,
    E_TIME_PRESET_KEYS,
} from '../../constants';
import { map } from 'lodash/fp';

interface Props {
    startDate?: Date;
    onChangeStartDate: (startDate: Date) => void;
    onChangeTimeSpan: (timeSpan: E_TIME_PRESET_KEYS) => void;
    timespanPresets: E_TIME_PRESET_KEYS[];
    timespan?: E_TIME_PRESET_KEYS;
    filterDate?: (date: Date)=> boolean;
    maxDate?: Date | null;
    minDate?: Date | null;
    popperPlacement?: string;
    dropdownDateFormat?: string;
}

const GQDatePicker = ({
    onChangeStartDate,
    onChangeTimeSpan,
    timespanPresets,
    startDate,
    timespan,
    filterDate,
    minDate,
    maxDate,
    popperPlacement,
    dropdownDateFormat,
}: Props) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const onClickOutside = useCallback(() => {
        setIsVisible(false);
    }, []);

    return (
        <OutsideClickHandler onOutsideClick={onClickOutside} display="contents">
            <div className="top-nav-date-picker row">
                <div className="top-nav-date">
                    <div className="react-datepicker-wrapper">
                        <div
                            className="react-datepicker__input-container"
                            onClick={() => setIsVisible(true)}>
                            <button className="react-datepicker__input">
                                {' '}
                                {moment(startDate).format(
                                    dropdownDateFormat ||
                                        MOMENTJS_DATE_DISPLAY_FORMAT
                                )}{' '}
                                <i
                                    className="gqi-dropdown"
                                    style={{
                                        verticalAlign: 'sub',
                                    }}
                                />
                            </button>
                            {timespan && (
                                <span className="time-preset">{timespan}</span>
                            )}
                        </div>
                    </div>
                    {isVisible && (
                        <div className="date-range-picker-wrapper">
                            <div className="calendars-wrapper">
                                <DatePicker
                                    calendarClassName="start-date-picker"
                                    inline
                                    onChange={onChangeStartDate}
                                    selected={startDate}
                                    selectsStart
                                    startDate={startDate}
                                    maxDate={
                                        maxDate || C_TIMELINE_MAX_DATE.toDate()
                                    }
                                    minDate={
                                        minDate || C_TIMELINE_MIN_DATE.toDate()
                                    }
                                    dateFormat={DATE_FNS_DATE_DISPLAY_FORMAT}
                                    showYearDropdown
                                    peekNextMonth={false}
                                    filterDate={filterDate}
                                    popperPlacement={popperPlacement}
                                />
                            </div>
                            <div className="date-range-presets-wrapper">
                                <div className="date-range-presets">
                                    {map(currTimeSpan => {
                                        return (
                                            <span
                                                className={`time-preset ${
                                                    timespan === currTimeSpan
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                key={currTimeSpan}
                                                onClick={() =>
                                                    onChangeTimeSpan(
                                                        currTimeSpan
                                                    )
                                                }>
                                                {currTimeSpan}
                                            </span>
                                        );
                                    }, timespanPresets)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </OutsideClickHandler>
    );
};

GQDatePicker.defaultProps = {
    defaultTimeSpan: E_TIME_PRESET_KEYS.ONE_YEAR,
};

export default React.memo(GQDatePicker);
