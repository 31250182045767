import { ReactNode } from 'react';
import React from 'react';

type Props = {
    children: ReactNode;
};

const PageContainerMobile = React.memo((props: Props) => {
    return <div className="mobile-page-container">{props.children}</div>;
});

export default PageContainerMobile;
