import { VIEW, E_RISK_TYPE } from 'interfaces';
import { inject } from 'mobx-react';
import React, { useEffect } from 'react';
import { IMobxRootState } from 'RootStore';
import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import ChartContainer from 'components/GQChart/ChartContainer';
import { isEmpty } from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import ContingentSidebarRiskPicker from 'components/sidebarRiskPicker/ContingentSidebarRiskPicker';
import useInitChart from 'hooks/useInitChart';
import useInitCountryDim from 'hooks/useInitCountryDim';

type ContingentGraphPageProps = {
    countries?: number[];
};

type ContingentGraphProps = {
    height?: number;
};

export const ContingentGraphPage = inject(
    ({ countryStore }: IMobxRootState) => {
        return {
            countries: countryStore.currentCountryList,
        };
    }
)(({ countries }: ContingentGraphPageProps) => {
    const { push } = useHistory();
    useEffect(() => {
        if (isEmpty(countries)) {
            push(`/${VIEW.SELECT_COUNTRY}/${VIEW.CONTINGENT_GRAPH}`);
            return;
        }
    }, [countries, push]);

    if (isEmpty(countries)) {
        return null;
    }
    return (
        <React.Fragment>
            <EventsFeedDataProvider />
            <ContingentGraph />
            <ContingentSidebarRiskPicker />
        </React.Fragment>
    );
});

export const ContingentGraph = inject(
    ({ chartStore, countryStore }: IMobxRootState) => {
        return {
            countries: countryStore.currentCountryList,
            height: chartStore.height,
        };
    }
)(({ height }: ContingentGraphProps) => {
    useInitChart();
    const ready = useInitCountryDim({
        riskType: E_RISK_TYPE.CONTINGENT,
        multiCountry: true,
        multiRisk: false,
    });

    return (
        ready && (
            <ChartContainer
                expanded
                height={height}
                activeView={VIEW.CONTINGENT_GRAPH}
                changeDataWhenDragStop={true}
            />
        )
    );
});
