import {
    E_RISK_TYPE,
    IActiveClientFacingIndicator,
    IActiveDimension,
} from 'interfaces';
import { map } from 'lodash/fp';
import rootStore from 'RootStore';

export const getDimensionLongName = (dimension: IActiveDimension) => {
    return dimension.lname;
};

export const getDimensionShortName = (dimension: IActiveDimension) => {
    return dimension.sname;
};

export const getCFILongName = (cfi: IActiveClientFacingIndicator) => {
    return cfi.display_name;
};

export const getCFIShortName = (cfi: IActiveClientFacingIndicator) => {
    return cfi.abbreviation;
};

export const getRiskShortNames = (
    risks: { type: E_RISK_TYPE; id: number }[]
) => {
    return map((risk: { type: E_RISK_TYPE; id: number }) => {
        switch (risk.type) {
            case E_RISK_TYPE.DIMENSIONS:
                return getDimensionShortName(
                    rootStore.risksStore.dimensions.hashed[risk.id]
                );
            case E_RISK_TYPE.CLIENT_FACING_INDICATORS:
                return getCFIShortName(
                    rootStore.risksStore.clientFacingIndicators[risk.id]
                );
            default:
                return null;
        }
    }, risks);
};

export const getRiskLongNames = (
    risks: { type: E_RISK_TYPE; id: number }[]
) => {
    return map((risk: { type: E_RISK_TYPE; id: number }) => {
        switch (risk.type) {
            case E_RISK_TYPE.DIMENSIONS:
                return getDimensionLongName(
                    rootStore.risksStore.dimensions.hashed[risk.id]
                );
            case E_RISK_TYPE.CLIENT_FACING_INDICATORS:
                return getCFILongName(
                    rootStore.risksStore.clientFacingIndicators[risk.id]
                );
            default:
                return null;
        }
    }, risks);
};
