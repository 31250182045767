import CreateNewPassportPage from 'components/authPages/CreateNewPassportPage';
import ForgotPasswordPage from 'components/authPages/ForgotPasswordPage';
import Login from 'components/authPages/LoginPage';
import ResetPasswordPage from 'components/authPages/ResetPasswordPage';
import WelcomePage from 'components/authPages/WelcomePage';
import Terms from 'components/TermsOfUse';
import { VIEW } from 'interfaces';
import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Activate from './components/Activate';
import GQLoader from 'components/GQLoader/GQLoader';
import Logo from 'assets/general/Geoquant_Logo_Digital_White.svg';
import { SUPPORT_EMAIL} from 'services/configService';
import GQNavbarMobile from 'components/GQNavbar/GQNavbarMobile';
import SignalsMobile from 'components/Homepage/SignalsMobile';
import useInit from 'hooks/useInit';
import InsightsListMobile from 'components/Homepage/InsightsListMobile';
import InsightMobile from 'components/Homepage/InsightMobile';
import PageContainerMobile from 'PageContainerMobile';
import { WorldGraphPage } from 'components/Homepage/WorldGraph';
import rootStore from './RootStore';

const RouterMobile = React.memo(({ loggedIn }: { loggedIn: boolean }) => {
    return loggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />;
});

const LoggedInRoutes = React.memo(() => {
    return (
        <Switch>
            <Route component={RoutesWithPageContainer} />
        </Switch>
    );
});

const RoutesWithPageContainer = React.memo(() => {
    const {
        didPreload,
        didInitStores,
        preloadError,
        loadingSettings,
        ready,
    } = useInit();

    useEffect(()=>{
        if (ready) {
            rootStore.eventsFeedStore.setMinimized(true);
        }
    }, [ready]);

    if (preloadError) {
        return (
            <PageContainerMobile>
                <div className="preload-error">
                    <div className="preload-error-logo">
                        <img src={Logo} alt="" />
                    </div>
                    <div>A network error has occured.</div>
                    <div>Please try again in a few min.</div>
                    <div>
                        If the error persists, please contact us at{' '}
                        {SUPPORT_EMAIL}
                    </div>
                </div>
            </PageContainerMobile>
        );
    }

    if (loadingSettings || !didPreload || !didInitStores) {
        return (
            <PageContainerMobile>
                <GQLoader
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            </PageContainerMobile>
        );
    }
    return (
        <PageContainerMobile>
            <GQNavbarMobile />
            <div className="mobile-content">
                <Switch>
                    <Route
                        exact={true}
                        path={`/${VIEW.SIGNALS}`}
                        component={SignalsMobile}
                    />
                    <Route
                        exact={true}
                        component={WorldGraphPage}
                        path={`/${VIEW.WORLD_GRAPH}`}
                    />
                    <Route
                        exact={true}
                        path={`/${VIEW.INSIGHTS}`}
                        component={InsightsListMobile}
                    />
                    <Route
                        exact={true}
                        path={`/${VIEW.INSIGHT}/:id?`}
                        component={InsightMobile}
                    />
                    <Route
                        render={() => {
                            return <Redirect to={`/${VIEW.INSIGHTS}`} />;
                        }}
                    />
                </Switch>
            </div>
        </PageContainerMobile>
    );
});

const LoggedOutRoutes = React.memo(() => {
    return (
        <Switch>
            <Route path="/welcome" component={WelcomePage} />
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/login" component={Login} />
            <Route
                path="/create-new-password"
                component={CreateNewPassportPage}
            />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <Route path="/terms" component={Terms} />
            <Route path="/activate/:token" component={Activate} />
            <Route component={Login} />
        </Switch>
    );
});

export default RouterMobile;
