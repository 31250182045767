import { observable, action, toJS, computed, runInAction } from 'mobx';
import {
    IActivityBaseComponent,
    IActivitySnapshot,
    E_COMPONENT_TYPE,
    IActivityRecordChartDataSnapshot,
    IActivityChartComponent,
    IActivityMapComponent,
    IMapNote,
    E_CHART_GROUP_IDENTIFIERS,
    E_RISK_TYPE,
    IActivityHeatmapComponent,
    INote,
} from '../../services/lib/APIServiceInterfaces';
import ColorStack from '../../utils/ColorStack';
import {
    ActivityChartNoteStore,
    IActivityChartNoteStoreProps,
    ActivityMapNoteStore,
    ActivityHeatmapNoteStore,
} from './InsightsNoteStore';
import { generateID } from '../../utils/generalUtils';
import rootStore from '../../RootStore';
import moment from 'moment';
import { VIEW } from '../../interfaces';

export class ActivityBaseComponentStore implements IActivityBaseComponent {
    @observable public id: string;
    @observable public type: E_COMPONENT_TYPE;
    @observable public snapshot: IActivitySnapshot;
    @observable public readonly disableEdit?: boolean;
    @observable public imageURL?: string;
    @observable public thumbnailURL?: string;
    @observable public csvURL?: string;
    @observable public name: string;
    public countriesColorStack: ColorStack<number> = null;
    public dimensionsColorStack: ColorStack<number> = null;
    public activeClientFacingIndicatorsColorStack: ColorStack<number>;
    public htmlKey: string;

    constructor(props: IActivityBaseComponent) {
        this.htmlKey = props.htmlKey || `html-${generateID()}`;
        this.type = props.type;
        this.snapshot = props.snapshot;
        this.disableEdit = props.disableEdit;
        this.id = props.id;
        this.imageURL = props.imageURL;
        this.thumbnailURL = props.thumbnailURL;
        this.csvURL = props.csvURL;
        this.name = props.name;
        if (this.type !== E_COMPONENT_TYPE.CUSTOM) {
            this.countriesColorStack = ColorStack.fromSerialize(
                this.snapshot.countries.colorsStack
            );
            this.dimensionsColorStack = ColorStack.fromSerialize(
                this.snapshot.risks.colorsStack.dimensionsStack
            );
            this.activeClientFacingIndicatorsColorStack = ColorStack.fromSerialize(
                this.snapshot.risks.colorsStack.clientFacingIndicatorsStack
            );
        }
    }

    public get canSnapshotBeRestored() {
        return true;
    }

    @action
    public switchComponentType(type: E_COMPONENT_TYPE) {
        this.type = type;
    }

    @action
    public setSnapshot(snapshot: IActivitySnapshot) {
        this.snapshot = snapshot;
        this.countriesColorStack = ColorStack.fromSerialize(
            this.snapshot.countries.colorsStack
        );
        this.dimensionsColorStack = ColorStack.fromSerialize(
            this.snapshot.risks.colorsStack.dimensionsStack
        );
        this.activeClientFacingIndicatorsColorStack = ColorStack.fromSerialize(
            this.snapshot.risks.colorsStack.clientFacingIndicatorsStack
        );
    }

    @action
    public setURLS(urls: {
        imageURL: string;
        thumbnailURL: string;
        csvURL: string;
    }) {
        Object.assign(this, urls);
    }

    @action
    public setName(name: string) {
        this.name = name;
    }

    public toJS(): IActivityBaseComponent {
        return {
            id: toJS(this.id),
            type: toJS(this.type),
            snapshot: toJS(this.snapshot),
            disableEdit: toJS(this.disableEdit),
            imageURL: toJS(this.imageURL),
            thumbnailURL: toJS(this.thumbnailURL),
            csvURL: toJS(this.csvURL),
            name: toJS(this.name),
        };
    }

    public restoreSnapshot(): VIEW {
        if (!this.canSnapshotBeRestored) {
            return; // perhaps raise exception or something?
        }
        if (
            rootStore.risksStore.checkIfRiskDisabled(
                this.snapshot.riskType,
                this.snapshot.risks.values
            )
        ) {
            alert('cannot restore');
            return; // perhaps raise exception or something?
        }
        const snapshot = toJS(this.snapshot);
        runInAction(() => {
            if (this.type === E_COMPONENT_TYPE.CHART) {
                const currencyCountries = (this as IActivityChartComponent).data
                    .filter(
                        d =>
                            d.chartIdentifier ===
                            E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE
                    )
                    .map(d => d.country);
                rootStore.risksStore.setCurrencyCountries(currencyCountries);
            }
            rootStore.risksStore.setState({
                projectionDataOn:
                    snapshot.risks.projectionDataOn !== undefined
                        ? snapshot.risks.projectionDataOn
                        : rootStore.risksStore.projectionDataOn,
                riskChartMode:
                    snapshot.risks.riskChartMode !== undefined
                        ? snapshot.risks.riskChartMode
                        : rootStore.risksStore.riskChartMode,
            });
            rootStore.risksStore.overrideGlobalIndicators(
                snapshot.risks.activeGlobalIndicators || []
            );
            rootStore.risksStore.setCurrentRiskType(snapshot.riskType);
            rootStore.risksStore.currentStore.overrideValues(
                ...snapshot.risks.values
            );
            rootStore.countryStore.currentStore.overrideValues(
                ...snapshot.countries.values
            );
            rootStore.chartStore.setState({
                virtualToday: moment(snapshot.virtualToday),
                baseDate: moment(snapshot.baseDate),
                rangeLeft: snapshot.rangeLeft,
                rangeRight: snapshot.rangeRight,
                showLines: snapshot.showLines,
                showCircles: snapshot.showCircles,
                showVirtualToday:
                    snapshot.showVirtualToday !== undefined
                        ? snapshot.showVirtualToday
                        : rootStore.chartStore.showVirtualToday,
            });
        });
        return this.getComponentViewType();
    }

    public getComponentViewType(): VIEW {
        switch (this.type) {
            case E_COMPONENT_TYPE.CHART:
                return this.snapshot.riskType === E_RISK_TYPE.CONTINGENT
                    ? VIEW.CONTINGENT_GRAPH
                    : this.snapshot.countries.isMultiSelect
                    ? VIEW.WORLD_GRAPH
                    : VIEW.COUNTRY_GRAPH;
            case E_COMPONENT_TYPE.MAP:
                return VIEW.RISK_VIEW;
            case E_COMPONENT_TYPE.DELTA:
                return VIEW.SORT_BY_DELTA;
            case E_COMPONENT_TYPE.CR_DELTA:
                return VIEW.SORT_BY_CONTINGENT_DELTA;
            case E_COMPONENT_TYPE.CR_RISK:
                return VIEW.SORT_BY_CONTINGENT_RISK;
            case E_COMPONENT_TYPE.RISK:
                return VIEW.SORT_BY_RISK;
            case E_COMPONENT_TYPE.CONTINGENT_CHART:
                return VIEW.CONTINGENT_GRAPH;
            default:
                return null;
        }
    }
}

type ActivitChartComponentStoreType =
    | E_COMPONENT_TYPE.CHART
    | E_COMPONENT_TYPE.CONTINGENT_CHART;
export class ActivityChartComponentStore extends ActivityBaseComponentStore {
    @observable public type: ActivitChartComponentStoreType;
    @observable
    private chartData: IActivityRecordChartDataSnapshot[] = [];
    @observable private annotationArr: ActivityChartNoteStore[];
    private deletedAnnotation: {
        annotation: ActivityChartNoteStore;
        index: number;
    };
    constructor(component: IActivityChartComponent) {
        super({
            id: component.id,
            snapshot: component.snapshot,
            type: component.type,
            disableEdit: false,
            imageURL: component.imageURL,
            thumbnailURL: component.thumbnailURL,
            csvURL: component.csvURL,
            name: component.name,
            htmlKey: component.htmlKey,
        });
        this.chartData = component.data;
        this.annotationArr = component.annotations
            ? component.annotations.map(a => new ActivityChartNoteStore(a))
            : [];
    }

    public clone(keepId: boolean = false) {
        return new ActivityChartComponentStore({
            annotations: this.annotationArr.map(c => c.toJS()),
            data: toJS(this.chartData),
            ...super.toJS(),
            type: E_COMPONENT_TYPE.CHART,
            id: keepId ? toJS(this.id) : generateID(),
        });
    }

    @action
    public addAnnotation(annotation: IActivityChartNoteStoreProps) {
        this.annotationArr.push(new ActivityChartNoteStore(annotation));
    }
    @action
    public removeAnnotation(index: number) {
        this.deletedAnnotation = {
            annotation: this.annotationArr[index],
            index,
        };
        this.annotationArr = [
            ...this.annotationArr.slice(0, index),
            ...this.annotationArr.slice(index + 1, this.annotationArr.length),
        ];
    }
    @action
    public undoRemoveAnnotation() {
        if (this.deletedAnnotation) {
            const { annotation, index } = this.deletedAnnotation;
            this.annotationArr.splice(index, 0, annotation);
            this.deletedAnnotation = null;
        }
    }
    @computed
    public get annotations() {
        return this.annotationArr;
    }
    @action
    public setData(data: IActivityRecordChartDataSnapshot[]) {
        this.chartData = data;
    }
    @computed
    public get data() {
        return this.chartData;
    }

    public toJS(): IActivityChartComponent {
        return {
            data: toJS(this.chartData),
            annotations: toJS(this.annotationArr.map(c => c.toJS())),
            id: toJS(this.id),
            type: toJS(this.type),
            snapshot: toJS(this.snapshot),
            name: toJS(this.name),
            htmlKey: toJS(this.htmlKey),
        };
    }
}

type ActivitHeatmapComponentStoreType = | E_COMPONENT_TYPE.HEATMAP;

export class ActivityHeatmapComponentStore extends ActivityBaseComponentStore {
    @observable public type: ActivitHeatmapComponentStoreType;
    @observable private chartData: IActivityRecordChartDataSnapshot[] = [];
    @observable private annotationArr: ActivityHeatmapNoteStore[];
    private deletedAnnotation: {
        annotation: ActivityHeatmapNoteStore;
        index: number;
    };
    constructor(component: IActivityHeatmapComponent) {
        super({
            id: component.id,
            snapshot: component.snapshot,
            type: component.type,
            disableEdit: false,
            imageURL: component.imageURL,
            thumbnailURL: component.thumbnailURL,
            csvURL: component.csvURL,
            name: component.name,
            htmlKey: component.htmlKey,
        });
        this.annotationArr = component.annotations
            ? component.annotations.map(a => new ActivityHeatmapNoteStore(a))
            : [];
    }

    public clone(keepId: boolean = false) {
        return new ActivityHeatmapComponentStore({
            annotations: this.annotationArr.map(c => c.toJS()),
            ...super.toJS(),
            type: E_COMPONENT_TYPE.HEATMAP,
            id: keepId ? toJS(this.id) : generateID(),
        });
    }

    @action
    public addAnnotation(annotation: INote) {
        this.annotationArr.push(new ActivityHeatmapNoteStore(annotation));
    }
    @action
    public removeAnnotation(index: number) {
        this.deletedAnnotation = {
            annotation: this.annotationArr[index],
            index,
        };
        this.annotationArr = [
            ...this.annotationArr.slice(0, index),
            ...this.annotationArr.slice(index + 1, this.annotationArr.length),
        ];
    }
    @action
    public undoRemoveAnnotation() {
        if (this.deletedAnnotation) {
            const { annotation, index } = this.deletedAnnotation;
            this.annotationArr.splice(index, 0, annotation);
            this.deletedAnnotation = null;
        }
    }
    @computed
    public get annotations() {
        return this.annotationArr;
    }
    @action
    public setData(data: IActivityRecordChartDataSnapshot[]) {
        this.chartData = data;
    }
    @computed
    public get data() {
        return this.chartData;
    }

    public toJS(): IActivityHeatmapComponent {
        return {
            data: toJS(this.chartData),
            annotations: toJS(this.annotationArr.map(c => c.toJS())),
            id: toJS(this.id),
            type: toJS(this.type),
            snapshot: toJS(this.snapshot),
            name: toJS(this.name),
            htmlKey: toJS(this.htmlKey),
        };
    }
}

type ActivityMapComponentStoreType =
    | E_COMPONENT_TYPE.MAP
    | E_COMPONENT_TYPE.DELTA
    | E_COMPONENT_TYPE.CR_DELTA
    | E_COMPONENT_TYPE.CR_RISK
    | E_COMPONENT_TYPE.RISK
    | E_COMPONENT_TYPE.CONTINGENT_MAP;
export class ActivityMapComponentStore extends ActivityBaseComponentStore {
    @observable public type: ActivityMapComponentStoreType;
    @observable private chartData: IActivityRecordChartDataSnapshot[] = [];
    @observable private allAnnotations: {
        [key: string]: ActivityMapNoteStore[];
    } = {
        [E_COMPONENT_TYPE.MAP]: [],
        [E_COMPONENT_TYPE.DELTA]: [],
        [E_COMPONENT_TYPE.RISK]: [],
        [E_COMPONENT_TYPE.CONTINGENT_MAP]: [],
        [E_COMPONENT_TYPE.CR_DELTA]: [],
        [E_COMPONENT_TYPE.CR_RISK]: [],
    };
    private deletedAnnotation: {
        annotation: ActivityMapNoteStore;
        index: number;
    };
    constructor(props: IActivityMapComponent) {
        super(props);
        this.type = props.type;
        this.chartData = props.data;
        this.annotationArr = props.annotations
            ? props.annotations.map(a => new ActivityMapNoteStore(a))
            : [];
    }

    public clone(keepId: boolean = false) {
        return new ActivityMapComponentStore({
            ...super.toJS(),
            type: this.type,
            data: toJS(this.chartData),
            annotations: this.annotationArr.map(c => c.toJS()),
            id: keepId ? toJS(this.id) : generateID(),
        });
    }

    @action
    public addAnnotation(annotation: IMapNote) {
        this.annotationArr.push(new ActivityMapNoteStore(annotation));
    }
    @action
    public removeAnnotation(index: number) {
        this.deletedAnnotation = {
            annotation: this.annotationArr[index],
            index,
        };
        this.annotationArr = [
            ...this.annotationArr.slice(0, index),
            ...this.annotationArr.slice(index + 1, this.annotationArr.length),
        ];
    }
    @action
    public undoRemoveAnnotation() {
        if (this.deletedAnnotation) {
            const { annotation, index } = this.deletedAnnotation;
            this.annotationArr.splice(index, 0, annotation);
            this.deletedAnnotation = null;
        }
    }
    @computed
    public get annotations() {
        return this.allAnnotations[this.type];
    }
    @action
    public setData(data: IActivityRecordChartDataSnapshot[]) {
        this.chartData = data;
    }

    @computed
    public get data() {
        return this.chartData;
    }
    // notice it's private, no @action because called already from action or consturctor
    private set annotationArr(arr: ActivityMapNoteStore[]) {
        this.allAnnotations[this.type] = arr;
    }

    private get annotationArr() {
        return this.allAnnotations[this.type];
    }

    public toJS(): IActivityMapComponent {
        return {
            data: toJS(this.chartData),
            annotations: toJS(this.annotationArr.map(c => c.toJS())),
            id: toJS(this.id),
            type: toJS(this.type) as
                | E_COMPONENT_TYPE.MAP
                | E_COMPONENT_TYPE.DELTA
                | E_COMPONENT_TYPE.RISK
                | E_COMPONENT_TYPE.CR_DELTA
                | E_COMPONENT_TYPE.CR_RISK
                | E_COMPONENT_TYPE.CONTINGENT_MAP,
            snapshot: toJS(this.snapshot),
            name: toJS(this.name),
            htmlKey: toJS(this.htmlKey),
        };
    }
}

export class ActivityCustomImageComponentStore extends ActivityBaseComponentStore {
    @observable public type: E_COMPONENT_TYPE.CUSTOM = E_COMPONENT_TYPE.CUSTOM;

    public get canSnapshotBeRestored() {
        return false;
    }
}
export type ActivityComponentStore =
    | ActivityMapComponentStore
    | ActivityChartComponentStore
    | ActivityCustomImageComponentStore
    | ActivityHeatmapComponentStore;

export function activityComponentStoreFromActivityComponent(
    component: IActivityBaseComponent
): ActivityComponentStore {
    switch (component.type) {
        case E_COMPONENT_TYPE.CHART:
            return new ActivityChartComponentStore(
                component as IActivityChartComponent
            );
        case E_COMPONENT_TYPE.CUSTOM:
            return new ActivityCustomImageComponentStore(component);
        case E_COMPONENT_TYPE.HEATMAP:
            return new ActivityHeatmapComponentStore(
                component as IActivityHeatmapComponent
            );
        default:
            return new ActivityMapComponentStore(
                component as IActivityMapComponent
            );
    }
}
