import { VIEW, E_RISK_TYPE } from 'interfaces';
import { inject } from 'mobx-react';
import React, { useEffect } from 'react';
import { IMobxRootState } from 'RootStore';
import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import ChartContainer from 'components/GQChart/ChartContainer';
import GQMapProvider from 'components/GQMap/GQMapProvider';
import ContingentSidebarRiskPicker from 'components/sidebarRiskPicker/ContingentSidebarRiskPicker';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import useInitCountryDim from 'hooks/useInitCountryDim';

type ContingentMapPageProps = {
    countries?: number[];
};

type ContingentMapProps = {
    showChart?: boolean;
};

export const ContingentMapPage = inject(({ countryStore }: IMobxRootState) => {
    return {
        countries: countryStore.currentCountryList,
    };
})(({ countries }: ContingentMapPageProps) => {
    const { push } = useHistory();

    useEffect(() => {
        if (isEmpty(countries)) {
            push(`/${VIEW.SELECT_COUNTRY}/${VIEW.CONTINGENT}`);
        }
    }, [countries, push]);

    if (isEmpty(countries)) {
        return null;
    }
    return (
        <React.Fragment>
            <EventsFeedDataProvider />
            <ContingentMap showChart />
            <ContingentSidebarRiskPicker />
        </React.Fragment>
    );
});

export const ContingentMap = React.memo((props: ContingentMapProps) => {
    const { push } = useHistory();

    const ready = useInitCountryDim({
        riskType: E_RISK_TYPE.CONTINGENT,
        multiCountry: true,
        multiRisk: false,
    });

    return (
        ready && (
            <div className="map-and-chart-container">
                <GQMapProvider view={VIEW.CONTINGENT} />
                {props.showChart && (
                    <ChartContainer
                        activeView={VIEW.CONTINGENT}
                        onExpand={() => {
                            push(`/${VIEW.CONTINGENT_GRAPH}`);
                        }}
                        changeDataWhenDragStop={true}
                    />
                )}
            </div>
        )
    );
});
