import { get, isEmpty, map, take } from 'lodash/fp';
import React, { useMemo } from 'react';
import { IBasicActivityRecord } from 'services/APIService';
import { Link } from 'react-router-dom';
import { VIEW } from 'interfaces';
import GQLoader from 'components/GQLoader/GQLoader';
import { E_TAB_FILTER } from 'components/GQActivityPage/activityPage/ActivityPageInterfaces';
import GQTabs from 'components/GQTabs/GQTabs';
import UserStore from 'stores/UserStore';
import GQFlag from 'components/GQFlag';
import { useInsights } from 'hooks/useInsights';
import { getRiskShortNames } from 'utils/riskUtils';

const InsightsListMobile = React.memo(() => {
    const { insights, loading, tab, setTab } = useInsights();
    const tabs = useMemo(() => {
        const bmiFeedVisible = get(
            ['insightsResource', 'bmi_feed_visible'],
            UserStore.allPermissions
        );
        let _tabs = [
            {
                label: 'Geoquant Insights',
                id: E_TAB_FILTER.GQ,
            },
            {
                label: 'MY Insights',
                id: E_TAB_FILTER.MY,
            },
        ];
        if (bmiFeedVisible) {
            _tabs = [
                ..._tabs,
                {
                    label: 'BMI Insights',
                    id: E_TAB_FILTER.BMI,
                },
            ];
        }
        return _tabs;
    }, [UserStore.allPermissions]);

    if (loading) {
        return <GQLoader style={{ marginTop: 32 }} />;
    }

    return (
        <div className="mobile-insights-root">
            <div className="mobile-insights-list-header">
                <GQTabs tabs={tabs} onChange={setTab} selectedTab={tab} />
            </div>
            <div className="mobile-insights-list-container">
                <div className="mobile-insights-list">
                    {map((insight: IBasicActivityRecord) => {
                        const insightRisks = getRiskShortNames(insight.risks);
                        return (
                            <div
                                key={insight._id}
                                className="mobile-insights-list-item">
                                <Link
                                    to={`${VIEW.INSIGHT}/${insight._id}`}
                                    style={{
                                        color: '#fff',
                                        textDecoration: 'none',
                                    }}>
                                    <div>{insight.name}</div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                        }}>
                                        {map(
                                            (country: number) => (
                                                <GQFlag
                                                    key={country}
                                                    countryId={country}
                                                    getFlagFromAll={true}
                                                    style={{ fontSize: 12 }}
                                                />
                                            ),
                                            insight.countries
                                        )}
                                        {!isEmpty(insight.risks) &&
                                            !isEmpty(insight.countries) && (
                                                <div
                                                    style={{
                                                        borderLeft:
                                                            '1px solid #999',
                                                        marginInline: 8,
                                                    }}
                                                />
                                            )}
                                        {map(
                                            (riskName: string) => (
                                                <span
                                                    key={riskName}
                                                    className="mobile-insights-list-item-risk">
                                                    {riskName}
                                                </span>
                                            ),
                                            insightRisks
                                        )}
                                    </div>
                                    <div></div>
                                </Link>
                            </div>
                        );
                    }, take(30, insights))}
                </div>
            </div>
        </div>
    );
});
export default InsightsListMobile;
